/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Security API
 * A rest api for managing the ameta security system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SecurityEvent,
    SecurityEventFromJSON,
    SecurityEventToJSON,
} from '../models';

export interface CreateSecurityEventRequest {
    securityEvent: SecurityEvent;
}

export interface DeleteSecurityEventRequest {
    securityEventId: string;
}

/**
 * 
 */
export class SecurityEventApi extends runtime.BaseAPI {

    /**
     * create a new event
     */
    async createSecurityEventRaw(requestParameters: CreateSecurityEventRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.securityEvent === null || requestParameters.securityEvent === undefined) {
            throw new runtime.RequiredError('securityEvent','Required parameter requestParameters.securityEvent was null or undefined when calling createSecurityEvent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/event`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecurityEventToJSON(requestParameters.securityEvent),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create a new event
     */
    async createSecurityEvent(requestParameters: CreateSecurityEventRequest): Promise<void> {
        await this.createSecurityEventRaw(requestParameters);
    }

    /**
     * delete security event
     */
    async deleteSecurityEventRaw(requestParameters: DeleteSecurityEventRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.securityEventId === null || requestParameters.securityEventId === undefined) {
            throw new runtime.RequiredError('securityEventId','Required parameter requestParameters.securityEventId was null or undefined when calling deleteSecurityEvent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/event/{securityEventId}`.replace(`{${"securityEventId"}}`, encodeURIComponent(String(requestParameters.securityEventId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete security event
     */
    async deleteSecurityEvent(requestParameters: DeleteSecurityEventRequest): Promise<void> {
        await this.deleteSecurityEventRaw(requestParameters);
    }

}
