import React, { useState } from "react";

import Box from "@mui/material/Box";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import measurementTypes from "components/ym/measurementTypes";
import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";
import { format } from "date-fns";
import { authorizedYmApi } from "services/apiService";

export default function CreateYmMeasurementDialog({
  ymPointMeasurementId,
  ymPointId,
  isOpened,
  onClose,
}: {
  ymPointId: string;
  ymPointMeasurementId: string;
  isOpened: boolean;
  onClose: () => void;
}) {
  const [formData, setFormData] = useState({
    data: {},
    date: new Date(),
  });
  const initialStatesThresholdValuesValidation = Object.fromEntries(
    Object.values(measurementTypes).map((value) => [value.label, true])
  );
  const [vaildations, setValidations] = useState({
    initialStatesThresholdValuesValidation,
  });
  const [measurementErrorMessage, setMeasurementErrorMessage] = useState("");
  const [saved, setSaved] = useState(false);
  function onSaveClick() {
    let countFalse = 0;
    for (const value of Object.values(
      vaildations.initialStatesThresholdValuesValidation
    )) {
      if (value === false) {
        countFalse++;
        break;
      }
    }
    if (countFalse !== 0) {
      setMeasurementErrorMessage(
        "Ugyldige tegn er brukt. Gyldige formater:: <51.5, >51.5, 51.5, <51, >51, 51"
      );
    } else if (Object.keys(formData.data).length === 0) {
      setMeasurementErrorMessage("Du må legge til minst en måleverdi");
    } else if (countFalse === 0 && Object.keys(formData.data).length !== 0) {
      authorizedYmApi().then((api) => {
        api
          .createYmMeasurement({
            pointId: ymPointId,
            newYmMeasurement: {
              measurementPointId: ymPointMeasurementId,
              date: format(formData.date, "yyyy-MM-dd"),
              data: formData.data,
            },
          })
          .then(() => {
            handleOnClose();
          });
      });
    }
    setSaved(false);
  }
  const validThreshold = (value, label) => {
    const re = /^[<>]?[0-9\b]+(\.[0-9]+)?$/;
    if (value === "" || re.test(value)) {
      setValidations({
        ...vaildations,
        initialStatesThresholdValuesValidation: {
          ...vaildations.initialStatesThresholdValuesValidation,
          [label]: true,
        },
      });
    } else {
      setValidations({
        ...vaildations,
        initialStatesThresholdValuesValidation: {
          ...vaildations.initialStatesThresholdValuesValidation,
          [label]: false,
        },
      });
    }
  };
  const handleOnClose = () => {
    setSaved(false);
    setValidations({
      initialStatesThresholdValuesValidation,
    });
    setFormData({
      data: {},
      date: new Date(),
    });
    setMeasurementErrorMessage("");
    onClose();
  };

  return (
    <Dialog
      type="drawer"
      title="Legg til ny måledata"
      paperSx={{ minWidth: "60%" }}
      dialogActions={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            type="success"
            onClick={() => {
              if (!saved) {
                setSaved(true);
                onSaveClick();
              }
            }}
          >
            Legg til ny måledata
          </Button>
        </Box>
      }
      onClose={handleOnClose}
      open={isOpened}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 4,
        }}
      >
        <DatePicker
          width={280}
          label="Dato"
          value={formData.date}
          onChange={(date) => {
            setFormData({ ...formData, date });
            setSaved(false);
          }}
        />
      </Box>
      <Text sx={{ pl: 4 }} variant="body1">
        Måletype:
      </Text>
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          {measurementTypes.map((type, index) => {
            return (
              <TextField
                key={index}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text sx={{ mr: 1 }} variant="body1">
                      {type.label}
                    </Text>
                    <Text variant="subtitle1">{`(${type.unit})`}</Text>
                  </Box>
                }
                onChange={(event) => {
                  const currentValue = event.target.value;
                  let measurementIndication = "eq";
                  if (currentValue[0] === "<") {
                    measurementIndication = "lt";
                  } else if (currentValue[0] === ">") {
                    measurementIndication = "mt";
                  }
                  validThreshold(currentValue, type.label);
                  if (currentValue === "") {
                    const thresholdObject = Object.fromEntries(
                      Object.entries(formData.data).filter(
                        ([key]) => key !== type.label
                      )
                    );
                    setFormData({
                      ...formData,
                      data: thresholdObject,
                    });
                  } else {
                    setFormData({
                      ...formData,
                      data: {
                        ...formData.data,
                        [type.label]: {
                          unit: type.unit,
                          value: currentValue,
                          measurementIndication,
                        },
                      },
                    });
                    setMeasurementErrorMessage("");
                  }

                  setSaved(false);
                }}
                error={
                  !vaildations.initialStatesThresholdValuesValidation[
                    type.label
                  ]
                }
              />
            );
          })}
        </Box>
      </Box>
      {measurementErrorMessage !== "" && (
        <Text variant="body1">{measurementErrorMessage}</Text>
      )}
    </Dialog>
  );
}
