import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import geohash from "ngeohash";
import { groupBy } from "lodash";
import {
  setHashArray,
  setSideMenuClosed,
  setDrawerOpen,
  setDrawerClosed,
  setSelectedPointId,
} from "redux/ym";
import { TStore } from "redux/store";

import YmMarkerCluster from "components/ym/mapView/YmMarkerCluster";
import Map from "@smartinspection/ameta-frontend-components/map/Map";
import { useYmPointQueries } from "services/ymQueryHooks";

export default function MapView({
  ymPointQueries,
}: {
  ymPointQueries: ReturnType<typeof useYmPointQueries>;
}) {
  const dispatch = useDispatch();
  const ymState = useSelector((state: TStore) => state.ym);
  const handlecords = useCallback(
    (mapRef) => {
      const southWest = mapRef.getBounds()._southWest;
      const northEast = mapRef.getBounds()._northEast;
      let precision = 5;
      let calculatedBBXHashArray = geohash.bboxes(
        southWest.lat,
        southWest.lng,
        northEast.lat,
        northEast.lng,
        precision
      );
      while (calculatedBBXHashArray.length > 5) {
        precision -= 1;
        calculatedBBXHashArray = geohash.bboxes(
          southWest.lat,
          southWest.lng,
          northEast.lat,
          northEast.lng,
          precision
        );
      }
      const updatedHashArray = [...ymState.hashArray];
      calculatedBBXHashArray.forEach((element) => {
        if (updatedHashArray.length === 0) {
          updatedHashArray.push(element);
        }

        if (!updatedHashArray.includes(element)) {
          updatedHashArray.push(element);
        }
      });

      dispatch(setHashArray(updatedHashArray));
    },
    [dispatch, ymState.hashArray]
  );

  const ymPoints = ymPointQueries.data;

  const groupedData = ymPoints && groupBy(Object.values(ymPoints), "status");
  let statuses = groupedData && Object.keys(groupedData);
  statuses = statuses.sort();
  // Hack, but works for now.
  // Probably not bulletproof.
  // Used to avoid getting wrong colors if the amount
  // of colors changes from 2 to 3 or vice versa,
  // in some spesific cases.

  return (
    <Map
      center={ymState.centerPoint}
      whenCreated={(map) => {
        handlecords(map);
      }}
      zoom={ymState.zoomLevel}
      onZoomEnd={(e) => {
        handlecords(e.target);
      }}
      onClick={() => {
        dispatch(setDrawerClosed());
        dispatch(setSideMenuClosed());
      }}
      onDragEnd={(e) => {
        handlecords(e.target);
      }}
    >
      {statuses.map((status, index) => {
        return (
          <YmMarkerCluster
            onClick={(point) => {
              dispatch(setSideMenuClosed());
              dispatch(setDrawerOpen());
              dispatch(setSelectedPointId(point.id));
            }}
            key={index}
            clusterColor={
              status === "ok"
                ? "green"
                : status === "warning"
                ? "orange"
                : status === "alarm"
                ? "red"
                : "cadetblue"
            }
            data={groupedData[status]}
          />
        );
      })}
    </Map>
  );
}
