import { useQueries, useQuery } from "react-query";
import { authorizedYmApi } from "services/apiService";
import {
  YmPoint,
  YmApi,
  YMPointsResponse,
  DocumentReport,
} from "services/ymApi";
import { queryClient } from "services/apiService";
const paginationQuery = async (
  api: YmApi,
  hashKey: string,
  temporaryObjectStore: YmPoint[],
  lastEvaluatedKey?: string
) => {
  await api
    .getYmPoints({ hashKey, lastEvaluatedKey })
    .then(async (response) => {
      response.objects &&
        response.objects.forEach((obj) => {
          temporaryObjectStore.push(obj);
        });
      if (response.lastEvaluatedKey) {
        await paginationQuery(
          api,
          hashKey,
          temporaryObjectStore,
          JSON.stringify(response.lastEvaluatedKey)
        );
      }
    });
};

export const useYmPointQuery = (pointId: string) => {
  const { data, refetch, isLoading } = useQuery(
    ["YmPointByPointId", pointId],
    async () => {
      const api = await authorizedYmApi();

      try {
        const response = await api.getYmPoint({ pointId: pointId });
        return response;
      } catch (err) {
        return undefined;
      }
    }
  );

  return { data, refetch, isLoading };
};

export const useYmPointQueries = (hashKeyArray: string[]) => {
  const queryArray = hashKeyArray.map((element) => {
    return {
      queryKey: ["ymPoints", element],
      queryFn: async () => {
        const temporaryObjectStore: YmPoint[] = [];
        const api = await authorizedYmApi();
        await paginationQuery(api, element, temporaryObjectStore);
        return { objects: temporaryObjectStore };
      },
    };
  });
  const data: { [key: string]: YmPoint } = {};
  const queriesResult = useQueries(queryArray);

  queriesResult.forEach((response) => {
    response.data &&
      (response.data as YMPointsResponse).objects.forEach((obj) => {
        data[obj.id as string] = obj;
      });
  });

  return {
    data,
    refetch: () =>
      queriesResult.forEach((response) => {
        response.refetch();
      }),
    remove: () => {
      queryClient.removeQueries("ymPoints");
    },
  };
};

export const useYmOverviewQuery = () => {
  const { data, refetch, isLoading } = useQuery(["ymOverview"], async () => {
    const api = await authorizedYmApi();
    return await api.getOverview();
  });

  return { data, refetch, isLoading };
};

const paginationDocumentReportQuery = async (
  api: YmApi,
  documentId: string,
  temporaryObjectStore: DocumentReport[]
) => {
  await api
    .getDocumentReport({ documentId: documentId })
    .then(async (response) => {
      temporaryObjectStore.push(response);
      if (response.status === "initial" || response.status === "processing") {
        await paginationDocumentReportQuery(
          api,
          documentId,
          temporaryObjectStore
        );
      }
    });
};

export const useYmDocumentReportQuery = (documentIdlist: string[]) => {
  const queryArray = documentIdlist.map((element) => {
    return {
      queryKey: ["ymDocumentsReport", element],
      queryFn: async () => {
        const temporaryObjectStore: DocumentReport[] = [];
        const api = await authorizedYmApi();
        await paginationDocumentReportQuery(api, element, temporaryObjectStore);
        return { objects: temporaryObjectStore };
      },
    };
  });
  let data: { [key: string]: DocumentReport } = {};
  const queriesResult = useQueries(queryArray);

  queriesResult.forEach((response) => {
    response.data &&
      response.data.objects.forEach((obj) => {
        data[obj.documentName] = obj;
      });
  });

  return {
    data,
    refetch: () =>
      queriesResult.forEach((response) => {
        response.refetch();
      }),
    remove: () => {
      queryClient.removeQueries("ymDocumentsReport");
    },
  };
};
