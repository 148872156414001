import React, { useState } from "react";
import { format } from "date-fns";

import Box from "@mui/material/Box";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Table from "@smartinspection/ameta-frontend-components/display/Table";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";

import { authorizedDeleteReportApi } from "services/apiService";

import { HMSCounter, NHReport, NhYearDataObject } from "services/hmsApi";
import { yearsListDropdown } from "utils/yearsListDropDown";

export default function NAndHReportDialog({
  hmsCounterData,
  refetch,
}: {
  hmsCounterData: HMSCounter;
  refetch: () => void;
}) {
  const [open, setOpen] = useState(false);

  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, "yyyy")
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteNhReportId, setDeleteNhReportId] = useState("");

  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const NAndHEventsInYear: (year: NhYearDataObject) => NHReport[] = (
    year: NhYearDataObject
  ) => {
    // Can get the events in scrabled order. Solved by using sortable table.

    const nhReports: NHReport[] = [];
    Object.values(year.months).forEach((month) => {
      nhReports.push(month);
    });
    return nhReports;
  };

  const reportDataYear =
    hmsCounterData?.data?.nh?.years?.[yearStringConverter(selectedYear)];
  const tableData =
    reportDataYear &&
    NAndHEventsInYear(reportDataYear).map((el) => {
      return {
        ...el,
        delete: (
          <IconButton
            noShadow
            type="error"
            onClick={() => {
              setDeleteDialogOpen(true);
              setDeleteNhReportId(el.date);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
      };
    });

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={<Text variant="body1">Se N&H rapportoversikt</Text>}
      >
        <IconButton
          type="info"
          onClick={() => {
            setOpen(true);
          }}
        >
          <ArticleIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        paperSx={{
          minWidth: "70%",
          borderRadius: 2,
          height: "70%",
        }}
        onClose={() => setOpen(false)}
        title="N- og H-verdier Månedsrapport"
        rightHeaderComponent={
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              py: 1,
            }}
          >
            <Box
              sx={{
                px: 1,
              }}
            >
              <OptionsPicker
                label="År"
                optionsList={yearsListDropdown()}
                value={selectedYear}
                onChange={setSelectedYear}
              />
            </Box>
          </Box>
        }
      >
        <Box style={{ height: "100%", width: "100%" }}>
          {tableData && tableData.length > 0 ? (
            <Box sx={{ py: 2, px: 1 }}>
              <Table
                headers={[
                  { fieldName: "date", name: "Dato" },
                  { fieldName: "nValue", name: "N-verdi" },
                  { fieldName: "h1Value", name: "H1-verdi" },
                  { fieldName: "h2Value", name: "H2-verdi" },
                  { fieldName: "delete", name: "", align: "center" },
                ]}
                data={tableData}
              />
            </Box>
          ) : (
            <CenterContainer>
              <Text variant="h5">Ingen data denne perioden</Text>
            </CenterContainer>
          )}
        </Box>
      </Dialog>
      {deleteDialogOpen && (
        <ConfirmDialog
          open={deleteDialogOpen}
          text="Er du sikker på at du vil slette denne raden?"
          acceptText="Ja, slett denne raden"
          cancelText="Avbryt"
          onAccept={() => {
            authorizedDeleteReportApi().then((api) => {
              api.deleteNhReport({ nhReportId: deleteNhReportId }).then(() => {
                setDeleteNhReportId("");
                setDeleteDialogOpen(false);
                setTimeout(() => {
                  refetch();
                }, 2000);
              });
            });
          }}
          onCancel={() => {
            setDeleteNhReportId("");
            setDeleteDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
        />
      )}
    </React.Fragment>
  );
}
