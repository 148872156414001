import React, { useState } from "react";
import format from "date-fns/format";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import MultilineTextField from "@smartinspection/ameta-frontend-components/input/MultilineTextField";
import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";
import TimePicker from "@smartinspection/ameta-frontend-components/pickers/TimePicker";

import { authorizedSecurityEventApi } from "services/apiService";
import {
  SecurityEvent,
  SecurityEventAccidentTypeEnum,
} from "services/securityApi/models";

export default function AddSecurityEventDialog({
  refetch,
}: {
  refetch: () => void;
}) {
  const [open, setOpen] = useState(false);

  const initialDate = new Date();

  const initialForm: SecurityEvent = {
    date: format(initialDate, "dd.MM.yyyy HH:mm"),
    place: "",
    accidentType: SecurityEventAccidentTypeEnum.AU,
    involvedCount: 1,
  };

  const initialValidation: { [k: string]: boolean } = Object.fromEntries(
    Object.keys(initialForm).map((key) => [key, true])
  );

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [formData, setFormData] = useState<SecurityEvent>(initialForm);
  const [validations, setValidations] = useState(initialValidation);
  const [errorMessage, setErrorMessage] = useState("");
  const validateForm = (form: any) => {
    const currentValidation = { ...validations };
    Object.keys(currentValidation).forEach((key) => {
      if ((!form[key] || form[key] < 0) && form[key] !== 0) {
        currentValidation[key] = false;
      }
    }); // for each key, set validation to false if there's nothing there
    setValidations(currentValidation);
    return Object.keys(currentValidation).every(
      (key) => currentValidation[key] // 'and' all values
    );
  };

  const closeAndResetStates = () => {
    setErrorMessage("");
    setSelectedDate(initialDate);
    setFormData(initialForm);
    setValidations(initialValidation);
    setOpen(false);
  };

  function onSaveClick() {
    if (!validateForm(formData)) {
      setErrorMessage("Du må fylle ut alle feltene som er markert med *");
    } else {
      authorizedSecurityEventApi().then((api) => {
        api
          .createSecurityEvent({
            securityEvent: {
              ...formData,
              date: format(selectedDate, "dd.MM.yyyy HH:mm"),
            },
          })
          .then(refetch);
      });
      closeAndResetStates();
    }
  }

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={
          <Text variant="body1">Legg til ny sikkerhetshendelse</Text>
        }
      >
        <IconButton
          type="info"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        paperSx={{ height: "70%" }}
        type="drawer"
        open={open}
        onClose={closeAndResetStates}
        title="Legg til ny sikkerhetshendelse"
        dialogActions={
          <Box
            sx={{
              px: 4,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button type="error" onClick={closeAndResetStates}>
              Avbryt
            </Button>
            <Button type="info" onClick={onSaveClick}>
              Opprett sikkerhetshendelse
            </Button>
          </Box>
        }
      >
        {errorMessage !== "" ? (
          <Text sx={{ p: 1 }} variant="body1">
            {errorMessage}
          </Text>
        ) : (
          ""
        )}
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              p: 4,
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <DatePicker
              width={280}
              label="*Dato"
              value={selectedDate}
              onChange={(date) => {
                setSelectedDate(
                  new Date(
                    selectedDate.setFullYear(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate()
                    )
                  )
                );
              }}
            />

            <TimePicker
              width={280}
              label="*Klokkeslett"
              value={selectedDate}
              onChange={(date) => {
                setSelectedDate(new Date(selectedDate.setTime(date.getTime())));
              }}
            />
            <Box sx={{ width: 200 }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              p: 4,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <OptionsPicker
              width={280}
              label="Ulykkestype"
              optionsList={{
                [SecurityEventAccidentTypeEnum.DR]: "Drepte",
                [SecurityEventAccidentTypeEnum.MAS]: "Meget alvorlig skadet",
                [SecurityEventAccidentTypeEnum.AS]: "Alvorlige skadet",
                [SecurityEventAccidentTypeEnum.LS]: "Lettere skadet",
                [SecurityEventAccidentTypeEnum.PR]: "Skadet vegobjekt",
                [SecurityEventAccidentTypeEnum.AU]:
                  "Andre ulykker uten personskade",
              }}
              value={formData.accidentType}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  accidentType: SecurityEventAccidentTypeEnum[value],
                });
              }}
            />
            <TextField
              width={280}
              label="*Sted"
              value={formData.place}
              onChange={(value) => {
                setValidations({ ...validations, place: true });
                setFormData({
                  ...formData,
                  place: value.target.value,
                });
              }}
              error={!validations.place}
            />

            <TextField
              width={280}
              label="*Antall involverte"
              type="number"
              value={formData.involvedCount}
              onChange={(value) => {
                let newValue;
                if (value.target.value === "") {
                  newValue = "";
                } else {
                  newValue = +value.target.value;
                }
                setValidations({ ...validations, involvedCount: true });
                setFormData({
                  ...formData,
                  involvedCount: newValue,
                });
              }}
              error={!validations.involvedCount}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              p: 4,
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <MultilineTextField
              label="Kommentar"
              value={formData.comment}
              sx={{ height: "100%" }}
              minRows={6}
              onChange={(value) => {
                setValidations({ ...validations, comment: true });
                setFormData({
                  ...formData,
                  comment: value.target.value,
                });
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
