/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SectionCounterDataSections1,
    SectionCounterDataSections1FromJSON,
    SectionCounterDataSections1FromJSONTyped,
    SectionCounterDataSections1ToJSON,
} from './';

/**
 * 
 * @export
 * @interface SectionCounterDataQuarters
 */
export interface SectionCounterDataQuarters {
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataSections1; }}
     * @memberof SectionCounterDataQuarters
     */
    sections: { [key: string]: SectionCounterDataSections1; };
    /**
     * 
     * @type {string}
     * @memberof SectionCounterDataQuarters
     */
    availability: string;
}

export function SectionCounterDataQuartersFromJSON(json: any): SectionCounterDataQuarters {
    return SectionCounterDataQuartersFromJSONTyped(json, false);
}

export function SectionCounterDataQuartersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionCounterDataQuarters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': (mapValues(json['sections'], SectionCounterDataSections1FromJSON)),
        'availability': json['availability'],
    };
}

export function SectionCounterDataQuartersToJSON(value?: SectionCounterDataQuarters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': (mapValues(value.sections, SectionCounterDataSections1ToJSON)),
        'availability': value.availability,
    };
}


