import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSideMenuClosed, setDrawerClosed } from "redux/ym";
import { TStore } from "redux/store";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ListIcon from "@mui/icons-material/List";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Scroll from "@smartinspection/ameta-frontend-components/layout/Scroll";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";

import YmPointDialog from "components/ym/dialogs/YmPointDialog";
import DeleteYmPointDialog from "components/ym/dialogs/DeleteYmPointDialog";
import CreateYmMeasurementDialog from "components/ym/dialogs/CreateYmMeasurmentDialog";
import MeasurementCard from "components/ym/bottomDrawer/MeasurementCard";
import HistoricalDataDialog from "components/ym/dialogs/HistoricalDataDialog";
import YmHistoricalListDialog from "components/ym/dialogs/YmHistoricalListDialog";
import YmHistoricalListDialogAll from "components/ym/dialogs/YmHistoricalListDialogAll";

import { useYmPointQuery, useYmPointQueries } from "services/ymQueryHooks";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

export default function BottomDrawerContent({
  ymPointQueries,
}: {
  ymPointQueries: ReturnType<typeof useYmPointQueries>;
}) {
  const theme = useTheme();

  const [deleteDialogOpen, setDeleteDialigOpen] = useState(false);
  const [openYmPointDialog, setOpenYmPointDialog] = useState(false);
  const [openCreateYmMeasurementDialog, setOpenCreateYmMeasurementDialog] =
    useState(false);

  const [historicalDataDialogOpen, setHistoricalDataDialogOpen] =
    useState(false);

  const [historicalListDialogOpen, setHistoricalListDialogOpen] =
    useState(false);
  const [historicalListDialogAllOpen, setHistoricalListDialogAllOpen] =
    useState(false);
  const [historicaDataMeasurementType, setHistoricaDataMeasurementType] =
    useState("");

  const ymState = useSelector((state: TStore) => state.ym);
  const dispatch = useDispatch();

  const ymPointQuery = useYmPointQuery(ymState.selectedPointId);

  const ymPoint = ymPointQuery.data;
  const ymPointData = ymPoint?.data;

  if (ymPointQuery.isLoading) {
    return <CircularProgress size={100} />;
  }

  return ymPoint ? (
    <React.Fragment>
      {ymPoint && (
        <YmPointDialog
          ymPoint={ymPoint}
          open={openYmPointDialog}
          onClose={() => {
            setOpenYmPointDialog(false);
          }}
          onSave={() => {
            setOpenYmPointDialog(false);
            setTimeout(() => {
              ymPointQueries.refetch();
              ymPointQuery.refetch();
            }, 2000);
          }}
        />
      )}
      {ymPoint && (
        <DeleteYmPointDialog
          ymPointToDelete={ymPoint}
          open={deleteDialogOpen}
          onClose={() => {
            setDeleteDialigOpen(false);
            dispatch(setDrawerClosed());
            dispatch(setSideMenuClosed());

            setTimeout(() => {
              ymPointQueries.refetch();
              ymPointQuery.refetch();
            }, 2000);
          }}
        />
      )}
      {ymPoint && (
        <HistoricalDataDialog
          open={historicalDataDialogOpen}
          onClose={() => {
            setHistoricalDataDialogOpen(false);
          }}
          measurementType={historicaDataMeasurementType}
          ymPoint={ymPoint}
        />
      )}
      {ymPoint && (
        <YmHistoricalListDialog
          open={historicalListDialogOpen}
          refetch={() => {
            ymPointQueries.refetch();
            ymPointQuery.refetch();
          }}
          onClose={() => {
            setHistoricalListDialogOpen(false);
            ymPointQueries.refetch();
            ymPointQuery.refetch();
          }}
          measurementType={historicaDataMeasurementType}
          ymPoint={ymPoint}
        />
      )}
      {ymPoint && (
        <YmHistoricalListDialogAll
          open={historicalListDialogAllOpen}
          refetch={() => {
            ymPointQueries.refetch();
            ymPointQuery.refetch();
          }}
          onClose={() => {
            setHistoricalListDialogAllOpen(false);
            ymPointQueries.refetch();
            ymPointQuery.refetch();
          }}
          ymPoint={ymPoint}
        />
      )}

      <Scroll>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Tooltip
            placement="bottom"
            tooltipContent={<Text variant="body1">Rediger punkt</Text>}
          >
            <IconButton
              type="info"
              onClick={() => {
                setOpenYmPointDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            placement="bottom"
            tooltipContent={<Text variant="body1">Legg til ny måling</Text>}
          >
            <IconButton
              type="info"
              onClick={() => {
                setOpenCreateYmMeasurementDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            placement="bottom"
            tooltipContent={<Text variant="body1">Slett punkt</Text>}
          >
            <IconButton
              type="info"
              onClick={() => {
                setDeleteDialigOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            placement="bottom"
            tooltipContent={<Text variant="body1">Vis historikk</Text>}
          >
            <IconButton
              type="info"
              onClick={() => {
                setHistoricalListDialogAllOpen(true);
              }}
            >
              <ListIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            px: 1,
            flex: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ p: 1, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Prosjekt Id:
            </Text>
            <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {ymPoint && ymPoint.projectId}
            </Text>
          </Box>
          <Box sx={{ p: 1, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Målepunkt Id:
            </Text>
            <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {ymPoint && ymPoint.measurementPointId}
            </Text>
          </Box>
          <Box sx={{ p: 1, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Vannkilde Id:
            </Text>
            <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {ymPoint && ymPoint.waterSourceId}
            </Text>
          </Box>
          <Box sx={{ p: 1, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Sted:
            </Text>
            <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {ymPoint && ymPoint.place}
            </Text>
          </Box>
          <Box sx={{ p: 1, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Kommentar:
            </Text>
            <Text variant="body1" sx={{ color: theme.palette.text.secondary }}>
              {ymPoint && ymPoint.comments}
            </Text>
          </Box>
          <Box sx={{ p: 1, width: "50%" }}>
            <Text sx={{ fontWeight: 600 }} variant="body1">
              Status:
            </Text>
            {ymPoint && (
              <Text
                variant="body1"
                sx={{
                  fontWeight: 600,
                  color:
                    ymPoint.status === "alarm"
                      ? theme.palette.error.main
                      : ymPoint.status === "warning"
                      ? theme.palette.warning.main
                      : ymPoint.status === "unknown"
                      ? theme.palette.text.secondary
                      : theme.palette.success.main,
                }}
              >
                {ymPoint.status === "alarm"
                  ? "Alarmerende"
                  : ymPoint.status === "warning"
                  ? "Varslende"
                  : ymPoint.status === "unknown"
                  ? "Ukjent"
                  : "God"}
              </Text>
            )}
          </Box>
        </Box>
        <Divider orientation="horizontal" sx={{ width: "100%", mb: 2 }} />

        {ymPointData &&
          Object.entries(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ymPointData.latestMeasurements as any
          ).map((mapEntry, index) => {
            return (
              <MeasurementCard
                key={index}
                type={mapEntry[0]}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                value={(mapEntry[1] as any).value}
                threshold={
                  ymPoint?.thresholdValues &&
                  Number(ymPoint.thresholdValues[mapEntry[0]])
                }
                onHistoryButtonClicked={(selectedMeasurementType: string) => {
                  setHistoricaDataMeasurementType(selectedMeasurementType);
                  setHistoricalDataDialogOpen(true);
                }}
                onClickEdit={(selectedMeasurementType: string) => {
                  setHistoricaDataMeasurementType(selectedMeasurementType);
                  setHistoricalListDialogOpen(true);
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                date={(mapEntry[1] as any).date}
              />
            );
          })}

        {ymPoint && (
          <CreateYmMeasurementDialog
            ymPointId={ymPoint.id}
            ymPointMeasurementId={ymPoint.measurementPointId}
            isOpened={openCreateYmMeasurementDialog}
            onClose={() => {
              setOpenCreateYmMeasurementDialog(false);
              setTimeout(() => {
                ymPointQueries.refetch();
                ymPointQuery.refetch();
              }, 2000);
            }}
          />
        )}
      </Scroll>
    </React.Fragment>
  ) : null;
}
