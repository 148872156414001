/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YmPointGeojsonGeometry,
    YmPointGeojsonGeometryFromJSON,
    YmPointGeojsonGeometryFromJSONTyped,
    YmPointGeojsonGeometryToJSON,
} from './';

/**
 * 
 * @export
 * @interface YmPointGeojson
 */
export interface YmPointGeojson {
    /**
     * 
     * @type {YmPointGeojsonGeometry}
     * @memberof YmPointGeojson
     */
    geometry?: YmPointGeojsonGeometry;
    /**
     * 
     * @type {string}
     * @memberof YmPointGeojson
     */
    type?: string;
}

export function YmPointGeojsonFromJSON(json: any): YmPointGeojson {
    return YmPointGeojsonFromJSONTyped(json, false);
}

export function YmPointGeojsonFromJSONTyped(json: any, ignoreDiscriminator: boolean): YmPointGeojson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'geometry': !exists(json, 'geometry') ? undefined : YmPointGeojsonGeometryFromJSON(json['geometry']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function YmPointGeojsonToJSON(value?: YmPointGeojson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'geometry': YmPointGeojsonGeometryToJSON(value.geometry),
        'type': value.type,
    };
}


