import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setCreateDialogClosed,
  setCreateDialogOpen,
  setDrawerClosed,
  setFileUploadDialogOpen,
  setFileUploadDialogClosed,
} from "redux/ym";
import { TStore } from "redux/store";

import Box from "@mui/material/Box";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AddFileIcon from "@mui/icons-material/UploadFile";
import TopView from "components/TopView";
import MapView from "components/ym/mapView/MapView";
import YmPointDialog from "components/ym/dialogs/YmPointDialog";
import YmFileUploadDialog from "components/ym/dialogs/YmFileUploadDialog";
import WithSideDrawer from "@smartinspection/ameta-frontend-components/layout/WithSideDrawer";
import DrawerContent from "components/ym/bottomDrawer/DrawerContent";
import { useYmPointQueries } from "services/ymQueryHooks";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";

export default function YmMap() {
  const ymState = useSelector((state: TStore) => state.ym);
  const ymPointQueries = useYmPointQueries(ymState.hashArray);
  const dispatch = useDispatch();
  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ position: "absolute", px: 1.2, top: 100, zIndex: 2 }}>
        <Tooltip
          placement="right"
          tooltipContent={<Text variant="body1">Legg til nytt ym punkt</Text>}
        >
          <IconButton
            type="info"
            onClick={() => {
              dispatch(setCreateDialogOpen());
            }}
          >
            <AddLocationIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ position: "absolute", px: 1.2, top: 150, zIndex: 2 }}>
        <Tooltip
          placement="right"
          tooltipContent={<Text variant="body1">Legg til nye måliger</Text>}
        >
          <IconButton
            type="info"
            onClick={() => {
              dispatch(setFileUploadDialogOpen());
            }}
          >
            <AddFileIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <YmPointDialog
        open={ymState.createDialog}
        onClose={() => {
          dispatch(setCreateDialogClosed());
        }}
        onSave={() => {
          setTimeout(() => {
            ymPointQueries.refetch();
          }, 2000);
        }}
      />
      <YmFileUploadDialog
        open={ymState.fileUploadDialog}
        onClose={() => {
          dispatch(setFileUploadDialogClosed());
        }}
      />
      <TopView title="Miljø" />
      <WithSideDrawer
        placement="left"
        onClickAway={() => {
          dispatch(setDrawerClosed());
        }}
        open={ymState.drawerOpen}
        drawerContent={<DrawerContent ymPointQueries={ymPointQueries} />}
      >
        <MapView ymPointQueries={ymPointQueries} />
      </WithSideDrawer>
    </Box>
  );
}
