/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Security API
 * A rest api for managing the ameta security system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SecurityMonthObject,
    SecurityMonthObjectFromJSON,
    SecurityMonthObjectFromJSONTyped,
    SecurityMonthObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface SecurityYearObject
 */
export interface SecurityYearObject {
    /**
     * 
     * @type {number}
     * @memberof SecurityYearObject
     */
    accidentsWithLightPersonalInjuries: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityYearObject
     */
    accidentsWithSignificantPersonalInjuries: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityYearObject
     */
    accidentsWithSeriousPersonalInjuries: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityYearObject
     */
    fatalAccidents: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityYearObject
     */
    hitRailings: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityYearObject
     */
    otherAccidents: number;
    /**
     * 
     * @type {{ [key: string]: SecurityMonthObject; }}
     * @memberof SecurityYearObject
     */
    months: { [key: string]: SecurityMonthObject; };
}

export function SecurityYearObjectFromJSON(json: any): SecurityYearObject {
    return SecurityYearObjectFromJSONTyped(json, false);
}

export function SecurityYearObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityYearObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accidentsWithLightPersonalInjuries': json['accidents_with_light_personal_injuries'],
        'accidentsWithSignificantPersonalInjuries': json['accidents_with_significant_personal_injuries'],
        'accidentsWithSeriousPersonalInjuries': json['accidents_with_serious_personal_injuries'],
        'fatalAccidents': json['fatal_accidents'],
        'hitRailings': json['hit_railings'],
        'otherAccidents': json['other_accidents'],
        'months': (mapValues(json['months'], SecurityMonthObjectFromJSON)),
    };
}

export function SecurityYearObjectToJSON(value?: SecurityYearObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accidents_with_light_personal_injuries': value.accidentsWithLightPersonalInjuries,
        'accidents_with_significant_personal_injuries': value.accidentsWithSignificantPersonalInjuries,
        'accidents_with_serious_personal_injuries': value.accidentsWithSeriousPersonalInjuries,
        'fatal_accidents': value.fatalAccidents,
        'hit_railings': value.hitRailings,
        'other_accidents': value.otherAccidents,
        'months': (mapValues(value.months, SecurityMonthObjectToJSON)),
    };
}


