/* eslint-disable no-constant-condition */
import React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Table from "@smartinspection/ameta-frontend-components/display/Table";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

function DocumentUploadList({
  listElements,
  onRemove,
}: {
  listElements: File[];
  onRemove: (element) => void;
}) {
  const theme = useTheme();
  const tableData = listElements.map((element) => {
    return {
      name: (
        <Text
          sx={{
            pr: 1,
            color:
              element.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
              "application/vnd.ms-excel.sheet.macroEnabled.12" ||
              element.type === "text/csv"
                ? theme.palette.text.secondary
                : theme.palette.error.main,
          }}
          variant="body1"
        >
          {element.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          "application/vnd.ms-excel.sheet.macroEnabled.12" ||
          element.type === "text/csv"
            ? element.name
            : element.name + " (feil filtype)"}
        </Text>
      ),
      delete: (
        <IconButton
          noShadow
          type="info"
          onClick={() => {
            onRemove(element);
          }}
        >
          <CloseIcon />
        </IconButton>
      ),
    };
  });

  return (
    <Box sx={{ height: "40%", width: "100%" }}>
      <Divider />
      {tableData.length > 0 && (
        <Table
          headers={[
            { fieldName: "name", name: "Navn", align: "center" },
            { fieldName: "delete", name: "Slett", align: "center" },
          ]}
          data={tableData}
        />
      )}

      <Divider />
    </Box>
  );
}

export default DocumentUploadList;
