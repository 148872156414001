import React, { useRef, useState } from "react";
import { useEffect } from "react";
import DragAndDropIndicator from "components/common/DragAndDropIndicator";

const Dropzone = ({
  onDrop,
  onFilesSelected,
}: {
  onDrop: (acceptedFiles: File[]) => void;
  onFilesSelected: (files: File[]) => void;
}) => {
  const [dragging, setDragging] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const dropzoneRef = useRef<HTMLDivElement>(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter + 1);
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => {
      const newCount = prev - 1;

      if (newCount === 1) {
        setDragging(false);
        setDragCounter(0);
      }

      return newCount;
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const fileList = (e.dataTransfer as HTMLInputElement).files;
      const files = fileList && Array.from(fileList);
      files && onDrop && onDrop(files);
      // e.dataTransfer.clearData(); //Crashes Firefox
      setDragCounter(0);
    }
  };

  useEffect(() => {
    const dropRef = dropzoneRef.current; // avoiding linting error
    if (dropRef) {
      dropRef.addEventListener("dragover", handleDragOver);
      dropRef.addEventListener("dragenter", handleDragEnter);
      dropRef.addEventListener("dragleave", handleDragLeave);
      dropRef.addEventListener("drop", handleDrop);
    }

    return () => {
      if (dropRef) {
        dropRef.removeEventListener("dragover", handleDragOver);
        dropRef.removeEventListener("dragenter", handleDragEnter);
        dropRef.removeEventListener("dragleave", handleDragLeave);
        dropRef.removeEventListener("drop", handleDrop);
      }
    };
  });

  return (
    <div ref={dropzoneRef} style={{ position: "relative", height: "100%" }}>
      <DragAndDropIndicator
        text={
          dragging
            ? "Slipp filene for å laste opp"
            : "Dra og slipp eller last opp rapporten din her"
        }
        selectedFiles={onFilesSelected}
      />
    </div>
  );
};

export default Dropzone;
