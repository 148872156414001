import React from "react";
import { YmPoint } from "services/ymApi/models";

import GeoJson from "@smartinspection/ameta-frontend-components/map/GeoJson";

function YmMarkers({
  ymData,
  onClick,
}: {
  ymData: YmPoint[];
  onClick?: (point: YmPoint) => void;
}) {
  return (
    <React.Fragment>
      {Object.values(ymData).map((point) => {
        const markersData = point.geojson;

        const markerIcon =
          point.status === "ok"
            ? "check"
            : point.status === "warning"
            ? "sort-up"
            : point.status === "alarm"
            ? "exclamation"
            : "question-circle";

        const markerColor =
          point.status === "ok"
            ? "green"
            : point.status === "warning"
            ? "orange"
            : point.status === "alarm"
            ? "red"
            : "cadetblue";

        return (
          <GeoJson
            key={point.id}
            data={markersData}
            onClick={
              onClick
                ? () => {
                    onClick(point);
                  }
                : undefined
            }
            marker={{ icon: markerIcon, color: markerColor }}
          />
        );
      })}
    </React.Fragment>
  );
}

export default YmMarkers;
