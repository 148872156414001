/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewYmMeasurement
 */
export interface NewYmMeasurement {
    /**
     * 
     * @type {string}
     * @memberof NewYmMeasurement
     */
    date: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof NewYmMeasurement
     */
    data: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof NewYmMeasurement
     */
    measurementPointId: string;
}

export function NewYmMeasurementFromJSON(json: any): NewYmMeasurement {
    return NewYmMeasurementFromJSONTyped(json, false);
}

export function NewYmMeasurementFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewYmMeasurement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'data': json['data'],
        'measurementPointId': json['measurementPointId'],
    };
}

export function NewYmMeasurementToJSON(value?: NewYmMeasurement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'data': value.data,
        'measurementPointId': value.measurementPointId,
    };
}


