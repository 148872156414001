/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteYmMeasurement,
    DeleteYmMeasurementFromJSON,
    DeleteYmMeasurementToJSON,
    DocumentReport,
    DocumentReportFromJSON,
    DocumentReportToJSON,
    NewDocument,
    NewDocumentFromJSON,
    NewDocumentToJSON,
    NewYmMeasurement,
    NewYmMeasurementFromJSON,
    NewYmMeasurementToJSON,
    NewYmPoint,
    NewYmPointFromJSON,
    NewYmPointToJSON,
    UpdateYmMeasurement,
    UpdateYmMeasurementFromJSON,
    UpdateYmMeasurementToJSON,
    UpdateYmPoint,
    UpdateYmPointFromJSON,
    UpdateYmPointToJSON,
    UploadDocumentObject,
    UploadDocumentObjectFromJSON,
    UploadDocumentObjectToJSON,
    YMOverviewResponse,
    YMOverviewResponseFromJSON,
    YMOverviewResponseToJSON,
    YMPointsResponse,
    YMPointsResponseFromJSON,
    YMPointsResponseToJSON,
    YmPoint,
    YmPointFromJSON,
    YmPointToJSON,
} from '../models';

export interface CreateYmDocumentURLRequest {
    newDocument: NewDocument;
}

export interface CreateYmMeasurementRequest {
    pointId: string;
    newYmMeasurement: NewYmMeasurement;
}

export interface CreateYmPointRequest {
    newYmPoint: NewYmPoint;
}

export interface DeleteYmMeasurementRequest {
    referenceId: string;
    deleteYmMeasurement: DeleteYmMeasurement;
}

export interface DeleteYmMeasurementTypeRequest {
    pointId: string;
    measurementType: string;
}

export interface DeleteYmPointRequest {
    pointId: string;
}

export interface GetDocumentReportRequest {
    documentId: string;
}

export interface GetYmPointRequest {
    pointId: string;
}

export interface GetYmPointsRequest {
    hashKey: string;
    lastEvaluatedKey?: string;
}

export interface UpdateYmMeasurementRequest {
    referenceId: string;
    updateYmMeasurement: UpdateYmMeasurement;
}

export interface UpdateYmPointRequest {
    pointId: string;
    updateYmPoint: UpdateYmPoint;
}

/**
 * 
 */
export class YmApi extends runtime.BaseAPI {

    /**
     * Create a new ym document signed url
     */
    async createYmDocumentURLRaw(requestParameters: CreateYmDocumentURLRequest): Promise<runtime.ApiResponse<UploadDocumentObject>> {
        if (requestParameters.newDocument === null || requestParameters.newDocument === undefined) {
            throw new runtime.RequiredError('newDocument','Required parameter requestParameters.newDocument was null or undefined when calling createYmDocumentURL.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewDocumentToJSON(requestParameters.newDocument),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadDocumentObjectFromJSON(jsonValue));
    }

    /**
     * Create a new ym document signed url
     */
    async createYmDocumentURL(requestParameters: CreateYmDocumentURLRequest): Promise<UploadDocumentObject> {
        const response = await this.createYmDocumentURLRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new ym measurement
     */
    async createYmMeasurementRaw(requestParameters: CreateYmMeasurementRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.pointId === null || requestParameters.pointId === undefined) {
            throw new runtime.RequiredError('pointId','Required parameter requestParameters.pointId was null or undefined when calling createYmMeasurement.');
        }

        if (requestParameters.newYmMeasurement === null || requestParameters.newYmMeasurement === undefined) {
            throw new runtime.RequiredError('newYmMeasurement','Required parameter requestParameters.newYmMeasurement was null or undefined when calling createYmMeasurement.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/points/{pointId}/measurements`.replace(`{${"pointId"}}`, encodeURIComponent(String(requestParameters.pointId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewYmMeasurementToJSON(requestParameters.newYmMeasurement),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a new ym measurement
     */
    async createYmMeasurement(requestParameters: CreateYmMeasurementRequest): Promise<string> {
        const response = await this.createYmMeasurementRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new ym point
     */
    async createYmPointRaw(requestParameters: CreateYmPointRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.newYmPoint === null || requestParameters.newYmPoint === undefined) {
            throw new runtime.RequiredError('newYmPoint','Required parameter requestParameters.newYmPoint was null or undefined when calling createYmPoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/points`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewYmPointToJSON(requestParameters.newYmPoint),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a new ym point
     */
    async createYmPoint(requestParameters: CreateYmPointRequest): Promise<string> {
        const response = await this.createYmPointRaw(requestParameters);
        return await response.value();
    }

    /**
     * deleted a ym measurement
     */
    async deleteYmMeasurementRaw(requestParameters: DeleteYmMeasurementRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.referenceId === null || requestParameters.referenceId === undefined) {
            throw new runtime.RequiredError('referenceId','Required parameter requestParameters.referenceId was null or undefined when calling deleteYmMeasurement.');
        }

        if (requestParameters.deleteYmMeasurement === null || requestParameters.deleteYmMeasurement === undefined) {
            throw new runtime.RequiredError('deleteYmMeasurement','Required parameter requestParameters.deleteYmMeasurement was null or undefined when calling deleteYmMeasurement.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/measurements/{referenceId}`.replace(`{${"referenceId"}}`, encodeURIComponent(String(requestParameters.referenceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteYmMeasurementToJSON(requestParameters.deleteYmMeasurement),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * deleted a ym measurement
     */
    async deleteYmMeasurement(requestParameters: DeleteYmMeasurementRequest): Promise<string> {
        const response = await this.deleteYmMeasurementRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete ym measurement
     */
    async deleteYmMeasurementTypeRaw(requestParameters: DeleteYmMeasurementTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pointId === null || requestParameters.pointId === undefined) {
            throw new runtime.RequiredError('pointId','Required parameter requestParameters.pointId was null or undefined when calling deleteYmMeasurementType.');
        }

        if (requestParameters.measurementType === null || requestParameters.measurementType === undefined) {
            throw new runtime.RequiredError('measurementType','Required parameter requestParameters.measurementType was null or undefined when calling deleteYmMeasurementType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/points/{pointId}/measurements/{measurementType}`.replace(`{${"pointId"}}`, encodeURIComponent(String(requestParameters.pointId))).replace(`{${"measurementType"}}`, encodeURIComponent(String(requestParameters.measurementType))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete ym measurement
     */
    async deleteYmMeasurementType(requestParameters: DeleteYmMeasurementTypeRequest): Promise<void> {
        await this.deleteYmMeasurementTypeRaw(requestParameters);
    }

    /**
     * delete ym point
     */
    async deleteYmPointRaw(requestParameters: DeleteYmPointRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pointId === null || requestParameters.pointId === undefined) {
            throw new runtime.RequiredError('pointId','Required parameter requestParameters.pointId was null or undefined when calling deleteYmPoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/points/{pointId}`.replace(`{${"pointId"}}`, encodeURIComponent(String(requestParameters.pointId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete ym point
     */
    async deleteYmPoint(requestParameters: DeleteYmPointRequest): Promise<void> {
        await this.deleteYmPointRaw(requestParameters);
    }

    /**
     * Get a ym document report by document id
     */
    async getDocumentReportRaw(requestParameters: GetDocumentReportRequest): Promise<runtime.ApiResponse<DocumentReport>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/document/{documentId}/report`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentReportFromJSON(jsonValue));
    }

    /**
     * Get a ym document report by document id
     */
    async getDocumentReport(requestParameters: GetDocumentReportRequest): Promise<DocumentReport> {
        const response = await this.getDocumentReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * get an overview of all ym points
     */
    async getOverviewRaw(): Promise<runtime.ApiResponse<YMOverviewResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => YMOverviewResponseFromJSON(jsonValue));
    }

    /**
     * get an overview of all ym points
     */
    async getOverview(): Promise<YMOverviewResponse> {
        const response = await this.getOverviewRaw();
        return await response.value();
    }

    /**
     * Get a ym point
     */
    async getYmPointRaw(requestParameters: GetYmPointRequest): Promise<runtime.ApiResponse<YmPoint>> {
        if (requestParameters.pointId === null || requestParameters.pointId === undefined) {
            throw new runtime.RequiredError('pointId','Required parameter requestParameters.pointId was null or undefined when calling getYmPoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/points/{pointId}`.replace(`{${"pointId"}}`, encodeURIComponent(String(requestParameters.pointId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => YmPointFromJSON(jsonValue));
    }

    /**
     * Get a ym point
     */
    async getYmPoint(requestParameters: GetYmPointRequest): Promise<YmPoint> {
        const response = await this.getYmPointRaw(requestParameters);
        return await response.value();
    }

    /**
     * get ym points on from hash key
     */
    async getYmPointsRaw(requestParameters: GetYmPointsRequest): Promise<runtime.ApiResponse<YMPointsResponse>> {
        if (requestParameters.hashKey === null || requestParameters.hashKey === undefined) {
            throw new runtime.RequiredError('hashKey','Required parameter requestParameters.hashKey was null or undefined when calling getYmPoints.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.hashKey !== undefined) {
            queryParameters['hashKey'] = requestParameters.hashKey;
        }

        if (requestParameters.lastEvaluatedKey !== undefined) {
            queryParameters['lastEvaluatedKey'] = requestParameters.lastEvaluatedKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/points/query`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => YMPointsResponseFromJSON(jsonValue));
    }

    /**
     * get ym points on from hash key
     */
    async getYmPoints(requestParameters: GetYmPointsRequest): Promise<YMPointsResponse> {
        const response = await this.getYmPointsRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a ym measurement
     */
    async updateYmMeasurementRaw(requestParameters: UpdateYmMeasurementRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.referenceId === null || requestParameters.referenceId === undefined) {
            throw new runtime.RequiredError('referenceId','Required parameter requestParameters.referenceId was null or undefined when calling updateYmMeasurement.');
        }

        if (requestParameters.updateYmMeasurement === null || requestParameters.updateYmMeasurement === undefined) {
            throw new runtime.RequiredError('updateYmMeasurement','Required parameter requestParameters.updateYmMeasurement was null or undefined when calling updateYmMeasurement.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/measurements/{referenceId}`.replace(`{${"referenceId"}}`, encodeURIComponent(String(requestParameters.referenceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateYmMeasurementToJSON(requestParameters.updateYmMeasurement),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * update a ym measurement
     */
    async updateYmMeasurement(requestParameters: UpdateYmMeasurementRequest): Promise<string> {
        const response = await this.updateYmMeasurementRaw(requestParameters);
        return await response.value();
    }

    /**
     * update a ym point
     */
    async updateYmPointRaw(requestParameters: UpdateYmPointRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.pointId === null || requestParameters.pointId === undefined) {
            throw new runtime.RequiredError('pointId','Required parameter requestParameters.pointId was null or undefined when calling updateYmPoint.');
        }

        if (requestParameters.updateYmPoint === null || requestParameters.updateYmPoint === undefined) {
            throw new runtime.RequiredError('updateYmPoint','Required parameter requestParameters.updateYmPoint was null or undefined when calling updateYmPoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/points/{pointId}`.replace(`{${"pointId"}}`, encodeURIComponent(String(requestParameters.pointId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateYmPointToJSON(requestParameters.updateYmPoint),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * update a ym point
     */
    async updateYmPoint(requestParameters: UpdateYmPointRequest): Promise<string> {
        const response = await this.updateYmPointRaw(requestParameters);
        return await response.value();
    }

}
