import React from "react";
import { useSelector } from "react-redux";
import { TStore } from "redux/store";

import Box from "@mui/material/Box";

import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
import PageMenu from "@smartinspection/ameta-frontend-components/navigation/PageMenu";

import TopView from "components/TopView";

import MonthlyOverview from "components/security/MonthlyOverview";
import SecurityBarChart from "components/security/SecurityBarChart";
import SecurityReportDialog from "components/security/dialog/SecurityReportDialog";
import AddSecurityEventDialog from "components/security/dialog/AddSecurityEventDialog";
import { useQuerySecurityCounter } from "services/securityQueryHooks";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";

export default function Security() {
  const securityCounterQuery = useQuerySecurityCounter();

  const sectionCounterData = securityCounterQuery.data;

  const globalState = useSelector((state: TStore) => state.global);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Sikkerhet" />
      {securityCounterQuery.isLoading ? (
        <CenterContainer>
          <CircularProgress size={150} />
        </CenterContainer>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <PageMenu
            sx={{ pl: 3, p: 0 }}
            hideMenu={globalState.hideMenus}
            transparent
          >
            <Box sx={{ py: 1 }}>
              <AddSecurityEventDialog refetch={securityCounterQuery.refetch} />
            </Box>
            {sectionCounterData && (
              <Box sx={{ py: 1 }}>
                <SecurityReportDialog
                  securityCounterData={sectionCounterData}
                  refetch={securityCounterQuery.refetch}
                />
              </Box>
            )}
          </PageMenu>
          {sectionCounterData && (
            <GridLayout
              editable={globalState.layoutEditable}
              layout={[
                {
                  i: "MonthlyOverview",
                  h: 15,
                  w: 12,
                  x: 0,
                  y: 0,
                },
                {
                  i: "SecurityBarChart",
                  h: 15,
                  w: 12,
                  x: 0,
                  y: 15,
                },
              ]}
            >
              <MonthlyOverview
                key="MonthlyOverview"
                securityCounterData={sectionCounterData}
              />
              <SecurityBarChart
                key="SecurityBarChart"
                securityCounterData={sectionCounterData}
              />
            </GridLayout>
          )}
        </Box>
      )}
    </Box>
  );
}
