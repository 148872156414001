import React from "react";
import { useSelector } from "react-redux";
import { TStore } from "redux/store";

import Box from "@mui/material/Box";

import TopView from "components/TopView";
import PassingsPerHour from "components/traffic/PassingsPerHour";
import PassingsVehicleSize from "components/traffic/PassingsVehicleSize";
import PassingsPastThreeYears from "components/traffic/PassingsPastThreeYears";

import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";

function TrafficView() {
  const globalState = useSelector((state: TStore) => state.global);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Dashboard" />
      <GridLayout
        onLayoutChange={(layout) => {}}
        editable={globalState.layoutEditable}
        layout={[
          {
            i: "PassingsPerHour",
            h: 15,
            w: 6,
            x: 0,
            y: 0,
          },
          {
            i: "PassingsVehicleSize",
            h: 15,
            w: 6,
            x: 6,
            y: 0,
          },
          {
            i: "PassingsPastThreeYears",
            h: 15,
            w: 12,
            x: 0,
            y: 15,
          },
        ]}
      >
        <PassingsPerHour key="PassingsPerHour" />
        <PassingsVehicleSize key="PassingsVehicleSize" />
        <PassingsPastThreeYears key="PassingsPastThreeYears" />
      </GridLayout>
    </Box>
  );
}

export default TrafficView;
