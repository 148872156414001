import React from "react";
import {
  XAxis,
  YAxis,
  Bar,
  BarChart,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

export default function BarIndicatior({
  value,
  threshold,
  color,
  domain,
}: {
  value: number;
  threshold?: number;
  color: React.CSSProperties["color"];
  domain?: number[];
}) {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        margin={{ left: 0, right: 50 }}
        layout="vertical"
        data={[{ value, name: "" }]}
      >
        <XAxis type="number" />
        <YAxis
          tick={false}
          dataKey="name"
          type="category"
          interval={0}
          domain={[
            0,
            threshold && threshold > value
              ? Math.round(threshold * 1.3)
              : Math.round(value * 1.3),
          ]}
        />
        <Bar radius={10} dataKey="value" fill={color} />
        <ReferenceLine
          x={threshold}
          stroke={theme.palette.ameta.background.dark}
          strokeWidth={3}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
