import React, { useState } from "react";
import format from "date-fns/format";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";

import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";

import { SJAReport } from "services/hmsApi";

import { authorizedSjaReportApi } from "services/apiService";

export default function AddRUHDialog({ refetch }: { refetch: () => void }) {
  const [open, setOpen] = useState(false);

  const initialDate = new Date();

  const initialForm: SJAReport = {
    date: format(initialDate, "dd.MM.yyyy"),
    sjaType: "",
    sjaNr: -1,
    company: "",
  };

  const initialValidation: { [k: string]: boolean } = Object.fromEntries(
    Object.keys(initialForm).map((key) => [key, true])
  );

  const [selectedDate, setSelectedDate] = useState(initialDate);

  const [formData, setFormData] = useState(initialForm);
  const [vaildations, setValidations] = useState(initialValidation);
  const [errorMessage, setErrorMessage] = useState("");
  const validateForm = (form: any) => {
    const currentValidation = { ...vaildations };
    Object.keys(currentValidation).forEach((key) => {
      if ((!form[key] || form[key] < 0) && form[key] !== 0) {
        currentValidation[key] = false;
      }
    }); // for each key, set validation to false if there's nothing there
    setValidations(currentValidation);
    return Object.keys(currentValidation).every(
      (key) => currentValidation[key] // 'and' all values
    );
  };

  const closeAndResetStates = () => {
    setErrorMessage("");
    setSelectedDate(initialDate);
    setFormData(initialForm);
    setValidations(initialValidation);
    setOpen(false);
  };

  function onSaveClick() {
    if (!validateForm(formData)) {
      setErrorMessage("Du må fylle ut alle feltene");
    } else {
      authorizedSjaReportApi().then((api) => {
        api
          .createSjaReport({
            sJAReport: {
              ...formData,
              date: format(selectedDate, "dd.MM.yyyy"),
            },
          })
          .then(refetch);
      });
      closeAndResetStates();
    }
  }

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={<Text variant="body1">Legg til ny SJA-rapport</Text>}
      >
        <IconButton
          type="success"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        type="drawer"
        open={open}
        paperSx={{ minWidth: 600, height: "70%" }}
        onClose={closeAndResetStates}
        title="Legg til månedsrapport for SJA"
        dialogActions={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              px: 4,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button type="error" onClick={closeAndResetStates}>
              Avbryt
            </Button>
            <Button colored type="success" onClick={onSaveClick}>
              Legg til rapport
            </Button>
          </Box>
        }
      >
        {errorMessage !== "" ? (
          <Text sx={{ p: 1 }} variant="body1">
            {errorMessage}
          </Text>
        ) : (
          ""
        )}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            px: 4,
          }}
        >
          <DatePicker
            width={280}
            label="Startdato"
            value={selectedDate}
            onChange={setSelectedDate}
          />
          <TextField
            width={280}
            label="Firma"
            value={formData.company}
            onChange={(value) => {
              setValidations({ ...vaildations, company: true });
              setFormData({
                ...formData,
                company: value.target.value,
              });
            }}
            error={!vaildations.company}
          />
          <TextField
            width={280}
            label="SJA type"
            value={formData.sjaType}
            onChange={(value) => {
              setValidations({ ...vaildations, sjaType: true });
              setFormData({
                ...formData,
                sjaType: value.target.value,
              });
            }}
            error={!vaildations.sjaType}
          />

          <TextField
            width={280}
            label="SJA Nr"
            type="number"
            value={formData.sjaNr}
            onChange={(value) => {
              setValidations({ ...vaildations, sjaNr: true });
              setFormData({
                ...formData,
                sjaNr: Number(value.target.value),
              });
            }}
            error={!vaildations.sjaNr}
          />
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
