import React, { useState } from "react";
import format from "date-fns/format";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";

import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";

import { authorizedNhEventApi } from "services/apiService";

export default function AddNAndHDialog({ refetch }: { refetch: () => void }) {
  const [open, setOpen] = useState(false);

  const initialDate = new Date();

  interface NHReportNumber {
    date: string;
    nValue: any;
    h1Value: any;
    h2Value: any;
  }

  const initialForm: NHReportNumber = {
    date: format(initialDate, "MM.yyyy"),
    nValue: "",
    h1Value: "",
    h2Value: "",
  };

  const initialValidation: { [k: string]: boolean } = Object.fromEntries(
    Object.keys(initialForm).map((key) => [key, true])
  );

  const [selectedDate, setSelectedDate] = useState(initialDate);

  const [formData, setFormData] = useState<NHReportNumber>(initialForm);
  const [vaildations, setValidations] = useState(initialValidation);
  const [errorMessage, setErrorMessage] = useState("");
  const validateForm = (form: any) => {
    const currentValidation = { ...vaildations };
    Object.keys(currentValidation).forEach((key) => {
      if ((!form[key] || form[key] < 0) && form[key] !== 0) {
        currentValidation[key] = false;
      }
    }); // for each key, set validation to false if there's nothing there
    setValidations(currentValidation);
    return Object.keys(currentValidation).every(
      (key) => currentValidation[key] // 'and' all values
    );
  };
  const closeAndResetStates = () => {
    setErrorMessage("");
    setSelectedDate(initialDate);
    setFormData(initialForm);
    setValidations(initialValidation);
    setOpen(false);
  };

  function onSaveClick() {
    if (!validateForm(formData)) {
      setErrorMessage("Du må fylle ut alle feltene");
    } else {
      const formDataString = {
        nValue: String(formData.nValue),
        h1Value: String(formData.h1Value),
        h2Value: String(formData.h2Value),
      };
      authorizedNhEventApi().then((api) => {
        api
          .createNhReport({
            nHReport: {
              ...formDataString,
              date: format(selectedDate, "MM.yyyy"),
            },
          })
          .then(refetch);
      });
      closeAndResetStates();
    }
  }

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={<Text variant="body1">Legg til ny N&H-rapport</Text>}
      >
        <IconButton
          type="success"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        type="drawer"
        open={open}
        paperSx={{ minWidth: 400, height: "60%" }}
        onClose={closeAndResetStates}
        title="Legg til månedsrapport for N- og H-verdier"
        dialogActions={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              px: 4,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button type="error" onClick={closeAndResetStates}>
              Avbryt
            </Button>
            <Button colored type="success" onClick={onSaveClick}>
              Legg til rapport
            </Button>
          </Box>
        }
      >
        {errorMessage !== "" ? (
          <Text sx={{ p: 1 }} variant="body1">
            {errorMessage}
          </Text>
        ) : (
          ""
        )}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            p: 2,
          }}
        >
          <DatePicker
            width={280}
            views={["year", "month"]}
            inputFormat="LLLL yyyy"
            label="Måned og år"
            value={selectedDate}
            onChange={setSelectedDate}
          />
          <TextField
            width={280}
            lang="nb-NO" // added to support both . and , for input
            label="N-verdi"
            type="number"
            value={formData.nValue}
            onChange={(newValue) => {
              setFormData({
                ...formData,
                nValue: +newValue.currentTarget.value,
              });
              setValidations({ ...vaildations, nValue: true });
            }}
            error={!vaildations.nValue}
          />
          <TextField
            lang="nb-NO"
            width={280}
            label="H1-verdi"
            type="number"
            value={formData.h1Value}
            onChange={(newValue) => {
              setFormData({
                ...formData,
                h1Value: +newValue.currentTarget.value,
              });
              setValidations({ ...vaildations, h1Value: true });
            }}
            error={!vaildations.hValue}
          />
          <TextField
            width={280}
            lang="nb-NO"
            label="H2-verdi"
            type="number"
            value={formData.h2Value}
            onChange={(newValue) => {
              setFormData({
                ...formData,
                h2Value: +newValue.currentTarget.value,
              });
              setValidations({ ...vaildations, h2Value: true });
            }}
            error={!vaildations.h2Value}
          />
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
