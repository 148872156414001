/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadDocumentObject
 */
export interface UploadDocumentObject {
    /**
     * 
     * @type {string}
     * @memberof UploadDocumentObject
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDocumentObject
     */
    signedUrl: string;
}

export function UploadDocumentObjectFromJSON(json: any): UploadDocumentObject {
    return UploadDocumentObjectFromJSONTyped(json, false);
}

export function UploadDocumentObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadDocumentObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': json['documentId'],
        'signedUrl': json['signedUrl'],
    };
}

export function UploadDocumentObjectToJSON(value?: UploadDocumentObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'signedUrl': value.signedUrl,
    };
}


