import { SecurityYearObject } from "services/securityApi";

export type MonthOverviewData = {
  id: string;
  monthlyOverview: number;
  soFarSelectedYear: number;
};

function secuityNumbersYearMonthMapper(
  year: SecurityYearObject | undefined,
  selectedMonth: string,
  mappingDict: { [key: string]: string }
) {
  const securityNumbers: MonthOverviewData[] = [];
  let tempYearObject: any = {};
  let tempMonthObject: any = {};

  if (year?.months[selectedMonth]) {
    Object.entries(year.months[selectedMonth].days).forEach(([_, days]) => {
      days.forEach((day) => {
        const type = day.accidentType;
        tempMonthObject = {
          ...tempMonthObject,
          [type]: tempMonthObject?.[type]
            ? tempMonthObject[type] + day.involvedCount
            : day.involvedCount,
        };
      });
    });
  }
  if (year) {
    Object.entries(year.months).forEach(([_, month]) => {
      Object.entries(month.days).forEach(([_, days]) => {
        days.forEach((day) => {
          const type = day.accidentType;

          tempYearObject = {
            ...tempYearObject,
            [type]: tempYearObject?.[type]
              ? tempYearObject[type] + day.involvedCount
              : day.involvedCount,
          };
        });
      });
    });
  }

  // DR = Drepte, MAS = Meget alvorlig skadet, AS = Alvorlige skadet, LS = Lettere skadet, PR = Skadet vegobjekt, AU = Andre ulykker uten personskade
  Object.entries(mappingDict).forEach(([key, value]) => {
    securityNumbers.push({
      id: value,
      monthlyOverview: tempMonthObject?.[key] ? tempMonthObject[key] : 0,
      soFarSelectedYear: tempYearObject?.[key] ? tempYearObject[key] : 0,
    });
  });
  return securityNumbers;
}

export default secuityNumbersYearMonthMapper;
