import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setFileUploadDialogOpen,
  setFileUploadDialogClosed,
} from "redux/document";
import { TStore } from "redux/store";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import AddFileIcon from "@mui/icons-material/UploadFile";
import AvailabilityFileUploadDialog from "components/availability/dialog/AvailabilityFileUploadDialog";
export default function AvailabilityDocumentUpload({
  refetch,
}: {
  refetch: () => void;
}) {
  const hmsState = useSelector((state: TStore) => state.document);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={
          <Text variant="body1">last opp tilgjengelighet fil</Text>
        }
      >
        <IconButton
          type="info"
          onClick={() => {
            dispatch(setFileUploadDialogOpen());
          }}
        >
          <AddFileIcon />
        </IconButton>
      </Tooltip>
      <AvailabilityFileUploadDialog
        docType="availability"
        open={hmsState.fileUploadDialog}
        onClose={() => {
          dispatch(setFileUploadDialogClosed());
          refetch();
        }}
      />
    </React.Fragment>
  );
}
