import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import UploadButton from "@smartinspection/ameta-frontend-components/controls/UploadButton";
import Box from "@mui/material/Box";
import UploadFile from "@mui/icons-material/FileCopy";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      padding: 50,
    },
    dragAndDropText: {
      padding: 10,
    },
    dragAndDropContainer: {
      height: "100%",
      width: "100%",
      borderStyle: "dotted",
      //borderColor: theme.colors.icons.noState.light,
      display: "flex",
      borderRadius: "75px",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export default function DragAndDropIndicator({
  text,
  selectedFiles,
}: {
  text: string;
  selectedFiles: (Files: File[]) => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.dragAndDropContainer}>
        <UploadFile style={{ fontSize: 50 }} />
        <Text variant="body1">{text}</Text>
        <Box sx={{ paddingTop: 5 }}>
          <UploadButton onFilesSelected={selectedFiles} />
        </Box>
      </div>
    </div>
  );
}
