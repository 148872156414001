/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Traffic API
 * A rest api for managing the ameta traffic system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SensorData,
    SensorDataFromJSON,
    SensorDataFromJSONTyped,
    SensorDataToJSON,
    TrafficSensorReadingTotal,
    TrafficSensorReadingTotalFromJSON,
    TrafficSensorReadingTotalFromJSONTyped,
    TrafficSensorReadingTotalToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrafficSensorReading
 */
export interface TrafficSensorReading {
    /**
     * 
     * @type {string}
     * @memberof TrafficSensorReading
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficSensorReading
     */
    sort: string;
    /**
     * 
     * @type {Array<SensorData>}
     * @memberof TrafficSensorReading
     */
    data: Array<SensorData>;
    /**
     * 
     * @type {TrafficSensorReadingTotal}
     * @memberof TrafficSensorReading
     */
    total: TrafficSensorReadingTotal;
}

export function TrafficSensorReadingFromJSON(json: any): TrafficSensorReading {
    return TrafficSensorReadingFromJSONTyped(json, false);
}

export function TrafficSensorReadingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficSensorReading {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sort': json['sort'],
        'data': ((json['data'] as Array<any>).map(SensorDataFromJSON)),
        'total': TrafficSensorReadingTotalFromJSON(json['total']),
    };
}

export function TrafficSensorReadingToJSON(value?: TrafficSensorReading | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'data': ((value.data as Array<any>).map(SensorDataToJSON)),
        'total': TrafficSensorReadingTotalToJSON(value.total),
    };
}


