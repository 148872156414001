import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";

import AddOrEditEventDialog from "components/availability/dialog/AddOrEditEventDialog";

export default function AddEventDialog({ refetch }: { refetch: () => void }) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={<Text variant="body1">Legg til ny hendelse</Text>}
      >
        <IconButton
          type="info"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      <AddOrEditEventDialog open={open} setOpen={setOpen} refetch={refetch} />
    </React.Fragment>
  );
}
