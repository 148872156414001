import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import MultilineTextField from "@smartinspection/ameta-frontend-components/input/MultilineTextField";

import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import { authorizedYmApi } from "services/apiService";
import { LatLng } from "leaflet";
import LocationPicker from "@smartinspection/ameta-frontend-components/pickers/LocationPicker";
import { NewYmPoint, YmPoint } from "services/ymApi";

import measurementTypes from "components/ym/measurementTypes";

import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import geohash from "ngeohash";

export default function YmPointDialog({
  ymPoint,
  open,
  onClose,
  onSave,
}: {
  ymPoint?: YmPoint | undefined;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}) {
  const [formData, setFormData] = useState<NewYmPoint>({
    projectId: "",
    measurementPointId: "",
    waterSourceId: "",
    location: { latitude: "0", longitude: "0" },
    geohash: "",
  });
  useEffect(() => {
    if (ymPoint) {
      setFormData({
        measurementPointId: ymPoint.measurementPointId,
        projectId: ymPoint.projectId,
        waterSourceId: ymPoint.waterSourceId,
        location: {
          latitude: String(ymPoint.centerPoint.latitude),
          longitude: String(ymPoint.centerPoint.longitude),
        },
        thresholdValues: ymPoint.thresholdValues,
        place: ymPoint.place,
        comments: ymPoint.comments,
        geohash: ymPoint.geohash,
      });
    }
  }, [ymPoint, open]);
  const initialStatesThresholdValuesValidation = Object.fromEntries(
    Object.values(measurementTypes).map((value) => [value.label, true])
  );
  const [vaildations, setValidations] = useState({
    projectId: true,
    measurementPointId: true,
    waterSourceId: true,
    location: { longitude: true, latitude: true },
    initialStatesThresholdValuesValidation:
      initialStatesThresholdValuesValidation,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const validateForm = (form: any) => {
    const currentValidation = { ...vaildations };
    if (!form.location.longitude) {
      currentValidation.location.longitude = false;
      setErrorMessage("Posisjon i kart er ikke valgt");
    }
    if (!form.location.latitude) {
      currentValidation.location.latitude = false;
    }
    if (!form.projectId) {
      currentValidation.projectId = false;
      setErrorMessage(
        "Prosjekt Id, målpunkt Id, vannkild Id, og posisjon i kart må fylles ut"
      );
    }
    if (!form.measurementPointId) {
      currentValidation.measurementPointId = false;
      setErrorMessage(
        "Prosjekt Id, målpunkt Id, vannkild Id, og posisjon i kart må fylles ut"
      );
    }
    if (!form.waterSourceId) {
      currentValidation.waterSourceId = false;
      setErrorMessage(
        "Prosjekt Id, målpunkt Id, vannkild Id, og posisjon i kart må fylles ut"
      );
    }

    setValidations(currentValidation);
    return (
      currentValidation.measurementPointId &&
      currentValidation.projectId &&
      currentValidation.waterSourceId &&
      currentValidation.location.latitude &&
      currentValidation.location.longitude
    );
  };
  const [saved, setSaved] = useState(false);
  const revertToInitialStates = () => {
    setErrorMessage("");
    setSaved(false);
    setFormData({
      projectId: "",
      measurementPointId: "",
      location: { longitude: "0", latitude: "0" },
      waterSourceId: "",
      geohash: "",
    });
  };
  function onSaveClick() {
    let countFalse = 0;
    for (const value of Object.values(
      vaildations.initialStatesThresholdValuesValidation
    )) {
      if (value === false) {
        countFalse++;
      }
    }
    if (countFalse !== 0) {
      setErrorMessage("Ugyldige tegn er brukt. Gyldige formater:: 51.5, 51");
    } else if (validateForm(formData) && countFalse === 0) {
      authorizedYmApi().then((api) => {
        if (!ymPoint) {
          api
            .createYmPoint({
              newYmPoint: { ...formData },
            })
            .then(() => {
              revertToInitialStates();
              onSave();
              onClose();
            });
        } else {
          const updateData = {
            ...formData,
            data: ymPoint.data,
          };
          api
            .updateYmPoint({
              pointId: ymPoint.id,
              updateYmPoint: updateData,
            })
            .then(() => {
              revertToInitialStates();
              onSave();
              onClose();
            });
        }
      });
    }
    setSaved(false);
  }
  const validThreshold = (value: string, label: string) => {
    const re = /^[0-9\b]+(\.[0-9]+)?$/;
    let validated: boolean;
    if (value === "" || re.test(value)) {
      validated = true;
    } else {
      validated = false;
    }
    setValidations({
      ...vaildations,
      initialStatesThresholdValuesValidation: {
        ...vaildations.initialStatesThresholdValuesValidation,
        [label]: validated,
      },
    });
  };

  return (
    <Dialog
      type="drawer"
      paperSx={{ minWidth: "60%" }}
      title={!ymPoint ? "Legg til nytt målepunkt" : "Rediger"}
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            type="success"
            endIcon={!ymPoint ? <AddIcon /> : <SaveIcon />}
            onClick={() => {
              if (!saved) {
                setSaved(true);
                onSaveClick();
              }
            }}
          >
            {!ymPoint ? "Opprett punkt" : "Oppdater"}
          </Button>
        </Box>
      }
      onClose={() => {
        setValidations({
          projectId: true,
          measurementPointId: true,
          waterSourceId: true,
          location: { longitude: true, latitude: true },
          initialStatesThresholdValuesValidation:
            initialStatesThresholdValuesValidation,
        });
        revertToInitialStates();
        onClose();
      }}
      open={open}
    >
      {errorMessage !== "" ? (
        <Text sx={{ p: 2 }} variant="body1">
          {errorMessage}
        </Text>
      ) : (
        ""
      )}
      <Text sx={{ pl: 4 }} variant="h6">
        Målepunktdetaljer:
      </Text>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: 4,
        }}
      >
        <TextField
          width={280}
          label="Projekt Id"
          value={formData.projectId}
          onChange={(value) => {
            setFormData({
              ...formData,
              projectId: value.target.value,
            });
            setValidations({ ...vaildations, projectId: true });
            setSaved(false);
          }}
          error={!vaildations.projectId}
        />
        <TextField
          width={280}
          label="Målepunkt Id"
          value={formData.measurementPointId}
          onChange={(value) => {
            setFormData({
              ...formData,
              measurementPointId: value.target.value,
            });
            setValidations({
              ...vaildations,
              measurementPointId: true,
            });
            setSaved(false);
          }}
          error={!vaildations.measurementPointId}
        />
        <TextField
          width={280}
          label="Vannkilde Id"
          value={formData.waterSourceId}
          onChange={(value) => {
            setFormData({
              ...formData,
              waterSourceId: value.target.value,
            });
            setValidations({ ...vaildations, waterSourceId: true });
            setSaved(false);
          }}
          error={!vaildations.waterSourceId}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: 4,
        }}
      >
        <TextField
          width={280}
          label="Sted"
          value={formData.place}
          onChange={(value) => {
            if (value.target.value === "") {
              setFormData({ ...formData, place: undefined });
            } else {
              setFormData({
                ...formData,
                place: value.target.value,
              });
            }
            setSaved(false);
          }}
        />
      </Box>
      <Box sx={{ p: 4 }}>
        <MultilineTextField
          label="Kommentar"
          value={formData.comments}
          onChange={(value) => {
            if (value.target.value === "") {
              setFormData({ ...formData, comments: undefined });
            } else {
              setFormData({
                ...formData,
                comments: value.target.value,
              });
            }
          }}
        />
      </Box>
      <Box>
        <Text sx={{ pl: 4 }} variant="h6">
          Terskelverdier:
        </Text>
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            {measurementTypes.map((type, index) => {
              return (
                <TextField
                  value={
                    formData.thresholdValues
                      ? formData.thresholdValues[type.label]
                      : undefined
                  }
                  onChange={(event) => {
                    validThreshold(event.target.value, type.label);
                    if (event.target.value === "") {
                      const thresholdObject =
                        formData.thresholdValues &&
                        Object.fromEntries(
                          Object.entries(formData.thresholdValues).filter(
                            ([key]) => key !== type.label
                          )
                        );
                      if (
                        thresholdObject &&
                        Object.entries(thresholdObject).length !== 0
                      ) {
                        setFormData({
                          ...formData,
                          thresholdValues: thresholdObject,
                        });
                      } else {
                        setFormData({
                          ...formData,
                          thresholdValues: undefined,
                        });
                      }
                    } else {
                      setFormData({
                        ...formData,
                        thresholdValues: {
                          ...formData.thresholdValues,
                          [type.label]: event.target.value,
                        },
                      });
                      setErrorMessage("");
                    }
                    setSaved(false);
                  }}
                  key={index}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Text sx={{ mr: 1 }} variant="body1">
                        {type.label}
                      </Text>
                      <Text variant="subtitle1">{`(${type.unit})`}</Text>
                    </Box>
                  }
                />
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 4,
          }}
        >
          <Text variant="h6">Plassering i kart:</Text>
          <Text variant="h6">Klikk i view for å plassere lokasjon</Text>
        </Box>
        <Box sx={{ height: 400, width: "100%", px: 4, pb: 4 }}>
          <LocationPicker
            onChange={(newLocation) => {
              setFormData({
                ...formData,
                location: {
                  longitude: String(newLocation.lng),
                  latitude: String(newLocation.lat),
                },
                geohash: geohash.encode(
                  String(newLocation.lat),
                  String(newLocation.lng),
                  12
                ),
              });
              setValidations({
                ...vaildations,
                location: { longitude: true, latitude: true },
              });
              setErrorMessage("");
              setSaved(false);
            }}
            location={
              formData.location &&
              formData.location.latitude !== "0" &&
              formData.location.longitude !== "0"
                ? new LatLng(
                    Number(formData.location.latitude),
                    Number(formData.location.longitude)
                  )
                : undefined
            }
          />
        </Box>
      </Box>
    </Dialog>
  );
}
