import React, { useState } from "react";
import { format } from "date-fns";

import Box from "@mui/material/Box";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import BarChart from "@smartinspection/ameta-frontend-components/charts/BarChart";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";

import {
  SectionCounter,
  SectionCounterDataSections,
  SectionCounterDataSections2,
} from "services/availabilityApi/models";
import { yearsListDropdown } from "utils/yearsListDropDown";

import * as materialColors from "@mui/material/colors";

function EventOverview({
  sectionCounterData,
}: {
  sectionCounterData: SectionCounter;
}) {
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const [selectedMonth, setSelectedMonth] = useState(
    format(previousMonthDate, "M")
  );

  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, "yyyy")
  );

  const graphOptions = {
    deducted: "Stengeminutter med trekk",
    nonDeducted: "Stengeminutter uten trekk",
    total: "Stengeminutter totalt",
    summarized: "Stengeminutter oppsummert",
  };

  const [selectedGraph, setSelectedGraph] = useState("deducted");

  const sectionsDeducted =
    sectionCounterData.data?.deducted?.[selectedYear]?.months?.[selectedMonth]
      ?.sections;

  const sectionsNonDeducted =
    sectionCounterData.data?.nonDeducted?.[selectedYear]?.months?.[
      selectedMonth
    ]?.sections;

  const sectionsTotal =
    sectionCounterData.data?.totalTime?.[selectedYear]?.months?.[selectedMonth]
      ?.sections;

  const multiBar = (
    sections:
      | {
          [key: string]:
            | SectionCounterDataSections
            | SectionCounterDataSections2;
        }
      | undefined
  ) => {
    const barChartData: { [key: string]: string | number }[] = [
      { label: "Seksjon 1" },
      { label: "Seksjon 2" },
      { label: "Seksjon 3" },
      { label: "Seksjon 4" },
      { label: "Seksjon 5" },
      { label: "Seksjon 6" },
    ];
    sections &&
      Object.entries(sections).forEach((section) => {
        barChartData.forEach((dataElement, index) => {
          if (dataElement.label === "Seksjon " + section[0]) {
            barChartData.splice(index, 1);
          }
        });

        const sectionBarChartData = { label: "Seksjon " + section[0] };

        section[1].eventTimes &&
          Object.entries(section[1].eventTimes).forEach((event) => {
            sectionBarChartData[event[0]] = Number(event[1]);
          });

        barChartData.push(sectionBarChartData);
      });

    barChartData.sort((a, b) => {
      const aNum = Number(String(a.label).split("Seksjon ")[1]);
      const bNum = Number(String(b.label).split("Seksjon ")[1]);

      return aNum - bNum;
    });
    return barChartData;
  };

  const closingDataSummarized: {
    label: string;
    "Stenging med trekk"?: string;
    "Stenging uten trekk"?: string;
    "Totalt antall stengeminutter"?: string;
  }[] = [
    { label: "Seksjon 1" },
    { label: "Seksjon 2" },
    { label: "Seksjon 3" },
    { label: "Seksjon 4" },
    { label: "Seksjon 5" },
    { label: "Seksjon 6" },
  ];
  sectionsTotal &&
    Object.entries(sectionsTotal).forEach((element) => {
      closingDataSummarized.forEach((tableElement, tableIndex) => {
        if (tableElement.label === "Seksjon " + element[0]) {
          closingDataSummarized.splice(tableIndex, 1);
        }
      });

      closingDataSummarized.push({
        label: "Seksjon " + element[0],
        "Stenging med trekk": sectionsDeducted?.[element[0]].totalMinutes,
        "Stenging uten trekk": sectionsNonDeducted?.[element[0]].totalMinutes,
        "Totalt antall stengeminutter": element[1].totalMinutes,
      });
    });

  closingDataSummarized.sort((a, b) => {
    const aNum = Number(String(a.label).split("Seksjon ")[1]);
    const bNum = Number(String(b.label).split("Seksjon ")[1]);
    return aNum - bNum;
  });

  const barChartDataCollection = {
    deducted: multiBar(sectionsDeducted),
    nonDeducted: multiBar(sectionsNonDeducted),
    total: multiBar(sectionsTotal),
    summarized: closingDataSummarized,
  };

  const barChartDataProps = [
    {
      fieldName: "Tunnelvask",
      name: "Tunnelvask",
      color: materialColors.lightBlue[900],
    },
    {
      fieldName: "Øvrig drift",
      name: "Øvrig drift",
      color: materialColors.lightGreen[700],
    },
    {
      fieldName: "Asfaltering",
      name: "Asfaltering",
      color: materialColors.lightBlue[300],
    },
    {
      fieldName: "Øvrig tungt vedlikehold",
      name: "Øvrig tungt vedlikehold",
      color: materialColors.orange[500],
    },
    {
      fieldName: "Trafikkuhell/ skader",
      name: "Trafikkuhell/ skader",
      color: materialColors.orange[200],
    },
  ];

  const summarizedProps = [
    {
      fieldName: "Stenging med trekk",
      name: "Stenging med trekk",
      color: materialColors.lightBlue[900],
    },
    {
      fieldName: "Stenging uten trekk",
      name: "Stenging uten trekk",
      color: materialColors.lightGreen[700],
    },
    {
      fieldName: "Totalt antall stengeminutter",
      name: "Totalt antall stengeminutter",
      color: materialColors.lightBlue[300],
    },
  ];

  return (
    <Card
      title="Seksjonsoversikt"
      faIcon="road"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <OptionsPicker
            label="År"
            optionsList={yearsListDropdown()}
            value={selectedYear}
            onChange={setSelectedYear}
          />
          <OptionsPicker
            label="Periode"
            optionsList={{
              "1": "Januar",
              "2": "Februar",
              "3": "Mars",
              "4": "April",
              "5": "Mai",
              "6": "Juni",
              "7": "Juli",
              "8": "August",
              "9": "September",
              "10": "Oktober",
              "11": "November",
              "12": "Desember",
            }}
            value={selectedMonth}
            onChange={setSelectedMonth}
          />

          <OptionsPicker
            label="Graf"
            optionsList={graphOptions}
            value={selectedGraph}
            onChange={setSelectedGraph}
          />
        </Box>
      }
    >
      <BarChart
        margin={{ top: 0, bottom: 30, left: 0, right: 0 }}
        dataProps={
          selectedGraph === "summarized" ? summarizedProps : barChartDataProps
        }
        yAxisLabel="Minutter"
        data={barChartDataCollection[selectedGraph]}
      />
    </Card>
  );
}

export default EventOverview;
