import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  hashArray: string[];
  sideMenu: boolean;
  drawerOpen: boolean;
  createDialog: boolean;
  fileUploadDialog: boolean;
  selectedPointId: string;
  centerPoint: { lat: number; lng: number };
  zoomLevel: number;
} = {
  hashArray: [],
  sideMenu: false,
  drawerOpen: false,
  createDialog: false,
  fileUploadDialog: false,
  selectedPointId: "",
  centerPoint: { lat: 58.2450073183954, lng: 8.319148504443959 },
  zoomLevel: 12,
};

export const ymSlice = createSlice({
  name: "ym",
  initialState,
  reducers: {
    setHashArray: (state, action) => {
      state.hashArray = action.payload;
    },
    setSideMenuOpen: (state) => {
      state.sideMenu = true;
    },
    setSideMenuClosed: (state) => {
      state.sideMenu = false;
    },
    setDrawerOpen: (state) => {
      state.drawerOpen = true;
    },
    setDrawerClosed: (state) => {
      state.drawerOpen = false;
    },
    setCreateDialogOpen: (state) => {
      state.createDialog = true;
    },
    setCreateDialogClosed: (state) => {
      state.createDialog = false;
    },
    setSelectedPointId: (state, action) => {
      state.selectedPointId = action.payload;
    },
    setCenterPoint: (state, action) => {
      state.centerPoint = action.payload;
    },
    setZoomLevel: (state, action) => {
      state.zoomLevel = action.payload;
    },
    setFileUploadDialogOpen: (state) => {
      state.fileUploadDialog = true;
    },
    setFileUploadDialogClosed: (state) => {
      state.fileUploadDialog = false;
    },
  },
});

export const {
  setHashArray,
  setSideMenuOpen,
  setSideMenuClosed,
  setDrawerOpen,
  setDrawerClosed,
  setCreateDialogOpen,
  setCreateDialogClosed,
  setSelectedPointId,
  setCenterPoint,
  setZoomLevel,
  setFileUploadDialogOpen,
  setFileUploadDialogClosed,
} = ymSlice.actions;

export default ymSlice.reducer;
