/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HMSCounterDataNh,
    HMSCounterDataNhFromJSON,
    HMSCounterDataNhFromJSONTyped,
    HMSCounterDataNhToJSON,
    HMSCounterDataRuh,
    HMSCounterDataRuhFromJSON,
    HMSCounterDataRuhFromJSONTyped,
    HMSCounterDataRuhToJSON,
    HMSCounterDataSja,
    HMSCounterDataSjaFromJSON,
    HMSCounterDataSjaFromJSONTyped,
    HMSCounterDataSjaToJSON,
} from './';

/**
 * 
 * @export
 * @interface HMSCounterData
 */
export interface HMSCounterData {
    /**
     * 
     * @type {HMSCounterDataNh}
     * @memberof HMSCounterData
     */
    nh?: HMSCounterDataNh;
    /**
     * 
     * @type {HMSCounterDataRuh}
     * @memberof HMSCounterData
     */
    ruh?: HMSCounterDataRuh;
    /**
     * 
     * @type {HMSCounterDataSja}
     * @memberof HMSCounterData
     */
    sja?: HMSCounterDataSja;
}

export function HMSCounterDataFromJSON(json: any): HMSCounterData {
    return HMSCounterDataFromJSONTyped(json, false);
}

export function HMSCounterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HMSCounterData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nh': !exists(json, 'nh') ? undefined : HMSCounterDataNhFromJSON(json['nh']),
        'ruh': !exists(json, 'ruh') ? undefined : HMSCounterDataRuhFromJSON(json['ruh']),
        'sja': !exists(json, 'sja') ? undefined : HMSCounterDataSjaFromJSON(json['sja']),
    };
}

export function HMSCounterDataToJSON(value?: HMSCounterData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nh': HMSCounterDataNhToJSON(value.nh),
        'ruh': HMSCounterDataRuhToJSON(value.ruh),
        'sja': HMSCounterDataSjaToJSON(value.sja),
    };
}


