import React, { useState } from "react";

import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";

import { format } from "date-fns";

import Text from "@smartinspection/ameta-frontend-components/display/Text";

import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";
import EditYmMeasurementDialog from "components/ym/dialogs/EditYmMeasurementDialog";

import { YmPoint } from "services/ymApi";
import { authorizedYmApi } from "services/apiService";
import YmHistoricalAllTable from "./YmHistoricalAllTable";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";

export default function YmHistoricalListDialog({
  ymPoint,
  refetch,
  open,
  onClose,
}: {
  refetch: () => void;
  ymPoint: YmPoint;
  open: boolean;
  onClose: () => void;
}) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState(undefined);

  // str.substring(1), removes the first character of str

  const measurementHistoryDateConverter = (
    shortYear: string,
    shortMonth: string,
    shortDay: string
  ) => {
    const year = "20" + shortYear.substring(1);
    const monthZeroIndex = shortMonth.substring(1);
    const day = shortDay.substring(1);

    return new Date(+year, +monthZeroIndex - 1, +day);
  };

  interface historicalData {
    [key: string]: yearData;
  }
  interface yearData {
    [key: string]: monthData;
  }

  interface monthData {
    [key: string]: dayData;
  }
  interface dayData {
    [key: string]: measurement;
  }

  interface measurement {
    date?: string;
    measurementIndication: string;
    unit: string;
    value: string;
    referenceId: string;
  }

  const ymPointMeasurements: (historicalDataRecord: historicalData) => {
    reportData: object[];
    ymMeasurementHeaders: { id: string; label: string }[];
  } = (historicalDataRecord: historicalData) => {
    const reportData: object[] = [];
    const ymMeasurementHeaders: { id: string; label: string }[] = [];
    Object.entries(historicalDataRecord).forEach(([year, yearData]) => {
      Object.entries(yearData).forEach(([month, monthData]) => {
        Object.entries(monthData).forEach(([day, dayData]) => {
          const dayDate = measurementHistoryDateConverter(year, month, day);
          var valueObject = {};
          var refId: string = "";
          Object.entries(dayData).forEach(([type, typeData]) => {
            valueObject = {
              ...valueObject,
              [type]: typeData.value,
            };
            refId = typeData.referenceId;
            const headerObject = { id: type, label: type };

            const exists = !ymMeasurementHeaders.some(
              (header) => header.id === headerObject.id
            );

            if (exists) {
              ymMeasurementHeaders.push(headerObject);
            }
          });

          const obj = {
            ...valueObject,
            dateString: format(dayDate, "dd.MM.yyyy"),
            dayData,
            date: dayDate,
            refId,
          };

          dayData && reportData.push(obj);
        });
      });
    });

    return { reportData, ymMeasurementHeaders };
  };

  const reportData =
    ymPoint.data?.historicalDataRecord &&
    ymPointMeasurements(ymPoint.data?.historicalDataRecord as historicalData)
      .reportData;

  const measurementHeaders =
    ymPoint.data?.historicalDataRecord &&
    ymPointMeasurements(ymPoint.data?.historicalDataRecord as historicalData)
      .ymMeasurementHeaders;

  let dateHeaderArray: { id: string; label: string }[] = [];
  if (measurementHeaders) {
    dateHeaderArray = [{ id: "dateString", label: "Dato" }].concat(
      measurementHeaders
    );
  }

  const temp = selectedMeasurement as
    | {
        dateString: string;
        value: string;
        dayData: dayData;
        date: Date;
        refId: string;
      }
    | undefined;
  const selectedrefId: string | undefined = temp?.refId;

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} title="Alle målinger">
        {reportData && reportData.length > 0 && measurementHeaders ? (
          <YmHistoricalAllTable
            headers={dateHeaderArray}
            data={
              reportData as {
                [key: string]: string | number;
              }[]
            }
            onDeleteClick={(data) => {
              setDeleteDialogOpen(true);
              setSelectedMeasurement(data);
            }}
            onEditClick={(data) => {
              setEditDialogOpen(true);
              setSelectedMeasurement(data);
            }}
          />
        ) : (
          <CenterContainer>
            <Text variant="h5">Ingen data denne perioden</Text>
          </CenterContainer>
        )}
      </Dialog>
      {deleteDialogOpen && (
        <ConfirmDialog
          open={deleteDialogOpen}
          onCancel={() => {
            setDeleteDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          onAccept={() => {
            if (selectedMeasurement && selectedrefId && selectedrefId !== "") {
              authorizedYmApi().then((api) => {
                api
                  .deleteYmMeasurement({
                    referenceId: selectedrefId,
                    deleteYmMeasurement: {
                      data: { _delete: "all" },
                    },
                  })
                  .then(refetch);
              });
            }
          }}
          text="Er du sikker på at du vil slette alle verdier for denne dagen?"
          acceptText="Ja, slett verdiene"
          cancelText="Nei, avbryt"
        />
      )}
      {ymPoint && editDialogOpen && selectedrefId && selectedrefId !== "" && (
        <EditYmMeasurementDialog
          isOpened={editDialogOpen}
          ymPointId={ymPoint.id}
          ymPointMeasurementId={ymPoint.measurementPointId}
          selectedMeasurement={selectedMeasurement}
          editAll
          onClose={() => {
            setEditDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          referenceId={selectedrefId}
        />
      )}
    </React.Fragment>
  );
}
