/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SJAReport,
    SJAReportFromJSON,
    SJAReportToJSON,
} from '../models';

export interface CreateSjaReportRequest {
    sJAReport: SJAReport;
}

/**
 * 
 */
export class SjaReportApi extends runtime.BaseAPI {

    /**
     * create a new sja report
     */
    async createSjaReportRaw(requestParameters: CreateSjaReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sJAReport === null || requestParameters.sJAReport === undefined) {
            throw new runtime.RequiredError('sJAReport','Required parameter requestParameters.sJAReport was null or undefined when calling createSjaReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/sja-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SJAReportToJSON(requestParameters.sJAReport),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create a new sja report
     */
    async createSjaReport(requestParameters: CreateSjaReportRequest): Promise<void> {
        await this.createSjaReportRaw(requestParameters);
    }

}
