import Cookies from 'universal-cookie';
const cookies = new Cookies();

const localHostCookieConfig = {
  path: '/',
  domain: 'localhost',
};
const deploymentCookieConfig = {
  path: '/',
  domain: '.ameta.app',
  sameSite: 'none',
  secure: true,
};

export function setCookie(name: string, value:any) {
  cookies.set(
    name,
    value,
    process.env.NODE_ENV === 'development'
      ? localHostCookieConfig
      : deploymentCookieConfig
  );
}
