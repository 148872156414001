import React, { useState } from "react";

import { format } from "date-fns";

import { SecurityCounter, SecurityYearObject } from "services/securityApi";

import Box from "@mui/material/Box";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import BarChart from "@smartinspection/ameta-frontend-components/charts/BarChart";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";

import * as materialColors from "@mui/material/colors";
import { yearsListDropdown } from "utils/yearsListDropDown";

interface GraphData {
  label: string;
  "Lettere skadet"?: number;
  "Alvorlig skadet"?: number;
  "Meget alvorlig skadet"?: number;
  "Skadet vegobjekt"?: number;
  "Andre ulykker uten personskade"?: number;
  Drepte?: number;
}

const labelMonthConverter = {
  m1: "Jan",
  m2: "Feb",
  m3: "Mar",
  m4: "Apr",
  m5: "Mai",
  m6: "Jun",
  m7: "Jul",
  m8: "Aug",
  m9: "Sep",
  m10: "Okt",
  m11: "Nov",
  m12: "Des",
};

function SecurityBarChart({
  securityCounterData,
}: {
  securityCounterData: SecurityCounter;
}) {
  const [selectedYear, setSelectedYear] = useState(format(Date.now(), "yyyy"));

  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const secuityNumbersYearMonthMapper: (
    year: SecurityYearObject | undefined
  ) => GraphData[] = (year: SecurityYearObject | undefined) => {
    const securityNumbers: GraphData[] = [];
    Object.entries(labelMonthConverter).forEach(([key, value]) => {
      if (year?.months[key]) {
        let tempMonthObject: any = {};

        Object.entries(year.months[key].days).forEach(([_, days]) => {
          days.forEach((day) => {
            const type = day.accidentType;
            tempMonthObject = {
              ...tempMonthObject,
              [type]: tempMonthObject?.[type]
                ? tempMonthObject[type] + day.involvedCount
                : day.involvedCount,
            };
          });
        });

        // DR = Drepte, MAS = Meget alvorlig skadet, AS = Alvorlige skadet, LS = Lettere skadet, PR = Skadet vegobjekt, AU = Andre ulykker uten personskade
        securityNumbers.push({
          label: value,
          ...(tempMonthObject["LS"] && {
            "Lettere skadet": tempMonthObject["LS"],
          }),
          ...(tempMonthObject["AS"] && {
            "Alvorlig skadet": tempMonthObject["AS"],
          }),
          ...(tempMonthObject["MAS"] && {
            "Meget alvorlig skadet": tempMonthObject["MAS"],
          }),
          ...(tempMonthObject["DR"] && {
            Drepte: tempMonthObject["DR"],
          }),
          ...(tempMonthObject["PR"] && {
            "Skadet vegobjekt": tempMonthObject["PR"],
          }),
          ...(tempMonthObject["AU"] && {
            "Andre ulykker uten personskade": tempMonthObject["AU"],
          }),
        });
      } else {
        securityNumbers.push({
          label: value,
        });
      }
    });
    return securityNumbers;
  };

  const reportYear =
    securityCounterData?.data?.years?.[yearStringConverter(selectedYear)];
  return (
    <Card
      title={"Så langt i " + selectedYear}
      faIcon="chart-bar"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            pr: 2,
          }}
        >
          <OptionsPicker
            label="År"
            optionsList={yearsListDropdown()}
            value={selectedYear}
            onChange={setSelectedYear}
          />
        </Box>
      }
    >
      <BarChart
        margin={{ top: 0, bottom: 20, left: 0, right: 0 }}
        dataProps={[
          {
            fieldName: "Lettere skadet",
            name: "Lettere skadet",
            color: materialColors.lime[500],
          },
          {
            fieldName: "Alvorlig skadet",
            name: "Alvorlig skadet",
            color: materialColors.yellow[700],
          },
          {
            fieldName: "Meget alvorlig skadet",
            name: "Meget alvorlig skadet",
            color: materialColors.deepOrange[400],
          },
          {
            fieldName: "Drepte",
            name: "Drepte",
            color: materialColors.red[900],
          },
          {
            fieldName: "Skadet vegobjekt",
            name: "Skadet vegobjekt",
            color: materialColors.blueGrey[700],
          },
          {
            fieldName: "Andre ulykker uten personskade",
            name: "Andre ulykker uten personskade",
            color: materialColors.green[500],
          },
        ]}
        data={
          secuityNumbersYearMonthMapper(reportYear) as unknown as {
            [key: string]: string | number;
          }[]
        }
      />
    </Card>
  );
}

export default SecurityBarChart;
