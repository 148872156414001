/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SJAReport
 */
export interface SJAReport {
    /**
     * 
     * @type {string}
     * @memberof SJAReport
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof SJAReport
     */
    sjaNr: number;
    /**
     * 
     * @type {string}
     * @memberof SJAReport
     */
    sjaType: string;
    /**
     * 
     * @type {string}
     * @memberof SJAReport
     */
    company: string;
}

export function SJAReportFromJSON(json: any): SJAReport {
    return SJAReportFromJSONTyped(json, false);
}

export function SJAReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): SJAReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'sjaNr': json['sjaNr'],
        'sjaType': json['sjaType'],
        'company': json['company'],
    };
}

export function SJAReportToJSON(value?: SJAReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'sjaNr': value.sjaNr,
        'sjaType': value.sjaType,
        'company': value.company,
    };
}


