/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Security API
 * A rest api for managing the ameta security system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SecurityCounter,
    SecurityCounterFromJSON,
    SecurityCounterToJSON,
} from '../models';

/**
 * 
 */
export class SecurityCounterApi extends runtime.BaseAPI {

    /**
     * get security counter
     */
    async getSecurityCounterRaw(): Promise<runtime.ApiResponse<SecurityCounter>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/security-counter/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SecurityCounterFromJSON(jsonValue));
    }

    /**
     * get security counter
     */
    async getSecurityCounter(): Promise<SecurityCounter> {
        const response = await this.getSecurityCounterRaw();
        return await response.value();
    }

}
