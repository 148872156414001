/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteYmMeasurementData
 */
export interface DeleteYmMeasurementData {
    /**
     * 
     * @type {string}
     * @memberof DeleteYmMeasurementData
     */
    _delete: string;
}

export function DeleteYmMeasurementDataFromJSON(json: any): DeleteYmMeasurementData {
    return DeleteYmMeasurementDataFromJSONTyped(json, false);
}

export function DeleteYmMeasurementDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteYmMeasurementData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_delete': json['delete'],
    };
}

export function DeleteYmMeasurementDataToJSON(value?: DeleteYmMeasurementData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete': value._delete,
    };
}


