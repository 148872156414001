import React from "react";

import YmMarkers from "./YmMarkers";
import { YmPoint } from "services/ymApi/models";

import MarkerCluster from "@smartinspection/ameta-frontend-components/map/MarkerCluster";

export default function YmMarkerCluster({
  data,
  clusterColor,
  onClick,
}: {
  data: YmPoint[];
  clusterColor: React.CSSProperties["color"];
  onClick?: (point: YmPoint) => void;
}) {
  if (!data) {
    return null;
  }

  return (
    <MarkerCluster clusterColor={clusterColor}>
      <YmMarkers ymData={data} onClick={onClick} />
    </MarkerCluster>
  );
}
