import React, { useState } from "react";
import { format } from "date-fns";

import { SectionCounter } from "services/availabilityApi/models";

import Box from "@mui/material/Box";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Table from "@smartinspection/ameta-frontend-components/display/Table";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";

import { yearsListDropdown } from "utils/yearsListDropDown";

function SectionOverview({
  sectionCounterData,
}: {
  sectionCounterData: SectionCounter | undefined;
}) {
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const [selectedMonth, setSelectedMonth] = useState(
    format(previousMonthDate, "M")
  );

  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, "yyyy")
  );

  const sectionsWithDeduction =
    sectionCounterData?.data?.deducted?.[selectedYear]?.months?.[selectedMonth]
      ?.sections;

  const sectionsWithoutDeduction =
    sectionCounterData?.data?.nonDeducted?.[selectedYear]?.months?.[
      selectedMonth
    ]?.sections;
  const sectionsTotal =
    sectionCounterData?.data?.totalTime?.[selectedYear]?.months?.[selectedMonth]
      ?.sections;

  const tableData: {
    sectionNr: string;
    icon?: React.ReactNode;
    totalMinutesWithDeduction?: string;
    totalMinutesWithoutDeduction?: string;
    totalMinutes?: string;
  }[] = [
    {
      sectionNr: "1",
    },
    { sectionNr: "2" },
    { sectionNr: "3" },
    { sectionNr: "4" },
    { sectionNr: "5" },
    { sectionNr: "6" },
  ];
  sectionsTotal &&
    Object.entries(sectionsTotal).forEach((element) => {
      tableData.forEach((tableElement, tableIndex) => {
        if (tableElement.sectionNr === element[0]) {
          tableData.splice(tableIndex, 1);
        }
      });

      tableData.push({
        sectionNr: element[0],
        totalMinutesWithDeduction:
          sectionsWithDeduction?.[element[0]].totalMinutes,
        totalMinutesWithoutDeduction:
          sectionsWithoutDeduction?.[element[0]].totalMinutes,
        totalMinutes: element[1].totalMinutes,
      });
    });

  tableData.sort((a, b) => {
    return Number(a.sectionNr) - Number(b.sectionNr);
  });

  return (
    <Card
      title="Stenging"
      faIcon="road"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <OptionsPicker
            label="År"
            optionsList={yearsListDropdown()}
            value={selectedYear}
            onChange={(value) => {
              setSelectedYear(value);
            }}
          />
          <OptionsPicker
            label="Periode"
            optionsList={{
              "1": "Januar",
              "2": "Februar",
              "3": "Mars",
              "4": "April",
              "5": "Mai",
              "6": "Juni",
              "7": "Juli",
              "8": "August",
              "9": "September",
              "10": "Oktober",
              "11": "November",
              "12": "Desember",
            }}
            value={selectedMonth}
            onChange={(value) => {
              setSelectedMonth(value);
            }}
          />
        </Box>
      }
    >
      <Table
        compact
        headers={[
          { fieldName: "sectionNr", name: "Seksjon" },
          {
            fieldName: "totalMinutesWithDeduction",
            name: "Stengeminutter med trekk",
          },
          {
            fieldName: "totalMinutesWithoutDeduction",
            name: "Stengeminutter uten trekk",
          },
          { fieldName: "totalMinutes", name: "Totalt antall stengeminutter" },
        ]}
        data={tableData}
      />
    </Card>
  );
}

export default SectionOverview;
