/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HMSCounterData,
    HMSCounterDataFromJSON,
    HMSCounterDataFromJSONTyped,
    HMSCounterDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface HMSCounter
 */
export interface HMSCounter {
    /**
     * 
     * @type {HMSCounterData}
     * @memberof HMSCounter
     */
    data: HMSCounterData;
}

export function HMSCounterFromJSON(json: any): HMSCounter {
    return HMSCounterFromJSONTyped(json, false);
}

export function HMSCounterFromJSONTyped(json: any, ignoreDiscriminator: boolean): HMSCounter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': HMSCounterDataFromJSON(json['data']),
    };
}

export function HMSCounterToJSON(value?: HMSCounter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': HMSCounterDataToJSON(value.data),
    };
}


