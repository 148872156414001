/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Traffic API
 * A rest api for managing the ameta traffic system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TrafficSensorCounterTotal,
    TrafficSensorCounterTotalFromJSON,
    TrafficSensorCounterTotalFromJSONTyped,
    TrafficSensorCounterTotalToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrafficSensorCounter
 */
export interface TrafficSensorCounter {
    /**
     * 
     * @type {string}
     * @memberof TrafficSensorCounter
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficSensorCounter
     */
    sort?: string;
    /**
     * 
     * @type {TrafficSensorCounterTotal}
     * @memberof TrafficSensorCounter
     */
    total?: TrafficSensorCounterTotal;
    /**
     * 
     * @type {object}
     * @memberof TrafficSensorCounter
     */
    counter?: object;
}

export function TrafficSensorCounterFromJSON(json: any): TrafficSensorCounter {
    return TrafficSensorCounterFromJSONTyped(json, false);
}

export function TrafficSensorCounterFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficSensorCounter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'total': !exists(json, 'total') ? undefined : TrafficSensorCounterTotalFromJSON(json['total']),
        'counter': !exists(json, 'counter') ? undefined : json['counter'],
    };
}

export function TrafficSensorCounterToJSON(value?: TrafficSensorCounter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'total': TrafficSensorCounterTotalToJSON(value.total),
        'counter': value.counter,
    };
}


