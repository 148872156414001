/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NHReport,
    NHReportFromJSON,
    NHReportToJSON,
} from '../models';

export interface CreateNhReportRequest {
    nHReport: NHReport;
}

/**
 * 
 */
export class NhReportApi extends runtime.BaseAPI {

    /**
     * create a new nh report
     */
    async createNhReportRaw(requestParameters: CreateNhReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.nHReport === null || requestParameters.nHReport === undefined) {
            throw new runtime.RequiredError('nHReport','Required parameter requestParameters.nHReport was null or undefined when calling createNhReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/nh-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NHReportToJSON(requestParameters.nHReport),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create a new nh report
     */
    async createNhReport(requestParameters: CreateNhReportRequest): Promise<void> {
        await this.createNhReportRaw(requestParameters);
    }

}
