import {
  authorizedYmApi,
  authorizedHmsDocumentApi,
  authorizedAvailabilityDocumentApi,
} from "services/apiService";
import axios, { CancelTokenSource } from "axios";
export async function uploadFile(
  fileObject: {
    fileContent: File;
    cancelToken: CancelTokenSource;
    docType: string;
  },
  onUploadProgressCallback: (
    progressEvent: any // eslint-disable-line @typescript-eslint/no-explicit-any
    // disabled linting for progressEvent since the parameter is typed as "any"
    // in the axios library aswell.
  ) => void,
  onSuccess: (documentId: string) => void,
  onError?: (errorMessage: string) => void
) {
  let documentId: string;
  if (fileObject.docType === "ym") {
    authorizedYmApi().then((api) =>
      api
        .createYmDocumentURL({
          newDocument: {
            documentName: fileObject.fileContent.name,
          },
        })
        .then((x) => {
          documentId = x.documentId;
          const data = x["signedUrl"];
          const url = data["url"];
          const fields = data["fields"];
          const form = new FormData();
          form.append("AWSAccessKeyId", fields.AWSAccessKeyId);
          form.append("key", fields.key);
          form.append("x-amz-security-token", fields["x-amz-security-token"]);
          form.append("policy", fields.policy);
          form.append("signature", fields.signature);
          form.append("Content-Disposition", fields["Content-Disposition"]);
          form.append("x-amz-meta-doctype", fields["x-amz-meta-doctype"]);
          form.append("x-amz-meta-name", fields["x-amz-meta-name"]);
          form.append("file", fileObject.fileContent);
          axios
            .post(url, form, {
              cancelToken: fileObject.cancelToken.token,
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
                onUploadProgressCallback(progress);
              },
            })
            .then(() => {
              onSuccess(documentId);
            })
            .catch((error) => {
              onError &&
                onError(
                  error ? (error as string) : "ukjent feil ved opplasting"
                );
            });
        })
    );
  } else if (
    fileObject.docType === "sja" ||
    fileObject.docType === "nh" ||
    fileObject.docType === "ruh"
  ) {
    authorizedHmsDocumentApi().then((api) =>
      api
        .createHmsDocumentURL({
          newDocument: {
            docType: fileObject.docType,
            documentName: fileObject.fileContent.name,
          },
        })
        .then((x) => {
          documentId = x.documentId;
          const data = x["signedUrl"];
          const url = data["url"];
          const fields = data["fields"];
          const form = new FormData();
          form.append("AWSAccessKeyId", fields.AWSAccessKeyId);
          form.append("key", fields.key);
          form.append("x-amz-security-token", fields["x-amz-security-token"]);
          form.append("policy", fields.policy);
          form.append("signature", fields.signature);
          form.append("Content-Disposition", fields["Content-Disposition"]);
          form.append("x-amz-meta-doctype", fields["x-amz-meta-doctype"]);
          form.append("x-amz-meta-name", fields["x-amz-meta-name"]);
          form.append("file", fileObject.fileContent);
          axios
            .post(url, form, {
              cancelToken: fileObject.cancelToken.token,
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
                onUploadProgressCallback(progress);
              },
            })
            .then(() => {
              onSuccess(documentId);
            })
            .catch((error) => {
              onError &&
                onError(
                  error ? (error as string) : "ukjent feil ved opplasting"
                );
            });
        })
    );
  } else if (fileObject.docType === "availability") {
    authorizedAvailabilityDocumentApi().then((api) =>
      api
        .createAvailabilityDocumentURL({
          newDocument: {
            documentName: fileObject.fileContent.name,
          },
        })
        .then((x) => {
          documentId = x.documentId;
          const data = x["signedUrl"];
          const url = data["url"];
          const fields = data["fields"];
          const form = new FormData();
          form.append("AWSAccessKeyId", fields.AWSAccessKeyId);
          form.append("key", fields.key);
          form.append("x-amz-security-token", fields["x-amz-security-token"]);
          form.append("policy", fields.policy);
          form.append("signature", fields.signature);
          form.append("Content-Disposition", fields["Content-Disposition"]);
          form.append("x-amz-meta-doctype", fields["x-amz-meta-doctype"]);
          form.append("x-amz-meta-name", fields["x-amz-meta-name"]);
          form.append("file", fileObject.fileContent);
          axios
            .post(url, form, {
              cancelToken: fileObject.cancelToken.token,
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
                onUploadProgressCallback(progress);
              },
            })
            .then(() => {
              onSuccess(documentId);
            })
            .catch((error) => {
              onError &&
                onError(
                  error ? (error as string) : "ukjent feil ved opplasting"
                );
            });
        })
    );
  }
}
