/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface YmPointCenterPoint
 */
export interface YmPointCenterPoint {
    /**
     * 
     * @type {number}
     * @memberof YmPointCenterPoint
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof YmPointCenterPoint
     */
    latitude: number;
}

export function YmPointCenterPointFromJSON(json: any): YmPointCenterPoint {
    return YmPointCenterPointFromJSONTyped(json, false);
}

export function YmPointCenterPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): YmPointCenterPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'longitude': json['longitude'],
        'latitude': json['latitude'],
    };
}

export function YmPointCenterPointToJSON(value?: YmPointCenterPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'longitude': value.longitude,
        'latitude': value.latitude,
    };
}


