/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RUHReport,
    RUHReportFromJSON,
    RUHReportToJSON,
} from '../models';

export interface CreateRuhReportRequest {
    rUHReport: RUHReport;
}

/**
 * 
 */
export class RuhReportApi extends runtime.BaseAPI {

    /**
     * create a new ruh report
     */
    async createRuhReportRaw(requestParameters: CreateRuhReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.rUHReport === null || requestParameters.rUHReport === undefined) {
            throw new runtime.RequiredError('rUHReport','Required parameter requestParameters.rUHReport was null or undefined when calling createRuhReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/ruh-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RUHReportToJSON(requestParameters.rUHReport),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create a new ruh report
     */
    async createRuhReport(requestParameters: CreateRuhReportRequest): Promise<void> {
        await this.createRuhReportRaw(requestParameters);
    }

}
