/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Security API
 * A rest api for managing the ameta security system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SecurityEvent,
    SecurityEventFromJSON,
    SecurityEventFromJSONTyped,
    SecurityEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface SecurityMonthObject
 */
export interface SecurityMonthObject {
    /**
     * 
     * @type {number}
     * @memberof SecurityMonthObject
     */
    accidentsWithLightPersonalInjuries: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityMonthObject
     */
    accidentsWithSignificantPersonalInjuries: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityMonthObject
     */
    accidentsWithSeriousPersonalInjuries: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityMonthObject
     */
    fatalAccidents: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityMonthObject
     */
    hitRailings: number;
    /**
     * 
     * @type {number}
     * @memberof SecurityMonthObject
     */
    otherAccidents: number;
    /**
     * 
     * @type {{ [key: string]: Array<SecurityEvent>; }}
     * @memberof SecurityMonthObject
     */
    days: { [key: string]: Array<SecurityEvent>; };
}

export function SecurityMonthObjectFromJSON(json: any): SecurityMonthObject {
    return SecurityMonthObjectFromJSONTyped(json, false);
}

export function SecurityMonthObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityMonthObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accidentsWithLightPersonalInjuries': json['accidents_with_light_personal_injuries'],
        'accidentsWithSignificantPersonalInjuries': json['accidents_with_significant_personal_injuries'],
        'accidentsWithSeriousPersonalInjuries': json['accidents_with_serious_personal_injuries'],
        'fatalAccidents': json['fatal_accidents'],
        'hitRailings': json['hit_railings'],
        'otherAccidents': json['other_accidents'],
        'days': json['days'],
    };
}

export function SecurityMonthObjectToJSON(value?: SecurityMonthObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accidents_with_light_personal_injuries': value.accidentsWithLightPersonalInjuries,
        'accidents_with_significant_personal_injuries': value.accidentsWithSignificantPersonalInjuries,
        'accidents_with_serious_personal_injuries': value.accidentsWithSeriousPersonalInjuries,
        'fatal_accidents': value.fatalAccidents,
        'hit_railings': value.hitRailings,
        'other_accidents': value.otherAccidents,
        'days': value.days,
    };
}


