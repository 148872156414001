import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { toggleColorMode, toggleEditLayout } from "redux/global";
import { TStore } from "redux/store";

import Box from "@mui/material/Box";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

import TopView from "components/TopView";

export default function Settings() {
  const globalState = useSelector((state: TStore) => state.global);

  const dispatch = useDispatch();

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <TopView title="Innstillinger" />
      <Box>
        <Box sx={{ p: 5, display: "flex", alignItems: "center" }}>
          <Text sx={{ mr: 2 }}>Fargemodus: </Text>
          <IconButton
            type="info"
            onClick={() => {
              dispatch(toggleColorMode());
            }}
          >
            {globalState.colorMode === "dark" ? (
              <Brightness4Icon />
            ) : (
              <Brightness7Icon />
            )}
          </IconButton>
        </Box>
        <Box sx={{ p: 5, display: "flex", alignItems: "center" }}>
          <Text sx={{ mr: 2 }}>Endre layout: </Text>
          <Button
            type="info"
            onClick={() => {
              dispatch(toggleEditLayout());
            }}
          >
            {globalState.layoutEditable ? "På" : "Av"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
