import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Table from "@smartinspection/ameta-frontend-components/display/Table";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

export default function YmHistoricalAllTable({
  headers,
  data,
  onDeleteClick,
  onEditClick,
}: {
  headers: { id: string; label: string; thresholdNumber?: number }[];
  data?: { [key: string]: string | number }[];
  onDeleteClick?: (rowElement: any) => void;
  onEditClick?: (rowElement: any) => void;
}) {
  const tableData =
    data &&
    data.map((el) => {
      return {
        ...el,
        edit: (
          <IconButton
            noShadow
            type="info"
            onClick={() => {
              onEditClick && onEditClick(el);
            }}
          >
            <EditIcon />
          </IconButton>
        ),
        delete: (
          <IconButton
            noShadow
            type="error"
            onClick={() => {
              onDeleteClick && onDeleteClick(el);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
      };
    });

  return (
    <Table
      headers={[
        ...headers.map((header) => {
          return { fieldName: header.id, name: header.label };
        }),
        { fieldName: "edit", name: "Rediger", align: "center" },
        { fieldName: "delete", name: "Slett", align: "center" },
      ]}
      data={tableData}
    />
  );
}
