import { SecurityCounter } from 'services/securityApi/models';
import { useQuery } from 'react-query';
import { authorizedSecurityCounterApi } from 'services/apiService';

export const useQuerySecurityCounter = (): {
  data: SecurityCounter | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ['securityCounter'],
    async () => {
      const api = await authorizedSecurityCounterApi();
      return api.getSecurityCounter();
    }
  );

  return { data, refetch, isLoading };
};
