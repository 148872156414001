import React, { useState } from "react";

import { format } from "date-fns";

import Box from "@mui/material/Box";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";

import Table from "@smartinspection/ameta-frontend-components/display/Table";

import { useReportHook } from "services/availabilityQueryHooks";
import { yearsListDropdown } from "utils/yearsListDropDown";

import { authorizedAvailabilityApi } from "services/apiService";
import { ReportObject } from "services/availabilityApi";
import AddOrEditEventDialog from "./AddOrEditEventDialog";

export default function ReportDialog({ onClose }: { onClose: () => void }) {
  const [open, setOpen] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(format(new Date(), "M"));
  const [selectedYear, setSelectedYear] = useState(format(new Date(), "yyyy"));
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [deleteEventId, setDeleteEventId] = useState("");
  const [editEvent, setEditEvent] = useState<ReportObject>();
  const reportQuery = useReportHook(selectedYear, selectedMonth);
  const reportData = reportQuery.data;

  const tableData: { [key: string]: React.ReactNode }[] = [];
  reportData !== undefined &&
    reportData.forEach((element) => {
      tableData.push({
        ...element,
        delete: (
          <IconButton
            noShadow
            type="error"
            onClick={() => {
              setDeleteDialogOpen(true);
              setDeleteEventId(element.sort);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
        edit: (
          <IconButton
            noShadow
            type="info"
            onClick={() => {
              setEditDialogOpen(true);
              setEditEvent(element);
            }}
          >
            <EditIcon />
          </IconButton>
        ),
      });
    });

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={<Text variant="body1">Se rapportoversikt</Text>}
      >
        <IconButton
          type="success"
          onClick={() => {
            setOpen(true);
            reportQuery.refetch();
          }}
        >
          <ArticleIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        paperSx={{
          minWidth: "70%",
          borderRadius: 2,
          height: "70%",
        }}
        onClose={() => {
          onClose();
          setOpen(false);
        }}
        title="Månedsrapport"
        rightHeaderComponent={
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              py: 1,
            }}
          >
            <Box
              sx={{
                width: "30%",
                display: "flex",
                justifyContent: "space-around",
                p: 1,
              }}
            >
              <OptionsPicker
                label="År"
                optionsList={yearsListDropdown()}
                value={selectedYear}
                onChange={setSelectedYear}
              />

              <OptionsPicker
                label="Periode"
                optionsList={{
                  "1": "Januar",
                  "2": "Februar",
                  "3": "Mars",
                  "4": "April",
                  "5": "Mai",
                  "6": "Juni",
                  "7": "Juli",
                  "8": "August",
                  "9": "September",
                  "10": "Oktober",
                  "11": "November",
                  "12": "Desember",
                }}
                onChange={setSelectedMonth}
                value={selectedMonth}
              />
            </Box>
          </Box>
        }
      >
        {reportQuery.isLoading ? (
          <CenterContainer>
            <CircularProgress size={100} />
          </CenterContainer>
        ) : tableData.length > 0 ? (
          <Box sx={{ py: 2, px: 1 }}>
            <Table
              headers={[
                { fieldName: "closedDate", name: "Stengt" },
                { fieldName: "openedDate", name: "Åpnet" },
                { fieldName: "workType", name: "Arbeidstype" },
                { fieldName: "totalTime", name: "Total tid" },
                { fieldName: "totalMinutes", name: "Antall minutter" },
                { fieldName: "section", name: "Seksjon" },
                { fieldName: "direction", name: "Retning" },
                { fieldName: "isDeducted", name: "Trekk" },
                { fieldName: "dayMinutes", name: "Dag minutter" },
                { fieldName: "nightMinutes", name: "Natt minutter" },
                { fieldName: "netMinutes", name: "Netto minutter" },
                { fieldName: "edit", name: "Rediger rad", align: "center" },
                { fieldName: "delete", name: "Slett rad", align: "center" },
              ]}
              data={tableData}
            />
          </Box>
        ) : (
          <CenterContainer>
            <Text variant="h5">Ingen data denne perioden</Text>
          </CenterContainer>
        )}
      </Dialog>
      {deleteDialogOpen && (
        <ConfirmDialog
          open={deleteDialogOpen}
          text="Er du sikker på at du vil slette denne raden?"
          acceptText="Ja, slett denne raden"
          cancelText="Avbryt"
          onAccept={() => {
            authorizedAvailabilityApi()
              .then((api) => {
                api.deleteEventId({ eventId: deleteEventId });
              })
              .then(() => {
                setDeleteEventId("");
                setDeleteDialogOpen(false);
                setTimeout(() => {
                  reportQuery.refetch();
                }, 2000);
              });
          }}
          onCancel={() => {
            setDeleteEventId("");
            setDeleteDialogOpen(false);
            setTimeout(() => {
              reportQuery.refetch();
            }, 2000);
          }}
        />
      )}
      {editDialogOpen && editEvent?.closedDate && editEvent?.openedDate && (
        <AddOrEditEventDialog
          edit
          refetch={reportQuery.refetch}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          editObject={editEvent}
          onClose={() => {
            setEditEvent(undefined);
            setEditDialogOpen(false);
            setTimeout(() => {
              reportQuery.refetch();
            }, 2000);
          }}
        />
      )}
    </React.Fragment>
  );
}
