import React, { useState } from "react";

import Box from "@mui/material/Box";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import { sensorIdNameMap } from "config/sensorIdNameMap";
import LineChart from "@smartinspection/ameta-frontend-components/charts/LineChart";
import {
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import { useQueryTrafficSensorCounterByIdAndYear } from "services/trafficQueryHooks";

const monthKeyToMonth: { [key: string]: number } = {
  m01: 1,
  m02: 2,
  m03: 3,
  m04: 4,
  m05: 5,
  m06: 6,
  m07: 7,
  m08: 8,
  m09: 9,
  m10: 10,
  m11: 11,
  m12: 12,
};

const yearObjectToDataArray = (yearObject: any, year: string) => {
  const filteredCounterYear =
    yearObject &&
    Object.keys(yearObject)
      .filter(function (k) {
        return k.indexOf("m") === 0;
      })
      .sort()
      .reduce(function (newData, k) {
        newData[k] = yearObject[k];
        return newData;
      }, {});

  const calculatedDataArray =
    filteredCounterYear &&
    Object.keys(filteredCounterYear).map((monthKey) => {
      const date = new Date();

      date.setFullYear(Number(year), monthKeyToMonth[monthKey], 1);
      date.setHours(0, 0, 0, 0);

      const hourDataObject = {
        time: date.getTime(),
        Passeringer: filteredCounterYear[monthKey].all
          ? filteredCounterYear[monthKey].all
          : 0,
      };
      return hourDataObject;
    });

  return calculatedDataArray;
};

function PassingsPastThreeYears() {
  const theme = useTheme();

  const [sensorId, setSensorId] = useState("54653V1751052");

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear().toString();

  const currentYearQuery = useQueryTrafficSensorCounterByIdAndYear(
    sensorId,
    currentYear
  );
  const currentYearData = currentYearQuery.data?.counter?.["y" + currentYear];
  const chartDataCurrentYear = yearObjectToDataArray(
    currentYearData,
    currentYear
  );

  const oneYearBack = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 1)
  )
    .getFullYear()
    .toString();

  const oneYearBackQuery = useQueryTrafficSensorCounterByIdAndYear(
    sensorId,
    oneYearBack
  );
  const oneYearBackData = oneYearBackQuery.data?.counter?.["y" + oneYearBack];
  const chartDataOneYearBack = yearObjectToDataArray(
    oneYearBackData,
    oneYearBack
  );

  const twoYearsBack = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 1)
  )
    .getFullYear()
    .toString();
  const twoYearsBackQuery = useQueryTrafficSensorCounterByIdAndYear(
    sensorId,
    twoYearsBack
  );
  const twoYearsBackData =
    twoYearsBackQuery.data?.counter?.["y" + twoYearsBack];

  const chartDataTwoYearsBack = yearObjectToDataArray(
    twoYearsBackData,
    twoYearsBack
  );

  const threeYearsBack = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 1)
  )
    .getFullYear()
    .toString();
  const threeYearsBackQuery = useQueryTrafficSensorCounterByIdAndYear(
    sensorId,
    threeYearsBack
  );
  const threeYearsBackData =
    threeYearsBackQuery.data?.counter?.["y" + threeYearsBack];

  const chartDataThreeYearsBack = yearObjectToDataArray(
    threeYearsBackData,
    threeYearsBack
  );

  let chartDataArray: any[] = [];

  if (chartDataCurrentYear) {
    chartDataArray = [...chartDataArray, ...chartDataCurrentYear];
  }

  if (chartDataOneYearBack) {
    chartDataArray = [...chartDataArray, ...chartDataOneYearBack];
  }
  if (chartDataTwoYearsBack) {
    chartDataArray = [...chartDataArray, ...chartDataTwoYearsBack];
  }
  if (chartDataThreeYearsBack) {
    chartDataArray = [...chartDataArray, ...chartDataThreeYearsBack];
  }

  chartDataArray = chartDataArray.sort((a, b) => {
    return a.time - b.time;
  });

  const dataLoading =
    currentYearQuery.isLoading ||
    oneYearBackQuery.isLoading ||
    twoYearsBackQuery.isLoading ||
    threeYearsBackQuery.isLoading;

  let cardContent: React.ReactNode = null;

  if (dataLoading) {
    cardContent = (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  } else {
    if (chartDataArray && chartDataArray.length > 0) {
      cardContent = (
        <LineChart
          data={chartDataArray}
          yAxisLabel="Antall passeringer"
          margin={{ left: 30, top: 10, right: 10, bottom: 30 }}
          dataProps={[
            {
              fieldName: "Passeringer",
              name: "Alle kjøretøy",
              color: theme.palette.info.main,
            },
          ]}
          timeWindow={generateFloatingWindow(Step.Month, 36, 1)}
        />
      );
    } else {
      <CenterContainer>
        <Text variant="h5">
          {`Ingen data for ${sensorIdNameMap[sensorId]}`}
        </Text>
      </CenterContainer>;
    }
  }

  return (
    <Card
      faIcon="traffic-light"
      title="Passeringstrend 3 år"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            pr: 2,
          }}
        >
          <OptionsPicker
            label="Sensor"
            value={sensorId}
            optionsList={sensorIdNameMap}
            onChange={(sensorId) => {
              setSensorId(sensorId);
            }}
          />
        </Box>
      }
    >
      {cardContent}
    </Card>
  );
}

export default PassingsPastThreeYears;
