/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SjaMonthDataObject,
    SjaMonthDataObjectFromJSON,
    SjaMonthDataObjectFromJSONTyped,
    SjaMonthDataObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface SjaYearDataObject
 */
export interface SjaYearDataObject {
    /**
     * 
     * @type {number}
     * @memberof SjaYearDataObject
     */
    sumSja: number;
    /**
     * 
     * @type {{ [key: string]: SjaMonthDataObject; }}
     * @memberof SjaYearDataObject
     */
    months: { [key: string]: SjaMonthDataObject; };
}

export function SjaYearDataObjectFromJSON(json: any): SjaYearDataObject {
    return SjaYearDataObjectFromJSONTyped(json, false);
}

export function SjaYearDataObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SjaYearDataObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sumSja': json['sumSja'],
        'months': (mapValues(json['months'], SjaMonthDataObjectFromJSON)),
    };
}

export function SjaYearDataObjectToJSON(value?: SjaYearDataObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sumSja': value.sumSja,
        'months': (mapValues(value.months, SjaMonthDataObjectToJSON)),
    };
}


