import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Box from "@mui/material/Box";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import PieChart from "@smartinspection/ameta-frontend-components/charts/PieChart";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import ChartLegend from "components/mainView/environmentCard/ChartLegend";

import { format } from "date-fns";
import { nb } from "date-fns/locale";

import Card from "@smartinspection/ameta-frontend-components/display/Card";

import EnvironmentCardMap from "components/mainView/environmentCard/EnvironmentCardMap";
import { useYmOverviewQuery } from "services/ymQueryHooks";

function EnvironmentCard() {
  const overviewQuery = useYmOverviewQuery();
  const overviewData = overviewQuery.data;
  const theme = useTheme();
  const navigate = useNavigate();

  let currentMonth = format(new Date(), "LLLL", { locale: nb });

  currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
  const currentYear = format(new Date(), "yyyy");

  let cardContent: React.ReactElement | undefined = undefined;
  if (overviewQuery.isLoading) {
    cardContent = <CircularProgress size={50} />;
  } else if (overviewData) {
    cardContent = (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 1,
        }}
      >
        <Box sx={{ width: 450, height: "100%" }}>
          <PieChart
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            dataProps={[
              {
                fieldName: "alarm",
                name: "Over grenseverdi",
                color: theme.palette.error.main,
              },
              {
                fieldName: "warning",
                name: "Nær grenseverdi",
                color: theme.palette.warning.main,
              },
              {
                fieldName: "ok",
                name: "Under grenseverdi",
                color: theme.palette.success.main,
              },
            ]}
            defaultLabelText="Alarmerende punkter"
            defaultValueText={`${
              overviewData.statuses.alarm + overviewData.statuses.warning
            } / ${
              overviewData.statuses.alarm +
              overviewData.statuses.warning +
              overviewData.statuses.ok
            }`}
            activeValueFormatter={(_, value) => {
              return `${value} stk.`;
            }}
            data={{
              alarm: overviewData.statuses.alarm,
              warning: overviewData.statuses.warning,
              ok: overviewData.statuses.ok,
            }}
          />
        </Box>
        <Box
          sx={{
            height: "100%",
            width: 400,
            px: 4,
            py: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <ChartLegend
            color={theme.palette.error.main}
            label="Over grenseverdi"
          />
          <ChartLegend
            color={theme.palette.warning.main}
            label="Nær grenseverdi"
          />
          <ChartLegend
            color={theme.palette.success.main}
            label="Under grenseverdi"
          />
        </Box>

        <EnvironmentCardMap
          markers={overviewData.markedPoints && overviewData.markedPoints}
        />
      </Box>
    );
  } else {
    cardContent = (
      <CenterContainer>
        <Text variant="h5">Fant ikke data</Text>
      </CenterContainer>
    );
  }

  return (
    <Card
      type="drawer"
      faIcon="wind"
      title="Miljø"
      subtitle={`Utslippsgrense, ${currentMonth} ${currentYear}`}
      rightHeaderComponent={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pr: 1,
            pt: 1.5,
          }}
        >
          <IconButton
            transparentBackground
            type="success"
            onClick={() => {
              navigate("/dashboard/ym-map");
            }}
          >
            <FontAwesomeIcon icon="external-link-alt" size="lg" />
          </IconButton>
        </Box>
      }
    >
      {cardContent}
    </Card>
  );
}

export default EnvironmentCard;
