import React from "react";
import { Auth } from "aws-amplify";
import { queryClient } from "services/apiService";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleHideMenus } from "redux/global";
import { TStore } from "redux/store";
import SINavBar from "@smartinspection/ameta-frontend-components/navigation/NavBar";

import FlareIcon from "@mui/icons-material/Flare";
import TrafficIcon from "@mui/icons-material/Traffic";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import SettingsIcon from "@mui/icons-material/Settings";

export default function NavBarWrapper() {
  const navigate = useNavigate();
  const globalState = useSelector((state: TStore) => state.global);
  const dispatch = useDispatch();

  return (
    <SINavBar
      hideMenu={globalState.hideMenus}
      onMinimizeClick={() => {
        dispatch(toggleHideMenus());
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 300);
      }}
      avatarMenuItems={[
        {
          label: "Logg ut",
          onClick: () => {
            Auth.signOut().then(() => {
              queryClient.clear();
            });
          },
        },
      ]}
      menuItems={[
        {
          title: "Dashboard",
          icon: DashboardIcon,
          path: "/dashboard",
          onClick: () => {
            navigate("/dashboard");
          },
        },
        {
          title: "Tilgjengelighet",
          icon: AssessmentIcon,
          path: "/dashboard/availability",
          onClick: () => {
            navigate("/dashboard/availability");
          },
        },
        {
          title: "Sikkerhet",
          icon: AssignmentIcon,
          path: "/dashboard/security",
          onClick: () => {
            navigate("/dashboard/security");
          },
        },
        {
          title: "HMS",
          icon: HealthAndSafetyIcon,
          path: "/dashboard/hms",
          onClick: () => {
            navigate("/dashboard/hms");
          },
        },
        {
          title: "Miljø",
          icon: FlareIcon,
          path: "/dashboard/ym-map",
          onClick: () => {
            navigate("/dashboard/ym-map");
          },
        },
        {
          title: "Trafikkvisning",
          icon: TrafficIcon,
          path: "/dashboard/traffic",
          onClick: () => {
            navigate("/dashboard/traffic");
          },
        },
        {
          title: "Innstillinger",
          icon: SettingsIcon,
          path: "/dashboard/settings",
          onClick: () => {
            navigate("/dashboard/settings");
          },
        },
      ]}
    />
  );
}
