import React, { useState } from "react";
import { format } from "date-fns";
import Box from "@mui/material/Box";

import BarChart from "@smartinspection/ameta-frontend-components/charts/BarChart";
import Card from "@smartinspection/ameta-frontend-components/display/Card";

import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import VerticalSplit from "@smartinspection/ameta-frontend-components/layout/VerticalSplit";
import ValueIndicator from "@smartinspection/ameta-frontend-components/display/ValueIndicator";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import { HMSCounter, NhYearDataObject } from "services/hmsApi";
import { yearsListDropdown } from "utils/yearsListDropDown";

interface GraphDataN {
  label: string;
  N?: number;
}

interface GraphDataH {
  label: string;
  H?: number;
}

export default function NCard({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const theme = useTheme();

  const monthConverter = (date: Date) => {
    const month = format(date, "M");
    const paddingString = "m";
    return paddingString.concat(month);
  };
  const yearConverter = (date: Date) => {
    const year = format(date, "yyyy");
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [previousMonthDate, setPreviousMonthDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 2))
  );

  const labelMonthConverter = {
    m1: "Jan",
    m2: "Feb",
    m3: "Mar",
    m4: "Apr",
    m5: "Mai",
    m6: "Jun",
    m7: "Jul",
    m8: "Aug",
    m9: "Sep",
    m10: "Okt",
    m11: "Nov",
    m12: "Des",
  };

  const yearMonthMapper: (year: NhYearDataObject | undefined) => {
    n: GraphDataN[];
    h: GraphDataH[];
  } = (year: NhYearDataObject | undefined) => {
    const nNumbers: GraphDataN[] = [];
    const hNumbers: GraphDataH[] = [];
    Object.entries(labelMonthConverter).forEach(([key, value]) => {
      nNumbers.push({
        label: value,
        ...(year?.months?.[key]?.nValue && {
          "N-verdi": year.months[key].nValue,
        }),
      });
      hNumbers.push({
        label: value,
        ...(year?.months?.[key]?.h1Value && {
          "H-verdi": year.months[key].h1Value,
        }),
      });
    });
    return { n: nNumbers, h: hNumbers };
  };

  const nhReportYear =
    hmsCounterData?.data?.nh?.years?.[yearConverter(selectedDate)];

  const nhPreviousMonth =
    hmsCounterData?.data?.nh?.years?.[yearConverter(previousMonthDate)]
      ?.months?.[monthConverter(previousMonthDate)];

  return (
    <Card
      type="default"
      faIcon="notes-medical"
      title="N verdi"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="Periode"
              optionsList={{
                0: "Januar",
                1: "Februar",
                2: "Mars",
                3: "April",
                4: "Mai",
                5: "Juni",
                6: "Juli",
                7: "August",
                8: "September",
                9: "Oktober",
                10: "November",
                11: "Desember",
              }}
              value={selectedDate.getMonth().toString()}
              onChange={(e) => {
                const tempDate = new Date(selectedDate);
                tempDate.setMonth(+e);
                setSelectedDate(new Date(tempDate));
                tempDate.setMonth(tempDate.getMonth() - 1);
                setPreviousMonthDate(new Date(tempDate));
              }}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="År"
              optionsList={yearsListDropdown()}
              value={selectedDate.getFullYear().toString()}
              onChange={(e) => {
                const tempDate = new Date(selectedDate);
                tempDate.setFullYear(+e);
                setSelectedDate(new Date(tempDate));
                tempDate.setMonth(tempDate.getMonth() - 1);
                setPreviousMonthDate(new Date(tempDate));
              }}
            />
          </Box>
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <VerticalSplit
            divider
            left={
              <ValueIndicator
                type="flat"
                label="N-verdi"
                value={
                  nhReportYear?.months?.[monthConverter(selectedDate)]?.nValue
                    ? +nhReportYear?.months?.[monthConverter(selectedDate)]
                        ?.nValue
                    : undefined
                }
                previous={{
                  value: nhPreviousMonth?.nValue
                    ? +nhPreviousMonth?.nValue
                    : undefined,
                  comparison: "percentage",
                  endingText: "enn forrige periode",
                  isIncreaseGood: "yes",
                }}
              />
            }
            right={
              <ValueIndicator
                type="flat"
                label="N-verdi snitt hittil i år"
                value={nhReportYear?.avgN ? +nhReportYear.avgN : undefined}
              />
            }
          />
        </Box>
        <Box sx={{ flex: 2 }}>
          <BarChart
            margin={{ top: 0, bottom: 10, left: 0, right: 20 }}
            dataProps={[
              {
                fieldName: "N-verdi",
                name: "N-verdi",
                color: theme.palette.error.main,
              },
            ]}
            data={
              yearMonthMapper(nhReportYear).n as unknown as {
                [key: string]: string | number;
              }[]
            }
          />
        </Box>
      </Box>
    </Card>
  );
}
