/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Security API
 * A rest api for managing the ameta security system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SecurityCounterData,
    SecurityCounterDataFromJSON,
    SecurityCounterDataFromJSONTyped,
    SecurityCounterDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface SecurityCounter
 */
export interface SecurityCounter {
    /**
     * 
     * @type {SecurityCounterData}
     * @memberof SecurityCounter
     */
    data: SecurityCounterData;
}

export function SecurityCounterFromJSON(json: any): SecurityCounter {
    return SecurityCounterFromJSONTyped(json, false);
}

export function SecurityCounterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityCounter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': SecurityCounterDataFromJSON(json['data']),
    };
}

export function SecurityCounterToJSON(value?: SecurityCounter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SecurityCounterDataToJSON(value.data),
    };
}


