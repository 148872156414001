import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

import frontpageBackground from "assets/img/frontpage_background.jpg";

import Login from "@smartinspection/ameta-frontend-components/login/Login";

import isUserLoggedIn from "utils/isUserLoggedIn";

export default function FrontPage() {
  const user = isUserLoggedIn();

  const navigate = useNavigate();

  useEffect(() => {
    if (user === "user") {
      navigate("/dashboard");
    }
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          zIndex: 2,
          width: { xs: "90%", lg: "50%" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Login />
      </Box>

      <img
        style={{
          position: "absolute",
          zIndex: 1,
          height: "100%",
          width: "100%",
        }}
        alt="Frontpage background"
        src={frontpageBackground}
      />
    </Box>
  );
}
