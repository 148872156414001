/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SectionCounterDataMonths1,
    SectionCounterDataMonths1FromJSON,
    SectionCounterDataMonths1FromJSONTyped,
    SectionCounterDataMonths1ToJSON,
} from './';

/**
 * 
 * @export
 * @interface SectionCounterDataNonDeducted
 */
export interface SectionCounterDataNonDeducted {
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataMonths1; }}
     * @memberof SectionCounterDataNonDeducted
     */
    months: { [key: string]: SectionCounterDataMonths1; };
}

export function SectionCounterDataNonDeductedFromJSON(json: any): SectionCounterDataNonDeducted {
    return SectionCounterDataNonDeductedFromJSONTyped(json, false);
}

export function SectionCounterDataNonDeductedFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionCounterDataNonDeducted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'months': (mapValues(json['months'], SectionCounterDataMonths1FromJSON)),
    };
}

export function SectionCounterDataNonDeductedToJSON(value?: SectionCounterDataNonDeducted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'months': (mapValues(value.months, SectionCounterDataMonths1ToJSON)),
    };
}


