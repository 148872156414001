import { setCookie } from "services/cookieService";

import { useQueryCognitoUser } from "services/cognitoHooks";

export default function SessionIdCookie() {
  const cognitoUserQuery = useQueryCognitoUser();
  const cognitoUser = cognitoUserQuery.data;
  const jwtToken = cognitoUser?.authToken;

  setCookie("session-id", jwtToken);

  return null;
}
