/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewYmPointLocation,
    NewYmPointLocationFromJSON,
    NewYmPointLocationFromJSONTyped,
    NewYmPointLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewYmPoint
 */
export interface NewYmPoint {
    /**
     * 
     * @type {string}
     * @memberof NewYmPoint
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof NewYmPoint
     */
    measurementPointId: string;
    /**
     * 
     * @type {string}
     * @memberof NewYmPoint
     */
    waterSourceId: string;
    /**
     * 
     * @type {string}
     * @memberof NewYmPoint
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof NewYmPoint
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof NewYmPoint
     */
    place?: string;
    /**
     * 
     * @type {NewYmPointLocation}
     * @memberof NewYmPoint
     */
    location: NewYmPointLocation;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NewYmPoint
     */
    thresholdValues?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof NewYmPoint
     */
    geohash: string;
}

export function NewYmPointFromJSON(json: any): NewYmPoint {
    return NewYmPointFromJSONTyped(json, false);
}

export function NewYmPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewYmPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': json['projectId'],
        'measurementPointId': json['measurementPointId'],
        'waterSourceId': json['waterSourceId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'location': NewYmPointLocationFromJSON(json['location']),
        'thresholdValues': !exists(json, 'thresholdValues') ? undefined : json['thresholdValues'],
        'geohash': json['geohash'],
    };
}

export function NewYmPointToJSON(value?: NewYmPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'measurementPointId': value.measurementPointId,
        'waterSourceId': value.waterSourceId,
        'status': value.status,
        'comments': value.comments,
        'place': value.place,
        'location': NewYmPointLocationToJSON(value.location),
        'thresholdValues': value.thresholdValues,
        'geohash': value.geohash,
    };
}


