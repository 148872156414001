/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface DeleteNhReportRequest {
    nhReportId: string;
}

export interface DeleteRuhReportRequest {
    ruhReportId: string;
}

export interface DeleteSjaReportRequest {
    sjaReportId: string;
}

/**
 * 
 */
export class DeleteReportApi extends runtime.BaseAPI {

    /**
     * delete nh report
     */
    async deleteNhReportRaw(requestParameters: DeleteNhReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.nhReportId === null || requestParameters.nhReportId === undefined) {
            throw new runtime.RequiredError('nhReportId','Required parameter requestParameters.nhReportId was null or undefined when calling deleteNhReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/nh-report/{nhReportId}`.replace(`{${"nhReportId"}}`, encodeURIComponent(String(requestParameters.nhReportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete nh report
     */
    async deleteNhReport(requestParameters: DeleteNhReportRequest): Promise<void> {
        await this.deleteNhReportRaw(requestParameters);
    }

    /**
     * delete ruh report
     */
    async deleteRuhReportRaw(requestParameters: DeleteRuhReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ruhReportId === null || requestParameters.ruhReportId === undefined) {
            throw new runtime.RequiredError('ruhReportId','Required parameter requestParameters.ruhReportId was null or undefined when calling deleteRuhReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/ruh-report/{ruhReportId}`.replace(`{${"ruhReportId"}}`, encodeURIComponent(String(requestParameters.ruhReportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete ruh report
     */
    async deleteRuhReport(requestParameters: DeleteRuhReportRequest): Promise<void> {
        await this.deleteRuhReportRaw(requestParameters);
    }

    /**
     * delete sja report
     */
    async deleteSjaReportRaw(requestParameters: DeleteSjaReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sjaReportId === null || requestParameters.sjaReportId === undefined) {
            throw new runtime.RequiredError('sjaReportId','Required parameter requestParameters.sjaReportId was null or undefined when calling deleteSjaReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/sja-report/{sjaReportId}`.replace(`{${"sjaReportId"}}`, encodeURIComponent(String(requestParameters.sjaReportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete sja report
     */
    async deleteSjaReport(requestParameters: DeleteSjaReportRequest): Promise<void> {
        await this.deleteSjaReportRaw(requestParameters);
    }

}
