/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface YMPointsResponseLastEvaluatedKey
 */
export interface YMPointsResponseLastEvaluatedKey {
    /**
     * 
     * @type {string}
     * @memberof YMPointsResponseLastEvaluatedKey
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof YMPointsResponseLastEvaluatedKey
     */
    sort?: string;
    /**
     * 
     * @type {string}
     * @memberof YMPointsResponseLastEvaluatedKey
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof YMPointsResponseLastEvaluatedKey
     */
    geohash?: string;
}

export function YMPointsResponseLastEvaluatedKeyFromJSON(json: any): YMPointsResponseLastEvaluatedKey {
    return YMPointsResponseLastEvaluatedKeyFromJSONTyped(json, false);
}

export function YMPointsResponseLastEvaluatedKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): YMPointsResponseLastEvaluatedKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'geohash': !exists(json, 'geohash') ? undefined : json['geohash'],
    };
}

export function YMPointsResponseLastEvaluatedKeyToJSON(value?: YMPointsResponseLastEvaluatedKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'tenantId': value.tenantId,
        'geohash': value.geohash,
    };
}


