/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RUHReport
 */
export interface RUHReport {
    /**
     * 
     * @type {string}
     * @memberof RUHReport
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof RUHReport
     */
    ruhType: string;
    /**
     * 
     * @type {number}
     * @memberof RUHReport
     */
    ruhNr: number;
    /**
     * 
     * @type {string}
     * @memberof RUHReport
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof RUHReport
     */
    closed?: string;
}

export function RUHReportFromJSON(json: any): RUHReport {
    return RUHReportFromJSONTyped(json, false);
}

export function RUHReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): RUHReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'ruhType': json['ruhType'],
        'ruhNr': json['ruhNr'],
        'description': json['description'],
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
    };
}

export function RUHReportToJSON(value?: RUHReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'ruhType': value.ruhType,
        'ruhNr': value.ruhNr,
        'description': value.description,
        'closed': value.closed,
    };
}


