const development = {
  Auth: {
    userPoolBaseUri: "auth.dev.ameta.app",
    region: "eu-west-1",
    userPoolId: "eu-west-1_6TtSEvyYZ",
    userPoolWebClientId: "m6hq76dp411fvd910cv7utde4",

    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: "auth.dev.ameta.app",
      scope: [
        // Amplify doesn't respect this and gets all scopes
        "email",
        "openid",
        "profile",
      ],
      redirectSignIn: "http://localhost:3000",
      redirectSignOut: "http://localhost:3000",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

const deployment = {
  Auth: {
    userPoolBaseUri: process.env.REACT_APP_COGNITO_USER_POOL_BASE_URL,
    region: "eu-west-1",
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: process.env.REACT_APP_COGNITO_USER_POOL_BASE_URL,
      scope: [
        // Amplify doesn't respect this and gets all scopes
        "email",
        "openid",
        "profile",
      ],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URL,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

const config =
  process.env.NODE_ENV === "development" ? development : deployment;
export default config;
