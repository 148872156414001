import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';

export const useQueryCognitoUser: () => {
    data:
      | {
          firstName: string;
          imageUrl: string;
          username: string;
          authToken: string;
        }
      | undefined;
    refetch: () => void;
  } = () => {
    const { data, refetch } = useQuery('cognitoUser', async () => {
      const cognitoUser = await Auth.currentSession();
      const token = cognitoUser.getIdToken();
      return {
        firstName: token.payload.given_name,
        imageUrl: token.payload.picture,
        username: token.payload.username,
        authToken: token.getJwtToken(),
      };
    });
    return { data, refetch };
  };
  