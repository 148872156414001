/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface YMOverviewResponseStatuses
 */
export interface YMOverviewResponseStatuses {
    /**
     * 
     * @type {number}
     * @memberof YMOverviewResponseStatuses
     */
    ok: number;
    /**
     * 
     * @type {number}
     * @memberof YMOverviewResponseStatuses
     */
    warning: number;
    /**
     * 
     * @type {number}
     * @memberof YMOverviewResponseStatuses
     */
    alarm: number;
}

export function YMOverviewResponseStatusesFromJSON(json: any): YMOverviewResponseStatuses {
    return YMOverviewResponseStatusesFromJSONTyped(json, false);
}

export function YMOverviewResponseStatusesFromJSONTyped(json: any, ignoreDiscriminator: boolean): YMOverviewResponseStatuses {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ok': json['ok'],
        'warning': json['warning'],
        'alarm': json['alarm'],
    };
}

export function YMOverviewResponseStatusesToJSON(value?: YMOverviewResponseStatuses | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ok': value.ok,
        'warning': value.warning,
        'alarm': value.alarm,
    };
}


