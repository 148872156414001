/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HMSCounter,
    HMSCounterFromJSON,
    HMSCounterToJSON,
} from '../models';

/**
 * 
 */
export class HmsCounterApi extends runtime.BaseAPI {

    /**
     * get a hms counter
     */
    async getHmsCounterRaw(): Promise<runtime.ApiResponse<HMSCounter>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/hms-counter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HMSCounterFromJSON(jsonValue));
    }

    /**
     * get a hms counter
     */
    async getHmsCounter(): Promise<HMSCounter> {
        const response = await this.getHmsCounterRaw();
        return await response.value();
    }

}
