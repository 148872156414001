import React from "react";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import ValueIndicator from "@smartinspection/ameta-frontend-components/display/ValueIndicator";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

import Box from "@mui/material/Box";

import { HMSCounter } from "services/hmsApi";
import { monthConverter, yearConverter } from "utils/converters";

import SextupleSplit from "@smartinspection/ameta-frontend-components/layout/SextupleSplit";

function NandHValueCard({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const navigate = useNavigate();

  const currentDate = new Date();
  const lastMonth = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
  const monthBefore = new Date(
    currentDate.setMonth(currentDate.getMonth() - 1)
  );
  const previousMonthDate = new Date(currentDate);
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  let currentMonth = format(lastMonth, "LLLL", { locale: nb });

  currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
  const currentYear = format(lastMonth, "yyyy");

  return (
    <Card
      type="drawer"
      title="HMS • N og H-verdi"
      faIcon="notes-medical"
      subtitle={`${currentMonth} ${currentYear}`}
      rightHeaderComponent={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pr: 1,
            pt: 1.5,
          }}
        >
          <IconButton
            transparentBackground
            type="success"
            onClick={() => {
              navigate("/dashboard/hms");
            }}
          >
            <FontAwesomeIcon icon="external-link-alt" size="lg" />
          </IconButton>
        </Box>
      }
    >
      <SextupleSplit
        divider
        topLeft={
          <Box sx={{ height: "100%", width: "100%" }}>
            <ValueIndicator
              type="flat"
              label="N-verdi"
              faIcon="calculator"
              value={
                hmsCounterData?.data?.nh?.years?.[yearConverter(lastMonth)]
                  ?.months?.[monthConverter(lastMonth)]?.nValue
                  ? +hmsCounterData?.data?.nh?.years?.[yearConverter(lastMonth)]
                      ?.months?.[monthConverter(lastMonth)]?.nValue
                  : undefined
              }
              previous={{
                value: hmsCounterData?.data?.nh?.years?.[
                  yearConverter(monthBefore)
                ]?.months?.[monthConverter(monthBefore)]?.nValue
                  ? +hmsCounterData?.data?.nh?.years?.[
                      yearConverter(monthBefore)
                    ]?.months?.[monthConverter(monthBefore)]?.nValue
                  : undefined,
                comparison: "percentage",
                endingText: "enn forrige periode",
                isIncreaseGood: "yes",
              }}
            />
          </Box>
        }
        bottomLeft={
          <Box sx={{ height: "100%", width: "100%", pr: 1 }}>
            <ValueIndicator
              type="flat"
              label="N-verdi hittil i år"
              faIcon="calendar"
              value={
                hmsCounterData?.data?.nh?.years?.[yearConverter(monthBefore)]
                  ?.avgN
                  ? (Number(
                      hmsCounterData?.data?.nh?.years?.[
                        yearConverter(monthBefore)
                      ].avgN
                    ).toFixed(2) as any)
                  : undefined
              }
            />
          </Box>
        }
        topMiddle={
          <ValueIndicator
            type="flat"
            label="H1-verdi"
            faIcon="calculator"
            value={
              hmsCounterData?.data?.nh?.years?.[yearConverter(lastMonth)]
                ?.months?.[monthConverter(lastMonth)]?.h1Value
                ? +hmsCounterData?.data?.nh?.years?.[yearConverter(lastMonth)]
                    ?.months?.[monthConverter(lastMonth)]?.h1Value
                : undefined
            }
            previous={{
              value: hmsCounterData?.data?.nh?.years?.[
                yearConverter(monthBefore)
              ]?.months?.[monthConverter(monthBefore)]?.h1Value
                ? +hmsCounterData?.data?.nh?.years?.[yearConverter(monthBefore)]
                    ?.months?.[monthConverter(monthBefore)]?.h1Value
                : undefined,
              comparison: "percentage",
              endingText: "enn forrige periode",
              isIncreaseGood: "yes",
            }}
          />
        }
        bottomMiddle={
          <ValueIndicator
            type="flat"
            label="H1-verdi hittil i år"
            faIcon="calendar"
            value={
              hmsCounterData?.data?.nh?.years?.[yearConverter(monthBefore)]
                ?.avgH1
                ? (Number(
                    hmsCounterData?.data?.nh?.years?.[
                      yearConverter(monthBefore)
                    ].avgH1
                  ).toFixed(2) as any)
                : undefined
            }
          />
        }
        topRight={
          <ValueIndicator
            type="flat"
            label="H2-verdi"
            faIcon="calculator"
            value={
              hmsCounterData?.data?.nh?.years?.[yearConverter(lastMonth)]
                ?.months?.[monthConverter(lastMonth)]?.h2Value
                ? +hmsCounterData?.data?.nh?.years?.[yearConverter(lastMonth)]
                    ?.months?.[monthConverter(lastMonth)]?.h2Value
                : undefined
            }
            previous={{
              value: hmsCounterData?.data?.nh?.years?.[
                yearConverter(monthBefore)
              ]?.months?.[monthConverter(monthBefore)]?.h2Value
                ? +hmsCounterData?.data?.nh?.years?.[yearConverter(monthBefore)]
                    ?.months?.[monthConverter(monthBefore)]?.h2Value
                : undefined,
              comparison: "percentage",
              endingText: "enn forrige periode",
              isIncreaseGood: "yes",
            }}
          />
        }
        bottomRight={
          <ValueIndicator
            type="flat"
            label="H2-verdi hittil i år"
            faIcon="calendar"
            value={
              hmsCounterData?.data?.nh?.years?.[yearConverter(monthBefore)]
                ?.avgH1
                ? (Number(
                    hmsCounterData?.data?.nh?.years?.[
                      yearConverter(monthBefore)
                    ].avgH2
                  ).toFixed(2) as any)
                : undefined
            }
          />
        }
      />
    </Card>
  );
}

export default NandHValueCard;
