/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSjaReport,
    GetSjaReportFromJSON,
    GetSjaReportFromJSONTyped,
    GetSjaReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface SjaMonthDataObject
 */
export interface SjaMonthDataObject {
    /**
     * 
     * @type {number}
     * @memberof SjaMonthDataObject
     */
    sumSja: number;
    /**
     * 
     * @type {{ [key: string]: Array<GetSjaReport>; }}
     * @memberof SjaMonthDataObject
     */
    days: { [key: string]: Array<GetSjaReport>; };
}

export function SjaMonthDataObjectFromJSON(json: any): SjaMonthDataObject {
    return SjaMonthDataObjectFromJSONTyped(json, false);
}

export function SjaMonthDataObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SjaMonthDataObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sumSja': json['sumSja'],
        'days': json['days'],
    };
}

export function SjaMonthDataObjectToJSON(value?: SjaMonthDataObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sumSja': value.sumSja,
        'days': value.days,
    };
}


