/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentReportParsingReportErrors
 */
export interface DocumentReportParsingReportErrors {
    /**
     * 
     * @type {string}
     * @memberof DocumentReportParsingReportErrors
     */
    unknownfileExtension?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportParsingReportErrors
     */
    unknownfieldName?: string;
}

export function DocumentReportParsingReportErrorsFromJSON(json: any): DocumentReportParsingReportErrors {
    return DocumentReportParsingReportErrorsFromJSONTyped(json, false);
}

export function DocumentReportParsingReportErrorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentReportParsingReportErrors {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unknownfileExtension': !exists(json, 'unknownfileExtension') ? undefined : json['unknownfileExtension'],
        'unknownfieldName': !exists(json, 'unknownfieldName') ? undefined : json['unknownfieldName'],
    };
}

export function DocumentReportParsingReportErrorsToJSON(value?: DocumentReportParsingReportErrors | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unknownfileExtension': value.unknownfileExtension,
        'unknownfieldName': value.unknownfieldName,
    };
}


