/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YMPointData,
    YMPointDataFromJSON,
    YMPointDataFromJSONTyped,
    YMPointDataToJSON,
    YmPointCenterPoint,
    YmPointCenterPointFromJSON,
    YmPointCenterPointFromJSONTyped,
    YmPointCenterPointToJSON,
    YmPointGeojson,
    YmPointGeojsonFromJSON,
    YmPointGeojsonFromJSONTyped,
    YmPointGeojsonToJSON,
} from './';

/**
 * 
 * @export
 * @interface YmPoint
 */
export interface YmPoint {
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    geohash: string;
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    projectId: string;
    /**
     * 
     * @type {YmPointCenterPoint}
     * @memberof YmPoint
     */
    centerPoint: YmPointCenterPoint;
    /**
     * 
     * @type {YmPointGeojson}
     * @memberof YmPoint
     */
    geojson: YmPointGeojson;
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    measurementPointId: string;
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    waterSourceId: string;
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof YmPoint
     */
    place?: string;
    /**
     * 
     * @type {YMPointData}
     * @memberof YmPoint
     */
    data?: YMPointData;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof YmPoint
     */
    thresholdValues?: { [key: string]: string; };
}

export function YmPointFromJSON(json: any): YmPoint {
    return YmPointFromJSONTyped(json, false);
}

export function YmPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): YmPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'geohash': json['geohash'],
        'projectId': json['projectId'],
        'centerPoint': YmPointCenterPointFromJSON(json['centerPoint']),
        'geojson': YmPointGeojsonFromJSON(json['geojson']),
        'measurementPointId': json['measurementPointId'],
        'waterSourceId': json['waterSourceId'],
        'status': json['status'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'data': !exists(json, 'data') ? undefined : YMPointDataFromJSON(json['data']),
        'thresholdValues': !exists(json, 'thresholdValues') ? undefined : json['thresholdValues'],
    };
}

export function YmPointToJSON(value?: YmPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'geohash': value.geohash,
        'projectId': value.projectId,
        'centerPoint': YmPointCenterPointToJSON(value.centerPoint),
        'geojson': YmPointGeojsonToJSON(value.geojson),
        'measurementPointId': value.measurementPointId,
        'waterSourceId': value.waterSourceId,
        'status': value.status,
        'comments': value.comments,
        'place': value.place,
        'data': YMPointDataToJSON(value.data),
        'thresholdValues': value.thresholdValues,
    };
}


