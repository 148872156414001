import React, { useState } from "react";

import Box from "@mui/material/Box";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import QuadrantSplit from "@smartinspection/ameta-frontend-components/layout/QuadrantSplit";
import ValueIndicator from "@smartinspection/ameta-frontend-components/display/ValueIndicator";

import { HMSCounter } from "services/hmsApi";
import { format } from "date-fns";
import { yearsListDropdown } from "utils/yearsListDropDown";

export default function RSCard({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const monthConverter = (date: Date) => {
    const month = format(date, "M");
    const paddingString = "m";
    return paddingString.concat(month);
  };
  const yearConverter = (date: Date) => {
    const year = format(date, "yyyy");
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [previousMonthDate, setPreviousMonthDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 2))
  );

  const sjaReportYear =
    hmsCounterData?.data?.sja?.years?.[yearConverter(selectedDate)];

  const ruhReportYear =
    hmsCounterData?.data?.ruh?.years?.[yearConverter(selectedDate)];

  const ruhPreviousMonth =
    hmsCounterData?.data?.ruh?.years?.[yearConverter(previousMonthDate)]
      ?.months?.[monthConverter(previousMonthDate)];

  const sjaPreviousMonth =
    hmsCounterData?.data?.sja?.years?.[yearConverter(previousMonthDate)]
      ?.months?.[monthConverter(previousMonthDate)];

  return (
    <Card
      type="default"
      faIcon="notes-medical"
      title="RUH og SJA"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="Periode"
              value={selectedDate.getMonth().toString()}
              optionsList={{
                0: "Januar",
                1: "Februar",
                2: "Mars",
                3: "April",
                4: "Mai",
                5: "Juni",
                6: "Juli",
                7: "August",
                8: "September",
                9: "Oktober",
                10: "November",
                11: "Desember",
              }}
              onChange={(e) => {
                const tempDate = new Date(selectedDate);
                tempDate.setMonth(+e);
                setSelectedDate(new Date(tempDate));
                tempDate.setMonth(tempDate.getMonth() - 1);
                setPreviousMonthDate(new Date(tempDate));
              }}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="År"
              optionsList={yearsListDropdown()}
              value={selectedDate.getFullYear().toString()}
              onChange={(e) => {
                const tempDate = new Date(selectedDate);
                tempDate.setFullYear(+e);
                setSelectedDate(new Date(tempDate));
                tempDate.setMonth(tempDate.getMonth() - 1);
                setPreviousMonthDate(new Date(tempDate));
              }}
            />
          </Box>
        </Box>
      }
    >
      <QuadrantSplit
        divider
        topLeft={
          <ValueIndicator
            type="flat"
            label="RUH"
            value={
              ruhReportYear?.months?.[monthConverter(selectedDate)]?.sumRuh
            }
            previous={{
              value: ruhPreviousMonth?.sumRuh,
              comparison: "value",
              endingText: "enn forrige periode",
              isIncreaseGood: "no",
            }}
          />
        }
        topRight={
          <ValueIndicator
            type="flat"
            label="SJA"
            value={
              sjaReportYear?.months?.[monthConverter(selectedDate)]?.sumSja
            }
            previous={{
              value: sjaPreviousMonth?.sumSja,
              comparison: "value",
              endingText: "enn forrige periode",
              isIncreaseGood: "yes",
            }}
          />
        }
        bottomLeft={
          <ValueIndicator
            type="flat"
            label="RUH sum hittil i år"
            value={ruhReportYear?.sumRuh}
          />
        }
        bottomRight={
          <ValueIndicator
            type="flat"
            label="SJA sum hittil i år"
            value={sjaReportYear?.sumSja}
          />
        }
      />
    </Card>
  );
}
