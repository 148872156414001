import React, { useState } from "react";
import { format } from "date-fns";

import Box from "@mui/material/Box";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Table from "@smartinspection/ameta-frontend-components/display/Table";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";
import { authorizedDeleteReportApi } from "services/apiService";

import { HMSCounter, RuhMonthDataObject, RUHReport } from "services/hmsApi";
import { yearsListDropdown } from "utils/yearsListDropDown";

export default function RuhReportDialog({
  hmsCounterData,
  refetch,
}: {
  hmsCounterData: HMSCounter;
  refetch: () => void;
}) {
  const [open, setOpen] = useState(false);
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
  const [selectedMonth, setSelectedMonth] = useState(
    format(previousMonthDate, "M")
  );

  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, "yyyy")
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deleteRuhReportId, setDeleteRuhReportId] = useState("");
  const monthStringConverter = (month: string) => {
    const paddingString = "m";
    return paddingString.concat(month);
  };

  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const ruhEventsInMonth: (month: RuhMonthDataObject) => RUHReport[] = (
    month: RuhMonthDataObject
  ) => {
    // Can get the events in scrabled order. Solved by using sortable table.

    const ruhEvents: RUHReport[] = [];
    Object.values(month.days).forEach((events: RUHReport[]) => {
      events.forEach((e) => {
        ruhEvents.push(e);
      });
    });

    return ruhEvents;
  };

  const reportDataMonth =
    hmsCounterData.data?.ruh?.years?.[yearStringConverter(selectedYear)]
      ?.months?.[monthStringConverter(selectedMonth)];

  const tableData =
    reportDataMonth &&
    ruhEventsInMonth(reportDataMonth).map((el) => {
      return {
        ...el,
        delete: (
          <IconButton
            noShadow
            type="error"
            onClick={() => {
              const element = el as any; // reportId not in the API schema, but is in the object returned from backed
              setDeleteDialogOpen(true);
              setDeleteRuhReportId(element.reportId);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
      };
    });

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={<Text variant="body1">Se RUH rapportoversikt</Text>}
      >
        <IconButton
          type="info"
          onClick={() => {
            setOpen(true);
          }}
        >
          <ArticleIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        paperSx={{
          minWidth: "70%",
          borderRadius: 2,
          height: "70%",
        }}
        onClose={() => setOpen(false)}
        title="RUH Månedsrapport"
        rightHeaderComponent={
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              py: 1,
            }}
          >
            <Box
              sx={{
                px: 1,
              }}
            >
              <OptionsPicker
                label="År"
                optionsList={yearsListDropdown()}
                value={selectedYear}
                onChange={setSelectedYear}
              />
            </Box>
            <Box
              sx={{
                px: 1,
              }}
            >
              <OptionsPicker
                label="Periode"
                optionsList={{
                  "1": "Januar",
                  "2": "Februar",
                  "3": "Mars",
                  "4": "April",
                  "5": "Mai",
                  "6": "Juni",
                  "7": "Juli",
                  "8": "August",
                  "9": "September",
                  "10": "Oktober",
                  "11": "November",
                  "12": "Desember",
                }}
                onChange={setSelectedMonth}
                value={selectedMonth}
              />
            </Box>
          </Box>
        }
      >
        <Box style={{ height: "100%", width: "100%" }}>
          {tableData && tableData.length > 0 ? (
            <Box sx={{ py: 2, px: 1 }}>
              <Table
                headers={[
                  { fieldName: "ruhNr", name: "Nummer" },
                  { fieldName: "date", name: "Dato" },
                  { fieldName: "closed", name: "Sluttdato" },
                  { fieldName: "ruhType", name: "Type" },
                  { fieldName: "description", name: "Beskrivelse" },
                  { fieldName: "delete", name: "" },
                ]}
                data={tableData}
              />
            </Box>
          ) : (
            <CenterContainer>
              <Text variant="h5">Ingen data denne perioden</Text>
            </CenterContainer>
          )}
        </Box>
      </Dialog>
      {deleteDialogOpen && (
        <ConfirmDialog
          open={deleteDialogOpen}
          text="Er du sikker på at du vil slette denne raden?"
          acceptText="Ja, slett denne raden"
          cancelText="Avbryt"
          onAccept={() => {
            authorizedDeleteReportApi().then((api) => {
              api
                .deleteRuhReport({ ruhReportId: deleteRuhReportId })
                .then(() => {
                  setTimeout(() => {
                    refetch();
                  }, 2000);
                  setDeleteRuhReportId("");
                  setDeleteDialogOpen(false);
                });
            });
          }}
          onCancel={() => {
            setDeleteRuhReportId("");
            setDeleteDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
        />
      )}
    </React.Fragment>
  );
}
