/* eslint-disable no-constant-condition */
import React, { useRef, useState } from "react";
import Dropzone from "components/common/Dropzone";
import Box from "@mui/material/Box";
import DocumentsUploadList from "components/common/DocumentsUploadList";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import DocmentUploadTable from "components/common/DocumentUploadTable";
import UploadDocumentReport from "components/availability/UploadDocumentReport";

export default function AvailabilityFileUploadDialog({
  open,
  onClose,
  docType,
}: {
  open: boolean;
  onClose: () => void;
  docType: string;
}) {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [allFilesSelected, setAllFilesSelected] = useState<File[]>([]);
  const filesToUploadRef = useRef(filesToUpload); // needed to get updated state for clear file function, since its called from a setTimeout
  filesToUploadRef.current = filesToUpload;
  const [isUpload, setIsUpload] = useState(false);
  const [documentsIdList, setDocumentsIdList] = useState<string[]>([]);
  const clearFile = (fileToClear: File) => {
    const newFileArray = filesToUploadRef.current.filter(function (file) {
      return file.name !== fileToClear.name;
    });
    setFilesToUpload(newFileArray);
  };
  const [dialogBoxTitle, setDialogBoxTitle] = useState("Last opp dokumenter");
  return (
    <Dialog
      type="drawer"
      paperSx={{ minWidth: "40%", maxHeight: "80%" }}
      title={dialogBoxTitle}
      onClose={() => {
        setFilesToUpload([]);
        setDocumentsIdList([]);
        setAllFilesSelected([]);
        setIsUpload(false);
        onClose();
      }}
      open={open}
    >
      {!isUpload ? (
        <Box>
          <Box sx={{ height: 400, width: "100%", px: 4, pb: 4 }}>
            <Dropzone
              onDrop={(files) => {
                files.forEach((file) => {
                  allFilesSelected.push(file);
                  if (
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    "application/vnd.ms-excel.sheet.macroEnabled.12" ||
                    file.type === "text/csv"
                  ) {
                    filesToUpload.push(file);
                  }
                });
              }}
              onFilesSelected={(files) => {
                files.forEach((file) => {
                  allFilesSelected.push(file);
                  if (
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    "application/vnd.ms-excel.sheet.macroEnabled.12" ||
                    file.type === "text/csv"
                  ) {
                    filesToUpload.push(file);
                  }
                });
              }}
            />
          </Box>
          {allFilesSelected.length !== 0 && (
            <Box>
              <DocumentsUploadList
                listElements={allFilesSelected}
                onRemove={(element) => {
                  setAllFilesSelected(
                    allFilesSelected.filter((option) => {
                      return option !== element;
                    })
                  );
                  setFilesToUpload(
                    filesToUpload.filter((option) => {
                      return option !== element;
                    })
                  );
                }}
              />
              {filesToUpload.length !== 0 && (
                <Box
                  sx={{
                    px: 4,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ p: 2 }}>
                    <Button
                      type="error"
                      endIcon={<CancelIcon />}
                      onClick={() => {
                        setAllFilesSelected([]);
                        setFilesToUpload([]);
                        onClose();
                      }}
                    >
                      Avbryt
                    </Button>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Button
                      type="success"
                      endIcon={<SaveIcon />}
                      onClick={() => {
                        if (filesToUpload.length !== 0) {
                          setIsUpload(true);
                        }
                      }}
                    >
                      Last opp
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : filesToUpload.length === 0 && documentsIdList.length > 0 ? (
        <UploadDocumentReport
          documentIdList={documentsIdList}
          dialogBoxTitle={setDialogBoxTitle}
          close={() => {
            setDialogBoxTitle("Last opp dokumenter");
            setFilesToUpload([]);
            setDocumentsIdList([]);
            setAllFilesSelected([]);
            setIsUpload(false);
            onClose();
          }}
        />
      ) : (
        <DocmentUploadTable
          docType={docType}
          filesToUpload={filesToUpload}
          clearFile={clearFile}
          uploadedDocumentIdlist={setDocumentsIdList}
          setDialogBoxTitle={setDialogBoxTitle}
        />
      )}
    </Dialog>
  );
}
