import React, { useState } from "react";
import { format } from "date-fns";

import Divider from "@mui/material/Divider";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import VerticalSplit from "@smartinspection/ameta-frontend-components/layout/VerticalSplit";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";

import Box from "@mui/material/Box";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import { SectionCounter } from "services/availabilityApi/models";
import { yearsListDropdown } from "utils/yearsListDropDown";

const stringRound = (floatString: string) => {
  return Number(parseFloat(floatString).toFixed(2));
};

const PercentageElement = ({
  value,
  threshold,
  large,
}: {
  value?: number;
  threshold?: number;
  large?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          sx={{
            fontWeight: 600,
            color: value
              ? threshold && value < threshold
                ? theme.palette.error.main
                : theme.palette.success.main
              : undefined,
          }}
          variant={large ? "h3" : "h4"}
        >
          {value ? value + "%" : "-"}
        </Text>
      </Box>
    </Box>
  );
};

function QuarterlyOverview({
  sectionCounterData,
}: {
  sectionCounterData: SectionCounter;
}) {
  const theme = useTheme();

  const thresholdNumber = 99.0;

  const [selectedYear, setSelectedYear] = useState(format(Date.now(), "yyyy"));
  const [selectedQuarter, setSelectedQuarter] = useState(
    String(Math.ceil(Number(format(Date.now(), "MM")) / 3))
  );

  const totalThisQuarter = sectionCounterData.data?.deducted?.[selectedYear]
    ?.quarters?.[selectedQuarter]
    ? stringRound(
        sectionCounterData.data?.deducted?.[selectedYear].quarters[
          selectedQuarter
        ].availability
      )
    : undefined;

  const soFarThisYear = sectionCounterData.data?.deducted?.[selectedYear]
    ?.availability
    ? stringRound(
        sectionCounterData.data?.deducted?.[selectedYear].availability
      )
    : undefined;

  const sectionsData =
    sectionCounterData.data?.deducted?.[selectedYear]?.quarters?.[
      selectedQuarter
    ]?.sections;

  const sections: { [key: string]: { availability: string | undefined } } = {
    1: { availability: undefined },
    2: { availability: undefined },
    3: { availability: undefined },
    4: { availability: undefined },
    5: { availability: undefined },
    6: { availability: undefined },
  };

  sectionsData &&
    Object.entries(sectionsData).forEach((element) => {
      Object.entries(sections).forEach((sectionEl) => {
        if (element[0] === sectionEl[0]) {
          delete sections[element[0]];
        }
      });

      sections[element[0]] = element[1];
    });

  return (
    <Card
      type="flat"
      faIcon="chart-pie"
      bodySx={{ p: 0 }}
      title="Kvartalsoversikt"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <OptionsPicker
            label="År"
            optionsList={yearsListDropdown()}
            value={selectedYear}
            onChange={(value) => {
              setSelectedYear(value);
            }}
          />
          <OptionsPicker
            label="Kvartal"
            optionsList={{
              "1": "1. kvartal",
              "2": "2. kvartal",
              "3": "3. kvartal",
              "4": "4. kvartal",
            }}
            value={selectedQuarter}
            onChange={(value) => {
              setSelectedQuarter(value);
            }}
          />
        </Box>
      }
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          gap: 2,

          alignContent: "space-evenly",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "45%",
            display: "flex",
            flexDirection: "column",
            bgcolor: theme.palette.background.paper,
            borderRadius: 2,
            boxShadow: 2,
            p: 2,
          }}
        >
          <Text
            sx={{
              p: 1,
              width: "100%",
            }}
            variant="h6"
          >
            {`${selectedQuarter}. kvartal`}
          </Text>
          <Divider orientation="horizontal" />
          <PercentageElement
            large
            value={totalThisQuarter}
            threshold={thresholdNumber}
          />
        </Box>

        <Box
          sx={{
            width: "45%",
            display: "flex",
            flexDirection: "column",
            bgcolor: theme.palette.background.paper,
            borderRadius: 2,
            boxShadow: 2,
            p: 2,
          }}
        >
          <Text
            sx={{
              p: 1,
              width: "100%",
            }}
            variant="h6"
          >
            {`${
              Number(selectedYear) === new Date().getFullYear()
                ? "Hittil i"
                : "Sammenlagt i"
            }
              ${selectedYear}`}
          </Text>
          <Divider orientation="horizontal" />
          <PercentageElement
            large
            value={soFarThisYear}
            threshold={thresholdNumber}
          />
        </Box>

        {Object.entries(sections).map((section, index) => {
          const soFarThisYear = sectionCounterData.data?.deducted?.[
            selectedYear
          ]?.sectionAvailability?.[section[0]]
            ? stringRound(
                sectionCounterData.data?.deducted?.[selectedYear]
                  .sectionAvailability[section[0]].availability
              )
            : undefined;

          return (
            <Box
              key={index}
              sx={{
                bgcolor: theme.palette.background.paper,
                width: "45%",
                display: "flex",
                flexDirection: "column",
                borderRadius: 2,
                boxShadow: 2,
                p: 2,
              }}
            >
              <Text
                sx={{
                  p: 1,
                }}
                variant="h6"
              >{`Seksjon ${stringRound(section[0])}`}</Text>
              <Divider />
              <VerticalSplit
                divider
                left={
                  <CenterContainer>
                    <Text sx={{ pt: 1 }} variant="body1">
                      {`${selectedQuarter}. kvartal`}
                    </Text>
                    <PercentageElement
                      value={stringRound(section[1].availability as string)}
                      threshold={thresholdNumber}
                    />
                  </CenterContainer>
                }
                right={
                  <CenterContainer>
                    <Text sx={{ pt: 1 }} variant="body1">
                      {`${
                        Number(selectedYear) === new Date().getFullYear()
                          ? "Hittil i"
                          : "Sammenlagt i "
                      }
              ${selectedYear}`}
                    </Text>
                    <PercentageElement
                      value={stringRound(String(soFarThisYear))}
                      threshold={thresholdNumber}
                    />
                  </CenterContainer>
                }
              />
            </Box>
          );
        })}
      </Box>
    </Card>
  );
}

export default QuarterlyOverview;
