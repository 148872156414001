import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Box from "@mui/material/Box";

import NavBar from "components/NavBar";

import YmMap from "pages/YmMap";
import TrafficView from "pages/TrafficView";
import MainDash from "pages/MainDash";

import { useNavigate } from "react-router-dom";

import Settings from "pages/Settings";
import Availability from "pages/Availability";
import Security from "pages/Security";

import HMS from "pages/HMS";

import isUserLoggedIn from "utils/isUserLoggedIn";

export default function Main() {
  const user = isUserLoggedIn();

  const navigate = useNavigate();

  useEffect(() => {
    if (user === "nouser") {
      navigate("/");
    }
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
      <NavBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Routes>
          <Route path="/" element={<MainDash />} />
          <Route path="traffic" element={<TrafficView />} />
          <Route path="ym-map" element={<YmMap />} />
          <Route path="Availability" element={<Availability />} />
          <Route path="security" element={<Security />} />
          <Route path="hms" element={<HMS />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </Box>
    </Box>
  );
}
