/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YMPointDataLatestMeasurements,
    YMPointDataLatestMeasurementsFromJSON,
    YMPointDataLatestMeasurementsFromJSONTyped,
    YMPointDataLatestMeasurementsToJSON,
} from './';

/**
 * 
 * @export
 * @interface YMPointData
 */
export interface YMPointData {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof YMPointData
     */
    historicalDataRecord: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: YMPointDataLatestMeasurements; }}
     * @memberof YMPointData
     */
    latestMeasurements: { [key: string]: YMPointDataLatestMeasurements; };
}

export function YMPointDataFromJSON(json: any): YMPointData {
    return YMPointDataFromJSONTyped(json, false);
}

export function YMPointDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): YMPointData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'historicalDataRecord': json['historicalDataRecord'],
        'latestMeasurements': (mapValues(json['latestMeasurements'], YMPointDataLatestMeasurementsFromJSON)),
    };
}

export function YMPointDataToJSON(value?: YMPointData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'historicalDataRecord': value.historicalDataRecord,
        'latestMeasurements': (mapValues(value.latestMeasurements, YMPointDataLatestMeasurementsToJSON)),
    };
}


