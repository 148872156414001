import React from "react";
import { useSelector } from "react-redux";
import { TStore } from "redux/store";

import Box from "@mui/material/Box";

import TopView from "components/TopView";

import AvailabilityCard from "components/mainView/AvailabilityCard";
import EnvironmentCard from "components/mainView/environmentCard/EnvironmentCard";
import NandHValueCard from "components/mainView/NandHValueCard";
import RUHSJACard from "components/mainView/RUHSJACard";
import SecurityCard from "components/mainView/SecurityCard";
import { useQueryHmsCounter } from "services/hmsQueryHooks";

import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";

function MainDash() {
  const hmsCounterQuery = useQueryHmsCounter();

  const hmsCounterData = hmsCounterQuery.data;

  const globalState = useSelector((state: TStore) => state.global);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Dashboard" />
      <GridLayout
        onLayoutChange={(layout) => {}}
        editable={globalState.layoutEditable}
        layout={[
          {
            i: "1",
            h: 15,
            w: 4,
            x: 0,
            y: 0,
          },
          {
            i: "2",
            h: 15,
            w: 8,
            x: 6,
            y: 0,
          },
          {
            i: "3",
            h: 15,
            w: 5,
            x: 0,
            y: 15,
          },
          {
            i: "4",
            h: 15,
            w: 4,
            x: 5,
            y: 15,
          },
          {
            i: "5",
            h: 15,
            w: 3,
            x: 10,
            y: 15,
          },
        ]}
      >
        <AvailabilityCard key="1" />
        <EnvironmentCard key="2" />
        <NandHValueCard key="3" hmsCounterData={hmsCounterData} />
        <RUHSJACard key="4" hmsCounterData={hmsCounterData} />
        <SecurityCard key="5" />
      </GridLayout>
    </Box>
  );
}

export default MainDash;
