/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface YMPointDataLatestMeasurements
 */
export interface YMPointDataLatestMeasurements {
    /**
     * 
     * @type {string}
     * @memberof YMPointDataLatestMeasurements
     */
    measurementIndication: string;
    /**
     * 
     * @type {string}
     * @memberof YMPointDataLatestMeasurements
     */
    unit: string;
    /**
     * 
     * @type {string}
     * @memberof YMPointDataLatestMeasurements
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof YMPointDataLatestMeasurements
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof YMPointDataLatestMeasurements
     */
    referenceId: string;
}

export function YMPointDataLatestMeasurementsFromJSON(json: any): YMPointDataLatestMeasurements {
    return YMPointDataLatestMeasurementsFromJSONTyped(json, false);
}

export function YMPointDataLatestMeasurementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): YMPointDataLatestMeasurements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurementIndication': json['measurementIndication'],
        'unit': json['unit'],
        'value': json['value'],
        'date': json['date'],
        'referenceId': json['referenceId'],
    };
}

export function YMPointDataLatestMeasurementsToJSON(value?: YMPointDataLatestMeasurements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'measurementIndication': value.measurementIndication,
        'unit': value.unit,
        'value': value.value,
        'date': value.date,
        'referenceId': value.referenceId,
    };
}


