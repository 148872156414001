/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YMPointsResponseLastEvaluatedKey,
    YMPointsResponseLastEvaluatedKeyFromJSON,
    YMPointsResponseLastEvaluatedKeyFromJSONTyped,
    YMPointsResponseLastEvaluatedKeyToJSON,
    YmPoint,
    YmPointFromJSON,
    YmPointFromJSONTyped,
    YmPointToJSON,
} from './';

/**
 * 
 * @export
 * @interface YMPointsResponse
 */
export interface YMPointsResponse {
    /**
     * 
     * @type {Array<YmPoint>}
     * @memberof YMPointsResponse
     */
    objects: Array<YmPoint>;
    /**
     * 
     * @type {YMPointsResponseLastEvaluatedKey}
     * @memberof YMPointsResponse
     */
    lastEvaluatedKey?: YMPointsResponseLastEvaluatedKey;
}

export function YMPointsResponseFromJSON(json: any): YMPointsResponse {
    return YMPointsResponseFromJSONTyped(json, false);
}

export function YMPointsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): YMPointsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objects': ((json['objects'] as Array<any>).map(YmPointFromJSON)),
        'lastEvaluatedKey': !exists(json, 'lastEvaluatedKey') ? undefined : YMPointsResponseLastEvaluatedKeyFromJSON(json['lastEvaluatedKey']),
    };
}

export function YMPointsResponseToJSON(value?: YMPointsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objects': ((value.objects as Array<any>).map(YmPointToJSON)),
        'lastEvaluatedKey': YMPointsResponseLastEvaluatedKeyToJSON(value.lastEvaluatedKey),
    };
}


