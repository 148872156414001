import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalculator,
  faCalendar,
  faChartBar,
  faChartPie,
  faNotesMedical,
  faWind,
  faRoad,
  faHardHat,
  faThLarge,
  faTrafficLight,
  faListAlt,
  faUser,
  faBriefcase,
  faShareAlt,
  faTrash,
  faFileAlt,
  faEdit,
  faPlusCircle,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCalculator,
  faCalendar,
  faChartBar,
  faChartPie,
  faNotesMedical,
  faWind,
  faRoad,
  faHardHat,
  faThLarge,
  faTrafficLight,
  faListAlt,
  faUser,
  faBriefcase,
  faShareAlt,
  faTrash,
  faFileAlt,
  faEdit,
  faPlusCircle,
  faExternalLinkAlt
);
