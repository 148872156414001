/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentReportParsingReport,
    DocumentReportParsingReportFromJSON,
    DocumentReportParsingReportFromJSONTyped,
    DocumentReportParsingReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentReport
 */
export interface DocumentReport {
    /**
     * 
     * @type {string}
     * @memberof DocumentReport
     */
    documentName: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentReport
     */
    createdAt: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentReport
     */
    status: string;
    /**
     * 
     * @type {DocumentReportParsingReport}
     * @memberof DocumentReport
     */
    parsingReport?: DocumentReportParsingReport;
}

export function DocumentReportFromJSON(json: any): DocumentReport {
    return DocumentReportFromJSONTyped(json, false);
}

export function DocumentReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentName': json['documentName'],
        'createdAt': json['createdAt'],
        'status': json['status'],
        'parsingReport': !exists(json, 'parsingReport') ? undefined : DocumentReportParsingReportFromJSON(json['parsingReport']),
    };
}

export function DocumentReportToJSON(value?: DocumentReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentName': value.documentName,
        'createdAt': value.createdAt,
        'status': value.status,
        'parsingReport': DocumentReportParsingReportToJSON(value.parsingReport),
    };
}


