import React from "react";

import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";

import { authorizedYmApi } from "services/apiService";
import { YmPoint } from "services/ymApi/models/YmPoint";

function DeleteYmPointDialog({
  open,
  onClose,
  ymPointToDelete,
}: {
  open: boolean;
  onClose: () => void;
  ymPointToDelete: YmPoint;
}) {
  return (
    <ConfirmDialog
      open={open}
      text="Er du sikker på du vil slette målepunktet?"
      acceptText="Ja, slett"
      cancelText="Avbryt"
      onCancel={onClose}
      onAccept={() => {
        authorizedYmApi().then((api) => {
          api.deleteYmPoint({ pointId: ymPointToDelete.id });
        });
        onClose();
      }}
    />
  );
}

export default DeleteYmPointDialog;
