import React from "react";

import Divider from "@mui/material/Divider";

import TableChartIcon from "@mui/icons-material/TableChart";
import TimelineIcon from "@mui/icons-material/Timeline";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import BarIndicatior from "components/ym/bottomDrawer/BarIndicatior";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";

import Box from "@mui/material/Box";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import Card from "@smartinspection/ameta-frontend-components/display/Card";

export default function MeasurementCard({
  type,
  value,
  threshold,
  barChartDomain,
  onHistoryButtonClicked,
  onClickEdit,
  date,
}: {
  type: string;
  value: number;
  threshold?: number;
  barChartDomain?: number[];
  onHistoryButtonClicked: (selectedtype: string) => void;
  onClickEdit: (selectedtype: string) => void;
  date: string;
}) {
  const theme = useTheme();

  let barColor = theme.palette.ameta.lightBlueGray;

  if (threshold && value > threshold) {
    barColor = theme.palette.error.main;
  } else if (threshold && value >= 0.95 * threshold) {
    barColor = theme.palette.warning.main;
  } else if (threshold && value < threshold) {
    barColor = theme.palette.success.main;
  }

  return (
    <Box sx={{ height: 250, width: "100%", pb: 2 }}>
      <Card
        faIcon="chart-bar"
        type="default"
        title={type}
        rightHeaderComponent={
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Text
              sx={{ pr: 1 }}
              variant="subtitle1"
            >{`Sist oppdatert: ${date}`}</Text>
            <Tooltip
              placement="left"
              tooltipContent={
                <Text variant="body1">Se historisk data i listeform</Text>
              }
            >
              <IconButton
                sx={{ mr: 1 }}
                type="info"
                onClick={() => {
                  onClickEdit(type);
                }}
              >
                <TableChartIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="left"
              tooltipContent={<Text variant="body1">Se historisk data</Text>}
            >
              <IconButton
                sx={{ mr: 1 }}
                type="info"
                onClick={() => {
                  onHistoryButtonClicked(type);
                }}
              >
                <TimelineIcon />
              </IconButton>
            </Tooltip>
          </Box>
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text sx={{ fontWeight: 600 }} variant="body1">
                Status
              </Text>
              <Box
                sx={{
                  height: 15,
                  width: 15,
                  borderRadius: "50%",
                  bgcolor: barColor,
                }}
              />
            </Box>
            <Divider sx={{ height: 40 }} orientation="vertical" />
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text sx={{ fontWeight: 600 }} variant="body1">
                Terskelverdi
              </Text>
              <Text variant="body1">{threshold ? threshold : "ingen"}</Text>
            </Box>
            <Divider sx={{ height: 40 }} orientation="vertical" />
            <Box
              sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text sx={{ fontWeight: 600 }} variant="body1">
                Verdi
              </Text>
              <Text sx={{ fontWeight: 600 }} variant="body1">
                {value}
              </Text>
            </Box>
          </Box>
          <Box sx={{ pt: 3, height: 100, width: "100%" }}>
            <BarIndicatior
              color={barColor}
              domain={barChartDomain}
              value={value}
              threshold={threshold}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
