import React from "react";
import { useAvailabilityDocumentReportQuery } from "services/availabilityQueryHooks";
import Box from "@mui/material/Box";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import HorizontalSplit from "@smartinspection/ameta-frontend-components/layout/HorizontalSplit";
import Divider from "@mui/material/Divider";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RotateRightIcon from "@mui/icons-material/RotateRight";
function UploadDocumentReport({
  documentIdList,
  dialogBoxTitle,
  close,
}: {
  documentIdList: string[];
  dialogBoxTitle: (title: string) => void;
  close: () => void;
}) {
  const documentReport =
    useAvailabilityDocumentReportQuery(documentIdList).data;
  let noDivider: number = -1;
  return (
    <Box sx={{ height: "100%" }}>
      {Object.keys(documentReport).length > 0 ? (
        Object.values(documentReport).map((report, index) => {
          dialogBoxTitle("Resultater");
          const dateTime = new Date(report.createdAt * 1000);
          noDivider = noDivider + 1;
          return (
            <Box sx={{ p: 1 }} key={index}>
              {noDivider > 0 && <Divider />}
              <HorizontalSplit
                top={
                  <Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text variant="body1" sx={{ fontWeight: "bold" }}>
                        Documentnavn:
                      </Text>
                      <IconButton type="info" onClick={() => {}}>
                        {report.status === "completed" ? (
                          <CheckCircleIcon sx={{ color: "#5cb85c" }} />
                        ) : report.status === "processing" ? (
                          <RotateRightIcon sx={{ color: "#0275d8" }} />
                        ) : report.status === "initial" ? (
                          <PlayArrowIcon sx={{ color: "#0275d8" }} />
                        ) : (
                          <ErrorIcon sx={{ color: "#d9534f" }} />
                        )}
                      </IconButton>
                    </Box>
                    <Text variant="body1">{report.documentName}</Text>
                    <Text variant="body1" sx={{ fontWeight: "bold" }}>
                      Opprettet:
                    </Text>
                    <Text variant="body1">
                      {dateTime.toLocaleString("no-NO")}
                    </Text>
                  </Box>
                }
                bottom={
                  <Box>
                    <Text variant="body1" sx={{ fontWeight: "bold" }}>
                      Status:
                    </Text>
                    <Text variant="body1">
                      {report.status === "completed with errors"
                        ? "Fullført med feil"
                        : report.status === "completed"
                        ? "Fullført"
                        : report.status === "processing"
                        ? "Prosesserer"
                        : report.status === "initial"
                        ? "Stater for prosesserer"
                        : "Mislyktes"}
                    </Text>
                    {report?.parsingReport?.numberOfRecordsAdded && (
                      <Text variant="body1" sx={{ fontWeight: "bold" }}>
                        Resultat:
                      </Text>
                    )}
                    <Box>
                      {report?.parsingReport?.numberOfRecordsAdded &&
                        report.parsingReport?.numberOfRecordsAdded +
                          " rader er lagt til."}
                    </Box>
                    {report?.parsingReport?.errors && (
                      <Text variant="body1" sx={{ fontWeight: "bold" }}>
                        Feilmeldinger:
                      </Text>
                    )}
                    {report?.parsingReport?.errors?.unknownfieldName &&
                      "Det er ukjente kolonnenavn i filen. Du kan fikse filen og laste opp på nytt."}
                    {report?.parsingReport?.errors?.unknownfileExtension &&
                      "Filtypen støttes ikke av systemet."}
                  </Box>
                }
              />
            </Box>
          );
        })
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {dialogBoxTitle("Legg til ny rapport")}
          <Box>
            <CircularProgress size={150} />
            <Text variant="h5" sx={{ fontWeight: "bold" }}>
              Prosesserer...
            </Text>
          </Box>
        </Box>
      )}
      {Object.keys(documentReport).length > 0 && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button type="error" endIcon={<CancelIcon />} onClick={close}>
            Lukk
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default UploadDocumentReport;
