/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewYmPointLocation,
    NewYmPointLocationFromJSON,
    NewYmPointLocationFromJSONTyped,
    NewYmPointLocationToJSON,
    YMPointData,
    YMPointDataFromJSON,
    YMPointDataFromJSONTyped,
    YMPointDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateYmPoint
 */
export interface UpdateYmPoint {
    /**
     * 
     * @type {string}
     * @memberof UpdateYmPoint
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYmPoint
     */
    place?: string;
    /**
     * 
     * @type {NewYmPointLocation}
     * @memberof UpdateYmPoint
     */
    location: NewYmPointLocation;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateYmPoint
     */
    thresholdValues?: { [key: string]: string; };
    /**
     * 
     * @type {YMPointData}
     * @memberof UpdateYmPoint
     */
    data?: YMPointData;
    /**
     * 
     * @type {string}
     * @memberof UpdateYmPoint
     */
    measurementPointId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYmPoint
     */
    waterSourceId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYmPoint
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYmPoint
     */
    geohash: string;
}

export function UpdateYmPointFromJSON(json: any): UpdateYmPoint {
    return UpdateYmPointFromJSONTyped(json, false);
}

export function UpdateYmPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateYmPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'location': NewYmPointLocationFromJSON(json['location']),
        'thresholdValues': !exists(json, 'thresholdValues') ? undefined : json['thresholdValues'],
        'data': !exists(json, 'data') ? undefined : YMPointDataFromJSON(json['data']),
        'measurementPointId': json['measurementPointId'],
        'waterSourceId': json['waterSourceId'],
        'projectId': json['projectId'],
        'geohash': json['geohash'],
    };
}

export function UpdateYmPointToJSON(value?: UpdateYmPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comments': value.comments,
        'place': value.place,
        'location': NewYmPointLocationToJSON(value.location),
        'thresholdValues': value.thresholdValues,
        'data': YMPointDataToJSON(value.data),
        'measurementPointId': value.measurementPointId,
        'waterSourceId': value.waterSourceId,
        'projectId': value.projectId,
        'geohash': value.geohash,
    };
}


