import React from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Box from "@mui/material/Box";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Card from "@smartinspection/ameta-frontend-components/display/Card";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import { useQuerySecurityCounter } from "services/securityQueryHooks";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

import VerticalSplit from "@smartinspection/ameta-frontend-components/layout/VerticalSplit";

function SecurityCard() {
  const theme = useTheme();
  const navigate = useNavigate();

  const securityCounterQuery = useQuerySecurityCounter();
  const securityCounterData = securityCounterQuery.data;

  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const currentDate = new Date();
  const reportYear =
    securityCounterData?.data?.years?.[
      yearStringConverter(format(currentDate, "yyyy"))
    ];

  const fatal = reportYear?.fatalAccidents ? reportYear?.fatalAccidents : 0;
  let dateArray: Date[] = [];
  reportYear &&
    Object.entries(reportYear?.months).forEach((months) => {
      Object.entries(months[1]?.days).forEach((days) => {
        Object.entries(days[1]).forEach((day) => {
          day.forEach((item) => {
            if (
              (item["accidentType"] === "MAS" ||
                item["accidentType"] === "AS") &&
              item["date"]
            ) {
              const dateComponents = item["date"].split(" ");
              const datePieces = dateComponents[0].split(".");
              const timePieces = dateComponents[1].split(":");
              const dateFormat = new Date(
                datePieces[2],
                datePieces[1] - 1,
                datePieces[0],
                timePieces[0],
                timePieces[1]
              );
              dateArray.push(dateFormat);
            }
          });
        });
      });
    });

  const sortedDataByDate = dateArray.sort((a, b) => b.getTime() - a.getTime());
  const daysBetweenDates: string | number =
    sortedDataByDate.length > 0
      ? Math.floor(
          (new Date(currentDate).getTime() -
            new Date(sortedDataByDate[0]).getTime()) /
            (1000 * 60 * 60 * 24)
        )
      : "-";

  return (
    <Card
      type="drawer"
      title="Sikkerhet"
      faIcon="hard-hat"
      subtitle={`Ulykker så langt i ${format(currentDate, "yyyy")}`}
      rightHeaderComponent={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pr: 1,
            pt: 1.5,
          }}
        >
          <IconButton
            transparentBackground
            type="success"
            onClick={() => {
              navigate("/dashboard/security");
            }}
          >
            <FontAwesomeIcon icon="external-link-alt" size="lg" />
          </IconButton>
        </Box>
      }
    >
      <VerticalSplit
        divider
        left={
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text variant="h6" sx={{ fontWeight: 600 }}>
                Drepte
              </Text>
              <Text variant="body1">( antall )</Text>
            </Box>
            <Text
              sx={{
                fontWeight: 600,
                color:
                  fatal === 0
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
              variant="h3"
            >
              {fatal}
            </Text>
            <Text variant="body1">{"i " + format(currentDate, "yyyy")}</Text>
          </Box>
        }
        right={
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text variant="h6" sx={{ fontWeight: 600 }}>
                Hardt skadde
              </Text>
              <Text variant="body1">( dager siden sist )</Text>
            </Box>
            <Text sx={{ fontWeight: 600 }} variant="h3">
              {daysBetweenDates}
            </Text>
            <Text variant="body1">{"i " + format(currentDate, "yyyy")}</Text>
          </Box>
        }
      />
    </Card>
  );
}

export default SecurityCard;
