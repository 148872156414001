/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DocumentReport,
    DocumentReportFromJSON,
    DocumentReportToJSON,
    NewDocument,
    NewDocumentFromJSON,
    NewDocumentToJSON,
    UploadDocumentObject,
    UploadDocumentObjectFromJSON,
    UploadDocumentObjectToJSON,
} from '../models';

export interface CreateHmsDocumentURLRequest {
    newDocument: NewDocument;
}

export interface GetDocumentReportRequest {
    documentId: string;
}

/**
 * 
 */
export class HmsDocumentApi extends runtime.BaseAPI {

    /**
     * Create a new hms document signed url
     */
    async createHmsDocumentURLRaw(requestParameters: CreateHmsDocumentURLRequest): Promise<runtime.ApiResponse<UploadDocumentObject>> {
        if (requestParameters.newDocument === null || requestParameters.newDocument === undefined) {
            throw new runtime.RequiredError('newDocument','Required parameter requestParameters.newDocument was null or undefined when calling createHmsDocumentURL.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewDocumentToJSON(requestParameters.newDocument),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadDocumentObjectFromJSON(jsonValue));
    }

    /**
     * Create a new hms document signed url
     */
    async createHmsDocumentURL(requestParameters: CreateHmsDocumentURLRequest): Promise<UploadDocumentObject> {
        const response = await this.createHmsDocumentURLRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a hms document report by document id
     */
    async getDocumentReportRaw(requestParameters: GetDocumentReportRequest): Promise<runtime.ApiResponse<DocumentReport>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/document/{documentId}/report`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentReportFromJSON(jsonValue));
    }

    /**
     * Get a hms document report by document id
     */
    async getDocumentReport(requestParameters: GetDocumentReportRequest): Promise<DocumentReport> {
        const response = await this.getDocumentReportRaw(requestParameters);
        return await response.value();
    }

}
