import React, { useState } from "react";
import format from "date-fns/format";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { parse } from "date-fns";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";
import TimePicker from "@smartinspection/ameta-frontend-components/pickers/TimePicker";

import { authorizedAvailabilityApi } from "services/apiService";
import { ReportObject } from "services/availabilityApi";

export default function AddEventDialog({
  refetch,
  edit,
  open,
  setOpen,
  editObject,
  onClose,
}: {
  refetch: () => void;
  edit?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editObject?: ReportObject;
  onClose?: () => void;
}) {
  const dateParser = (dateString: string) => {
    return parse(dateString, "yyyy.MM.dd HH:mm", new Date());
  };

  const initDate = new Date();
  initDate.setSeconds(0, 0);

  const [closedDate, setClosedDate] = useState(
    editObject?.closedDate
      ? dateParser(editObject.closedDate)
      : new Date(initDate)
  );
  const [openedDate, setOpenedDate] = useState(
    editObject?.openedDate
      ? dateParser(editObject.openedDate)
      : new Date(initDate)
  );

  const timeDiffMs = openedDate.getTime() - closedDate.getTime();

  const hours = Math.floor(timeDiffMs / (1000 * 3600));
  const remainingMinutes = Math.round(
    (timeDiffMs - hours * 1000 * 3600) / (1000 * 60)
  );

  const totalTime =
    hours === 0
      ? Math.floor(timeDiffMs / (1000 * 60))
        ? Math.floor(timeDiffMs / (1000 * 60)) + " minutter"
        : ""
      : remainingMinutes
      ? hours + " timer og " + remainingMinutes + " minutter"
      : hours + " timer";

  const totalMinutes = Math.floor(timeDiffMs / (1000 * 60));

  const [workType, setWorkType] = useState(
    editObject?.workType ? editObject.workType : "Tunnelvask"
  );
  const [direction, setDirection] = useState(
    editObject?.direction ? editObject?.direction : "1+0"
  );
  const [section, setSection] = useState(
    editObject?.section ? editObject.section : "1"
  );
  const [isDeducted, setIsDeducted] = useState(
    editObject?.isDeducted ? editObject.isDeducted : "ja"
  );
  const closeAndResetStates = () => {
    setOpen(false);
    setClosedDate(new Date(initDate));
    setOpenedDate(new Date(initDate));
    setWorkType("Tunnelvask");
    setDirection("1+0");
    setSection("1");
    setIsDeducted("ja");
    onClose && onClose();
  };
  return (
    <Dialog
      type="drawer"
      open={open}
      onClose={closeAndResetStates}
      title={edit ? "Rediger hendelse" : "Legg til ny hendelse"}
      dialogActions={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {edit && editObject?.sort ? (
            <Button
              type="success"
              colored
              onClick={() => {
                authorizedAvailabilityApi().then((api) => {
                  api
                    .updateEvent({
                      event: {
                        closedDate: format(closedDate, "yyyy.MM.dd HH:mm"),
                        openedDate: format(openedDate, "yyyy.MM.dd HH:mm"),
                        workType: workType,
                        direction: direction,
                        section: section,
                        isDeducted: isDeducted,
                      },
                      eventId: editObject.sort,
                    })
                    .then(() => {
                      refetch();
                    });
                });
                closeAndResetStates();
              }}
            >
              Rediger hendelse
            </Button>
          ) : (
            <Button
              type="success"
              colored
              onClick={() => {
                authorizedAvailabilityApi().then((api) => {
                  api
                    .createEvent({
                      event: {
                        closedDate: format(closedDate, "yyyy.MM.dd HH:mm"),
                        openedDate: format(openedDate, "yyyy.MM.dd HH:mm"),
                        workType: workType,
                        direction: direction,
                        section: section,
                        isDeducted: isDeducted,
                      },
                    })
                    .then(() => {
                      refetch();
                    });
                });
                closeAndResetStates();
              }}
            >
              Opprett hendelse
            </Button>
          )}
        </Box>
      }
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Box sx={{ height: "100%", p: 2, display: "flex" }}>
          <Box sx={{ width: "100%", pb: 2 }}>
            <Text sx={{ p: 2 }} variant="h6">
              Stengingstidspunkt:
            </Text>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <DatePicker
                label="Dato"
                value={closedDate}
                onChange={(date) => {
                  setClosedDate(
                    new Date(
                      closedDate.setFullYear(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      )
                    )
                  );
                }}
              />

              <TimePicker
                label="Tidspunkt"
                value={closedDate}
                onChange={(date) => {
                  setClosedDate(new Date(closedDate.setTime(date.getTime())));
                }}
              />
            </div>
          </Box>
          <Divider sx={{ mx: 2 }} orientation="vertical" />
          <Box sx={{ width: "100%", pb: 2 }}>
            <Text sx={{ p: 2 }} variant="h6">
              Åpningstidspunkt:
            </Text>
            <Box
              sx={{
                display: "flex",

                justifyContent: "space-evenly",
              }}
            >
              <DatePicker
                label="Dato"
                value={openedDate}
                onChange={(date) => {
                  setOpenedDate(
                    new Date(
                      openedDate.setFullYear(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      )
                    )
                  );
                }}
              />

              <TimePicker
                label="Tidspunkt"
                value={openedDate}
                onChange={(date) => {
                  setOpenedDate(new Date(openedDate.setTime(date.getTime())));
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider style={{ width: "100%" }} orientation="horizontal" light />
        <Box sx={{ height: "100%", p: 2 }}>
          <Text sx={{ p: 2 }} variant="h6">
            Totalt:
          </Text>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ p: 2 }}>
              <Text variant="body1">Total tid:</Text>
              <Text align="center" variant="body1">
                {totalTime ? totalTime : "-"}
              </Text>
            </Box>
            <Box sx={{ p: 2 }}>
              <Text variant="body1">Antall minutter:</Text>
              <Text align="center" variant="body1">
                {totalMinutes ? totalMinutes : "-"}
              </Text>
            </Box>
          </Box>
        </Box>
        <Divider style={{ width: "100%" }} orientation="horizontal" light />
        <Box
          sx={{
            height: "100%",
            p: 2,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <OptionsPicker
            label="Velg type arbeid"
            value={workType}
            optionsList={{
              Tunnelvask: "Tunnelvask",
              "Øvrig drift": "Øvrig drift",
              Asfaltering: "Asfaltering",
              "Øvrig tungt vedlikehold": "Øvrig tungt vedlikehold",
              "Trafikkuhell/ skader": "Trafikkuhell/ skader",
            }}
            onChange={setWorkType}
          />

          <OptionsPicker
            label="Velg kjørefelt"
            optionsList={{
              "1+0": "1+0",
              "1+1": "1+1",
              "2+0": "2+0",
              "2+1": "2+1",
              "2+2": "2+2",
            }}
            value={direction}
            onChange={setDirection}
          />

          <OptionsPicker
            label="Velg seksjon"
            optionsList={{
              "1": "1",
              "2": "2",
              "3": "3",
              "4": "4",
              "5": "5",
              "6": "6",
            }}
            value={section}
            onChange={setSection}
          />

          <OptionsPicker
            label="Velg trekk"
            optionsList={{
              ja: "ja",
              nei: "nei",
            }}
            value={isDeducted}
            onChange={setIsDeducted}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
