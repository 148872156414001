/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateYmMeasurement
 */
export interface UpdateYmMeasurement {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UpdateYmMeasurement
     */
    data: { [key: string]: object; };
}

export function UpdateYmMeasurementFromJSON(json: any): UpdateYmMeasurement {
    return UpdateYmMeasurementFromJSONTyped(json, false);
}

export function UpdateYmMeasurementFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateYmMeasurement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

export function UpdateYmMeasurementToJSON(value?: UpdateYmMeasurement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
    };
}


