import React, { useState } from "react";
import { format } from "date-fns";

import Box from "@mui/material/Box";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import LineChart from "@smartinspection/ameta-frontend-components/charts/LineChart";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";
import {
  generateStaticWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import { sensorIdNameMap } from "config/sensorIdNameMap";

import { useQueryTrafficDataBySensorIdAndYearMonth } from "services/trafficQueryHooks";

function PassingsPerHour() {
  const [sensorId, setSensorId] = useState("54653V1751052");
  const [date, setDate] = useState(new Date(Date.now() - 86400000)); // yesterday
  const [direction, setDirection] = useState("with");

  const theme = useTheme();

  const trafficQuery = useQueryTrafficDataBySensorIdAndYearMonth(
    sensorId,
    format(date, "yyyy-MM")
  );
  const trafficData = trafficQuery.data;

  const passingsOnSelectedDate = trafficData
    ? trafficData.find((dayWithData) => {
        return dayWithData.sort === format(date, "yyyy-MM-dd");
      })
    : undefined;

  const passingsOnDayBefore = trafficData
    ? trafficData.find((dayWithData) => {
        return (
          dayWithData.sort ===
          format(
            new Date(date.getTime()).setDate(date.getDate() - 1),
            "yyyy-MM-dd"
          )
        );
      })
    : undefined;

  const timeSortedPassings: {
    [key: string]: {
      [key: string]: number;
    };
  } = {};

  passingsOnSelectedDate?.data.forEach((data) => {
    if (timeSortedPassings[Date.parse(data.til)]) {
      timeSortedPassings[Date.parse(data.til)][data.felt] = Number(data.volum);
    } else {
      timeSortedPassings[Date.parse(data.til)] = {
        [data.felt]: Number(data.volum),
      };
    }
  });

  // getting 23:00-00:00 data from the day before
  passingsOnDayBefore?.data.forEach((data) => {
    if (new Date(data.til).getHours() === 0) {
      if (timeSortedPassings[Date.parse(data.til)]) {
        timeSortedPassings[Date.parse(data.til)][data.felt] = Number(
          data.volum
        );
      } else {
        timeSortedPassings[Date.parse(data.til)] = {
          [data.felt]: Number(data.volum),
        };
      }
    }
  });

  const chartDataArray: { time: number; [key: string]: number }[] =
    Object.entries(timeSortedPassings).map((entry) => {
      return { time: Number(entry[0]), ...entry[1] };
    });

  let cardContent: React.ReactNode = null;

  if (trafficQuery.isLoading) {
    cardContent = (
      <CenterContainer>
        <CircularProgress size={150} />
      </CenterContainer>
    );
  }

  if (passingsOnSelectedDate) {
    const startTime = new Date(date.getTime()).setHours(0, 0, 0, 0);

    const endTime = new Date(
      new Date(startTime).setDate(new Date(startTime).getDate() + 1)
    ).getTime();

    cardContent = (
      <LineChart
        yAxisLabel="Antall passeringer"
        margin={{ left: 30, top: 10, right: 50, bottom: 30 }}
        data={chartDataArray}
        dataProps={[
          {
            fieldName: "lane_1",
            name: "Felt 1",
            color: theme.palette.info.main,
          },
          {
            fieldName: "lane_3",
            name: "Felt 3",
            color: theme.palette.ameta.lightGreen,
          },
        ]}
        timeWindow={generateStaticWindow(Step.Hour, startTime, endTime, 2)}
        stackGraphs
      />
    );
  } else {
    cardContent = (
      <CenterContainer>
        <Text variant="h5">
          {`Ingen data for ${sensorIdNameMap[sensorId]} ${format(
            date,
            "dd.MM.yyyy"
          )}`}
        </Text>
      </CenterContainer>
    );
  }

  return (
    <Card
      faIcon="traffic-light"
      title="Passeringer"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <OptionsPicker
            label="Sensor"
            value={sensorId}
            optionsList={sensorIdNameMap}
            onChange={(sensorId) => {
              setSensorId(sensorId);
            }}
          />

          <OptionsPicker
            label="Retning"
            value={direction}
            optionsList={{
              with: "I metreringsretning",
              against: "Mot metreringsretning",
            }}
            onChange={(direction) => {
              setDirection(direction);
            }}
          />

          <DatePicker
            label="Dato"
            value={date}
            onChange={(date) => {
              setDate(date);
            }}
          />
        </Box>
      }
    >
      {cardContent}
    </Card>
  );
}

export default PassingsPerHour;
