import React, { useState } from "react";
import { format } from "date-fns";

import Box from "@mui/material/Box";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Table from "@smartinspection/ameta-frontend-components/display/Table";
import VerticalSplit from "@smartinspection/ameta-frontend-components/layout/VerticalSplit";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";

import { SecurityCounter } from "services/securityApi";

import { yearsListDropdown } from "utils/yearsListDropDown";

import secuityNumbersYearMonthMapper, {
  MonthOverviewData,
} from "components/security/securityNumbersYearMonthMapper";

function MonthlyOverview({
  securityCounterData,
}: {
  securityCounterData: SecurityCounter;
}) {
  const monthStringConverter = (month: string) => {
    const paddingString = "m";
    return paddingString.concat(month);
  };
  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
  const initialMonth = monthStringConverter(format(previousMonthDate, "M"));

  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, "yyyy")
  );

  const accidentsWithInjuriesMapping = {
    LS: "Lettere skadet",
    AS: "Alvorlig skadet",
    MAS: "Meget alvorlig skadet",
    DR: "Drepte",
  };
  const accidentsWithoutInjuriesMapping = {
    PR: "Skadet vegobjekt",
    AU: "Andre ulykker uten personskade",
  };

  const reportYear =
    securityCounterData?.data?.years?.[yearStringConverter(selectedYear)];

  const OverviewCard = ({
    title,
    overviewData,
  }: {
    title: string;
    overviewData: MonthOverviewData[];
  }) => {
    const tableData: { [key: string]: React.ReactNode }[] = [];

    overviewData.forEach((el) => {
      tableData.push({ ...el });
    });

    return (
      <Box sx={{ px: 2, height: "100%" }}>
        <Card type="flat" title={title}>
          <Table
            compact
            headers={[
              { fieldName: "id", name: "" },
              { fieldName: "monthlyOverview", name: "Månedsoverskit" },
              {
                fieldName: "soFarSelectedYear",
                name: selectedYear + " oppsumert",
              },
            ]}
            data={tableData}
          />
        </Card>
      </Box>
    );
  };

  return (
    <Card
      faIcon="chart-bar"
      title="Månedsoversikt"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="Periode"
              optionsList={{
                m1: "Januar",
                m2: "Februar",
                m3: "Mars",
                m4: "April",
                m5: "Mai",
                m6: "Juni",
                m7: "Juli",
                m8: "August",
                m9: "September",
                m10: "Oktober",
                m11: "November",
                m12: "Desember",
              }}
              value={selectedMonth}
              onChange={(value) => {
                setSelectedMonth(value);
              }}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="År"
              optionsList={yearsListDropdown()}
              value={selectedYear}
              onChange={(value) => {
                setSelectedYear(value);
              }}
            />
          </Box>
        </Box>
      }
    >
      <VerticalSplit
        divider
        left={
          <OverviewCard
            title="Ulykker med personskade"
            overviewData={secuityNumbersYearMonthMapper(
              reportYear,
              selectedMonth,
              accidentsWithInjuriesMapping
            )}
          />
        }
        right={
          <OverviewCard
            title="Ulykker uten personskade"
            overviewData={secuityNumbersYearMonthMapper(
              reportYear,
              selectedMonth,
              accidentsWithoutInjuriesMapping
            )}
          />
        }
      />
    </Card>
  );
}

export default MonthlyOverview;
