import React from "react";

import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import { YmPoint } from "services/ymApi/models";

import LineChart from "@smartinspection/ameta-frontend-components/charts/LineChart";
import {
  generateFloatingWindow,
  Step,
} from "@smartinspection/ameta-frontend-components/utils/chartUtils";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

export default function HistoricalDataDialog({
  measurementType,
  ymPoint,
  open,
  onClose,
}: {
  measurementType: string;
  ymPoint: YmPoint;
  open: boolean;
  onClose: () => void;
}) {
  const dataArray: { time: number; value: number }[] = [];

  const theme = useTheme();

  let currentYear = "";
  let currentMonth = "";
  let currentDay = "";

  const measurementThresholdValue = ymPoint.thresholdValues
    ? Number(ymPoint.thresholdValues[measurementType])
    : undefined;

  ymPoint.data &&
    Object.entries(ymPoint.data.historicalDataRecord).forEach((year) => {
      if (typeof year[1] === "object") {
        currentYear = year[0];
        Object.entries(year[1] as object).forEach((month) => {
          if (typeof month[1] === "object") {
            currentMonth = month[0];
            Object.entries(month[1] as object).forEach((day) => {
              currentDay = day[0];
              if (day[1][measurementType]) {
                const date = new Date();
                date.setFullYear(Number(currentYear.replace("y", "20")));
                date.setMonth(Number(currentMonth.replace("m", "")));
                date.setDate(Number(currentDay.replace("d", "")));
                date.setHours(0, 0, 0, 0);
                dataArray.push({
                  time: date.getTime(),
                  value: day[1][measurementType].value,
                });
              }
            });
          }
        });
      }
    });

  const sortedDateArray = dataArray.sort((a, b) => {
    return a.time - b.time;
  });

  let dialogContent: React.ReactNode = null;

  if (dataArray.length > 0) {
    const startTime = new Date(sortedDateArray[0].time);
    startTime.setMonth(1, 1);
    startTime.setHours(0, 0, 0, 0);

    const endTime = new Date(sortedDateArray.slice(-1)[0].time);
    endTime.setMonth(12, 31);
    endTime.setHours(0, 0, 0, 0);

    dialogContent = (
      <LineChart
        margin={{ bottom: 50, left: 10, right: 50 }}
        data={dataArray}
        referenceLineProps={
          measurementThresholdValue
            ? [
                {
                  axis: "y",
                  color: theme.palette.error.main,
                  value: measurementThresholdValue,
                  label: "Terskelverdi",
                },
              ]
            : undefined
        }
        dataProps={[
          {
            fieldName: "value",
            name: measurementType,
            color: theme.palette.info.main,
          },
        ]}
        timeWindow={generateFloatingWindow(Step.Month, 24, 1)}
      />
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={`${measurementType} - Siste 2 år`}
    >
      {dialogContent}
    </Dialog>
  );
}
