/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SectionCounterDataMonths,
    SectionCounterDataMonthsFromJSON,
    SectionCounterDataMonthsFromJSONTyped,
    SectionCounterDataMonthsToJSON,
    SectionCounterDataQuarters,
    SectionCounterDataQuartersFromJSON,
    SectionCounterDataQuartersFromJSONTyped,
    SectionCounterDataQuartersToJSON,
    SectionCounterDataSections1,
    SectionCounterDataSections1FromJSON,
    SectionCounterDataSections1FromJSONTyped,
    SectionCounterDataSections1ToJSON,
} from './';

/**
 * 
 * @export
 * @interface SectionCounterDataDeducted
 */
export interface SectionCounterDataDeducted {
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataMonths; }}
     * @memberof SectionCounterDataDeducted
     */
    months: { [key: string]: SectionCounterDataMonths; };
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataQuarters; }}
     * @memberof SectionCounterDataDeducted
     */
    quarters: { [key: string]: SectionCounterDataQuarters; };
    /**
     * 
     * @type {string}
     * @memberof SectionCounterDataDeducted
     */
    availability: string;
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataSections1; }}
     * @memberof SectionCounterDataDeducted
     */
    sectionAvailability: { [key: string]: SectionCounterDataSections1; };
}

export function SectionCounterDataDeductedFromJSON(json: any): SectionCounterDataDeducted {
    return SectionCounterDataDeductedFromJSONTyped(json, false);
}

export function SectionCounterDataDeductedFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionCounterDataDeducted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'months': (mapValues(json['months'], SectionCounterDataMonthsFromJSON)),
        'quarters': (mapValues(json['quarters'], SectionCounterDataQuartersFromJSON)),
        'availability': json['availability'],
        'sectionAvailability': (mapValues(json['sectionAvailability'], SectionCounterDataSections1FromJSON)),
    };
}

export function SectionCounterDataDeductedToJSON(value?: SectionCounterDataDeducted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'months': (mapValues(value.months, SectionCounterDataMonthsToJSON)),
        'quarters': (mapValues(value.quarters, SectionCounterDataQuartersToJSON)),
        'availability': value.availability,
        'sectionAvailability': (mapValues(value.sectionAvailability, SectionCounterDataSections1ToJSON)),
    };
}


