import { SectionCounter, ReportObject } from "services/availabilityApi/models";
import { useQuery, useQueries } from "react-query";
import {
  authorizedAvailabilityApi,
  authorizedAvailabilityDocumentApi,
} from "services/apiService";
import {
  AvailabilityDocumentApi,
  DocumentReport,
} from "services/availabilityApi";
import { queryClient } from "services/apiService";

export const useQuerySectionCounter = (): {
  data: SectionCounter | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ["sectionCounter"],
    async () => {
      const api = await authorizedAvailabilityApi();
      return api.getSectionCounter();
    }
  );

  return { data, refetch, isLoading };
};

export const useReportHook = (
  year: string,
  month: string
): {
  data: ReportObject[] | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(
    ["availabilityReport", year, month],
    async () => {
      const api = await authorizedAvailabilityApi();
      return api.getMontlyReport({ yearMonth: year + "-" + month });
    }
  );

  return { data, refetch, isLoading };
};

const paginationDocumentReportQuery = async (
  api: AvailabilityDocumentApi,
  documentId: string,
  temporaryObjectStore: DocumentReport[]
) => {
  await api
    .getDocumentReport({ documentId: documentId })
    .then(async (response) => {
      temporaryObjectStore.push(response);
      if (response.status === "initial" || response.status === "processing") {
        await paginationDocumentReportQuery(
          api,
          documentId,
          temporaryObjectStore
        );
      }
    });
};

export const useAvailabilityDocumentReportQuery = (
  documentIdlist: string[]
) => {
  const queryArray = documentIdlist.map((element) => {
    return {
      queryKey: ["availabilityDocumentsReport", element],
      queryFn: async () => {
        const temporaryObjectStore: DocumentReport[] = [];
        const api = await authorizedAvailabilityDocumentApi();
        await paginationDocumentReportQuery(api, element, temporaryObjectStore);
        return { objects: temporaryObjectStore };
      },
    };
  });
  let data: { [key: string]: DocumentReport } = {};
  const queriesResult = useQueries(queryArray);

  queriesResult.forEach((response) => {
    response.data &&
      response.data.objects.forEach((obj) => {
        data[obj.documentName] = obj;
      });
  });
  return {
    data,
    refetch: () =>
      queriesResult.forEach((response) => {
        response.refetch();
      }),
    remove: () => {
      queryClient.removeQueries("availabilityDocumentsReport");
    },
  };
};
