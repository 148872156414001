import React from "react";

import { useSelector } from "react-redux";
import { TStore } from "redux/store";
import Box from "@mui/material/Box";

import TopView from "components/TopView";
import NCard from "components/hms/NCard";
import HCard from "components/hms/HCard";
import RSCard from "components/hms/RSCard";
import RSChartCard from "components/hms/RSChartCard";
import RUHReportDialog from "components/hms/dialog/RUHReportDialog";
import SJAReportDialog from "components/hms/dialog/SJAReportDialog";
import HmsDocumentUpload from "components/hms/HmsDocumentUpload";
import PageMenu from "@smartinspection/ameta-frontend-components/navigation/PageMenu";
import NAndHReportDialog from "components/hms/dialog/NAndHReportDialog";
import AddNAndHDialog from "components/hms/dialog/AddNAndHDialog";
import AddRUHDialog from "components/hms/dialog/AddRUHDialog";
import AddSJADialog from "components/hms/dialog/AddSJADialog";
import { useQueryHmsCounter } from "services/hmsQueryHooks";

import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";

export default function HMS() {
  const HmsCounterQuery = useQueryHmsCounter();

  const hmsCounterData = HmsCounterQuery.data;

  const globalState = useSelector((state: TStore) => state.global);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <TopView title="HMS" />
      {HmsCounterQuery.isLoading ? (
        <CenterContainer>
          <CircularProgress size={150} />
        </CenterContainer>
      ) : (
        <Box sx={{ height: "100%", display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <PageMenu
              sx={{ pl: 3, p: 0 }}
              hideMenu={globalState.hideMenus}
              transparent
            >
              <Box sx={{ py: 1 }}>
                <AddNAndHDialog refetch={HmsCounterQuery.refetch} />
              </Box>
              {hmsCounterData && (
                <Box sx={{ py: 1 }}>
                  <NAndHReportDialog
                    hmsCounterData={hmsCounterData}
                    refetch={HmsCounterQuery.refetch}
                  />
                </Box>
              )}
            </PageMenu>
            <PageMenu
              sx={{ pl: 3, p: 0 }}
              hideMenu={globalState.hideMenus}
              transparent
            >
              <Box sx={{ py: 1 }}>
                <HmsDocumentUpload refetch={HmsCounterQuery.refetch} />
              </Box>
              <Box sx={{ py: 1 }}>
                <AddRUHDialog refetch={HmsCounterQuery.refetch} />
              </Box>
              <Box sx={{ py: 1 }}>
                <AddSJADialog refetch={HmsCounterQuery.refetch} />
              </Box>
              {hmsCounterData && (
                <Box sx={{ py: 1 }}>
                  <RUHReportDialog
                    hmsCounterData={hmsCounterData}
                    refetch={HmsCounterQuery.refetch}
                  />
                </Box>
              )}
              {hmsCounterData && (
                <Box sx={{ py: 1 }}>
                  <SJAReportDialog
                    hmsCounterData={hmsCounterData}
                    refetch={HmsCounterQuery.refetch}
                  />
                </Box>
              )}
            </PageMenu>
          </Box>
          <GridLayout
            editable={globalState.layoutEditable}
            layout={[
              {
                i: "1",
                h: 15,
                w: 6,
                x: 0,
                y: 0,
              },
              {
                i: "2",
                h: 15,
                w: 6,
                x: 6,
                y: 0,
              },
              {
                i: "3",
                h: 15,
                w: 6,
                x: 0,
                y: 15,
              },
              {
                i: "4",
                h: 15,
                w: 6,
                x: 6,
                y: 15,
              },
            ]}
          >
            <NCard key="1" hmsCounterData={hmsCounterData} />
            <HCard key="2" hmsCounterData={hmsCounterData} />
            <RSCard key="3" hmsCounterData={hmsCounterData} />
            <RSChartCard key="4" hmsCounterData={hmsCounterData} />
          </GridLayout>
        </Box>
      )}
    </Box>
  );
}
