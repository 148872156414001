import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  fileUploadDialog: boolean;
} = {
  fileUploadDialog: false,
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setFileUploadDialogOpen: (state) => {
      state.fileUploadDialog = true;
    },
    setFileUploadDialogClosed: (state) => {
      state.fileUploadDialog = false;
    },
  },
});

export const { setFileUploadDialogOpen, setFileUploadDialogClosed } =
  documentSlice.actions;

export default documentSlice.reducer;
