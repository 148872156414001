import React, { useState } from "react";
import { format } from "date-fns";
import Box from "@mui/material/Box";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import BarChart from "@smartinspection/ameta-frontend-components/charts/BarChart";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import {
  HMSCounter,
  RuhYearDataObject,
  SjaYearDataObject,
} from "services/hmsApi";

import { yearsListDropdown } from "utils/yearsListDropDown";

interface GraphData {
  label: string;
  RUH?: number;
  SJA?: number;
}

export default function RSChartCard({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const theme = useTheme();

  const labelMonthConverter = {
    m1: "Jan",
    m2: "Feb",
    m3: "Mar",
    m4: "Apr",
    m5: "Mai",
    m6: "Jun",
    m7: "Jul",
    m8: "Aug",
    m9: "Sep",
    m10: "Okt",
    m11: "Nov",
    m12: "Des",
  };

  const sjaRuhYearMonthMapper: (
    sjaYear: SjaYearDataObject | undefined,
    ruhYear: RuhYearDataObject | undefined
  ) => GraphData[] = (
    sjaYear: SjaYearDataObject | undefined,
    ruhYear: RuhYearDataObject | undefined
  ) => {
    const hmsNumbers: GraphData[] = [];
    Object.entries(labelMonthConverter).forEach(([key, value]) => {
      hmsNumbers.push({
        label: value,
        ...(sjaYear?.months?.[key]?.sumSja && {
          SJA: sjaYear.months[key].sumSja,
        }),
        ...(ruhYear?.months?.[key]?.sumRuh && {
          RUH: ruhYear.months[key].sumRuh,
        }),
      });
    });
    return hmsNumbers;
  };

  const yearConverter = (date: Date) => {
    const year = format(date, "yyyy");
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );

  const sjaReportYear =
    hmsCounterData?.data?.sja?.years?.[yearConverter(selectedDate)];

  const ruhReportYear =
    hmsCounterData?.data?.ruh?.years?.[yearConverter(selectedDate)];

  return (
    <Card
      type="default"
      faIcon="notes-medical"
      title="RUH og SJA • Årsoversikt"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="År"
              optionsList={yearsListDropdown()}
              value={selectedDate.getFullYear().toString()}
              onChange={(e) => {
                const newDate = new Date(selectedDate);
                newDate.setFullYear(+e);
                setSelectedDate(new Date(newDate));
              }}
            />
          </Box>
        </Box>
      }
    >
      <BarChart
        margin={{ top: 0, bottom: 10, left: 0, right: 0 }}
        dataProps={[
          { fieldName: "RUH", name: "RUH", color: theme.palette.success.light },
          {
            fieldName: "SJA",
            name: "SJA",
            color: theme.palette.ameta.background.dark,
          },
        ]}
        data={
          sjaRuhYearMonthMapper(sjaReportYear, ruhReportYear) as unknown as {
            [key: string]: string | number;
          }[]
        }
      />
    </Card>
  );
}
