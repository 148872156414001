/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface YmPointGeojsonGeometry
 */
export interface YmPointGeojsonGeometry {
    /**
     * 
     * @type {Array<number>}
     * @memberof YmPointGeojsonGeometry
     */
    coordinates?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof YmPointGeojsonGeometry
     */
    type?: string;
}

export function YmPointGeojsonGeometryFromJSON(json: any): YmPointGeojsonGeometry {
    return YmPointGeojsonGeometryFromJSONTyped(json, false);
}

export function YmPointGeojsonGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): YmPointGeojsonGeometry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function YmPointGeojsonGeometryToJSON(value?: YmPointGeojsonGeometry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coordinates': value.coordinates,
        'type': value.type,
    };
}


