/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SectionCounterDataSections2,
    SectionCounterDataSections2FromJSON,
    SectionCounterDataSections2FromJSONTyped,
    SectionCounterDataSections2ToJSON,
} from './';

/**
 * 
 * @export
 * @interface SectionCounterDataMonths1
 */
export interface SectionCounterDataMonths1 {
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataSections2; }}
     * @memberof SectionCounterDataMonths1
     */
    sections: { [key: string]: SectionCounterDataSections2; };
}

export function SectionCounterDataMonths1FromJSON(json: any): SectionCounterDataMonths1 {
    return SectionCounterDataMonths1FromJSONTyped(json, false);
}

export function SectionCounterDataMonths1FromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionCounterDataMonths1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sections': (mapValues(json['sections'], SectionCounterDataSections2FromJSON)),
    };
}

export function SectionCounterDataMonths1ToJSON(value?: SectionCounterDataMonths1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sections': (mapValues(value.sections, SectionCounterDataSections2ToJSON)),
    };
}


