import React from 'react';

import Box from '@mui/material/Box';

import Text from '@smartinspection/ameta-frontend-components/display/Text';

function CommonChartLegend({
  label,
  color,
  highlighted,
}: {
  label: string;
  color: string | undefined;
  highlighted?: boolean;
}) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          height: 16,
          width: 16,
          borderRadius: '50%',
          marginRight: 10,
        }}
      >
        <Box
          style={{
            height: 16,
            width: 16,
            borderRadius: '50%',
            backgroundColor: color,
          }}
        />
      </Box>
      <Text
        variant="subtitle1"
        sx={{ fontWeight: highlighted ? 550 : undefined }}
        color="text.secondary"
      >
        {label}
      </Text>
    </Box>
  );
}

export default CommonChartLegend;
