import { format } from 'date-fns';
export const monthConverter = (date: Date) => {
  const month = format(date, 'M');
  const paddingString = 'm';
  return paddingString.concat(month);
};
export const yearConverter = (date: Date) => {
  const year = format(date, 'yyyy');
  const slicedYear = year.slice(2, 4);
  const paddingString = 'y';
  return paddingString.concat(slicedYear);
};
