/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Traffic API
 * A rest api for managing the ameta traffic system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SensorData
 */
export interface SensorData {
    /**
     * 
     * @type {string}
     * @memberof SensorData
     */
    navn: string;
    /**
     * 
     * @type {string}
     * @memberof SensorData
     */
    vegreferanse: string;
    /**
     * 
     * @type {string}
     * @memberof SensorData
     */
    fra: string;
    /**
     * 
     * @type {string}
     * @memberof SensorData
     */
    til: string;
    /**
     * 
     * @type {string}
     * @memberof SensorData
     */
    volum: string;
    /**
     * 
     * @type {string}
     * @memberof SensorData
     */
    felt: string;
    /**
     * 
     * @type {string}
     * @memberof SensorData
     */
    smallSize: string;
    /**
     * 
     * @type {string}
     * @memberof SensorData
     */
    largeSize: string;
}

export function SensorDataFromJSON(json: any): SensorData {
    return SensorDataFromJSONTyped(json, false);
}

export function SensorDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'navn': json['Navn'],
        'vegreferanse': json['Vegreferanse'],
        'fra': json['Fra'],
        'til': json['Til'],
        'volum': json['Volum'],
        'felt': json['Felt'],
        'smallSize': json['SmallSize'],
        'largeSize': json['LargeSize'],
    };
}

export function SensorDataToJSON(value?: SensorData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Navn': value.navn,
        'Vegreferanse': value.vegreferanse,
        'Fra': value.fra,
        'Til': value.til,
        'Volum': value.volum,
        'Felt': value.felt,
        'SmallSize': value.smallSize,
        'LargeSize': value.largeSize,
    };
}


