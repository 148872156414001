/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Traffic API
 * A rest api for managing the ameta traffic system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrafficSensorReadingTotal
 */
export interface TrafficSensorReadingTotal {
    /**
     * 
     * @type {string}
     * @memberof TrafficSensorReadingTotal
     */
    lane1?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficSensorReadingTotal
     */
    lane2?: string;
    /**
     * 
     * @type {string}
     * @memberof TrafficSensorReadingTotal
     */
    all?: string;
}

export function TrafficSensorReadingTotalFromJSON(json: any): TrafficSensorReadingTotal {
    return TrafficSensorReadingTotalFromJSONTyped(json, false);
}

export function TrafficSensorReadingTotalFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficSensorReadingTotal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lane1': !exists(json, 'lane_1') ? undefined : json['lane_1'],
        'lane2': !exists(json, 'lane_2') ? undefined : json['lane_2'],
        'all': !exists(json, 'all') ? undefined : json['all'],
    };
}

export function TrafficSensorReadingTotalToJSON(value?: TrafficSensorReadingTotal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lane_1': value.lane1,
        'lane_2': value.lane2,
        'all': value.all,
    };
}


