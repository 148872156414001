/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SectionCounterDataSections2
 */
export interface SectionCounterDataSections2 {
    /**
     * 
     * @type {string}
     * @memberof SectionCounterDataSections2
     */
    minutesClosedDay: string;
    /**
     * 
     * @type {string}
     * @memberof SectionCounterDataSections2
     */
    minutesClosedNight: string;
    /**
     * 
     * @type {string}
     * @memberof SectionCounterDataSections2
     */
    totalMinutes: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SectionCounterDataSections2
     */
    eventTimes: { [key: string]: string; };
}

export function SectionCounterDataSections2FromJSON(json: any): SectionCounterDataSections2 {
    return SectionCounterDataSections2FromJSONTyped(json, false);
}

export function SectionCounterDataSections2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionCounterDataSections2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minutesClosedDay': json['minutesClosedDay'],
        'minutesClosedNight': json['minutesClosedNight'],
        'totalMinutes': json['totalMinutes'],
        'eventTimes': json['eventTimes'],
    };
}

export function SectionCounterDataSections2ToJSON(value?: SectionCounterDataSections2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minutesClosedDay': value.minutesClosedDay,
        'minutesClosedNight': value.minutesClosedNight,
        'totalMinutes': value.totalMinutes,
        'eventTimes': value.eventTimes,
    };
}


