/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentReportParsingReportErrors,
    DocumentReportParsingReportErrorsFromJSON,
    DocumentReportParsingReportErrorsFromJSONTyped,
    DocumentReportParsingReportErrorsToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentReportParsingReport
 */
export interface DocumentReportParsingReport {
    /**
     * 
     * @type {number}
     * @memberof DocumentReportParsingReport
     */
    numberOfMeasurementsAdded?: number;
    /**
     * 
     * @type {DocumentReportParsingReportErrors}
     * @memberof DocumentReportParsingReport
     */
    errors?: DocumentReportParsingReportErrors;
}

export function DocumentReportParsingReportFromJSON(json: any): DocumentReportParsingReport {
    return DocumentReportParsingReportFromJSONTyped(json, false);
}

export function DocumentReportParsingReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentReportParsingReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfMeasurementsAdded': !exists(json, 'numberOfMeasurementsAdded') ? undefined : json['numberOfMeasurementsAdded'],
        'errors': !exists(json, 'errors') ? undefined : DocumentReportParsingReportErrorsFromJSON(json['errors']),
    };
}

export function DocumentReportParsingReportToJSON(value?: DocumentReportParsingReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfMeasurementsAdded': value.numberOfMeasurementsAdded,
        'errors': DocumentReportParsingReportErrorsToJSON(value.errors),
    };
}


