import React from "react";

import { useSelector } from "react-redux";
import { TStore } from "redux/store";
import Box from "@mui/material/Box";

import GridLayout from "@smartinspection/ameta-frontend-components/layout/GridLayout";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";

import TopView from "components/TopView";
import QuarterlyOverview from "components/availability/QuarterlyOverview";
import SectionOverview from "components/availability/SectionOverview";
import EventOverview from "components/availability/EventOverview";
import AddEvent from "components/availability/dialog/AddEvent";
import ReportDialog from "components/availability/dialog/ReportDialog";
import AvailabilityDocumentUpload from "components/availability/AvailabilityDocumentUpload";
import { useQuerySectionCounter } from "services/availabilityQueryHooks";

import PageMenu from "@smartinspection/ameta-frontend-components/navigation/PageMenu";

export default function Availability() {
  const sectionCounterQuery = useQuerySectionCounter();

  const sectionCounterData = sectionCounterQuery.data;

  const globalState = useSelector((state: TStore) => state.global);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TopView title="Tilgjengelighet" />
      {sectionCounterQuery.isLoading ? (
        <CenterContainer>
          <CircularProgress size={150} />
        </CenterContainer>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <PageMenu
            sx={{ pl: 3, p: 0 }}
            hideMenu={globalState.hideMenus}
            transparent
          >
            <Box sx={{ py: 1 }}>
              <AddEvent
                refetch={sectionCounterQuery.refetch}
                key="AddEventDialog"
              />
            </Box>
            <Box sx={{ py: 1 }}>
              <ReportDialog
                key="ReportDialog"
                onClose={sectionCounterQuery.refetch}
              />
            </Box>
            <Box sx={{ py: 1 }}>
              <AvailabilityDocumentUpload
                key="UploadAvailabilityFileDialog"
                refetch={sectionCounterQuery.refetch}
              />
            </Box>
          </PageMenu>
          {sectionCounterData && (
            <GridLayout
              spacing={16}
              editable={globalState.layoutEditable}
              layout={[
                {
                  i: "1",
                  h: 30,
                  w: 6,
                  x: 0,
                  y: 0,
                },
                {
                  i: "2",
                  h: 15,
                  w: 6,
                  x: 6,
                  y: 0,
                },
                {
                  i: "3",
                  h: 15,
                  w: 6,
                  x: 6,
                  y: 15,
                },
              ]}
            >
              <QuarterlyOverview
                key="1"
                sectionCounterData={sectionCounterData}
              />
              <SectionOverview
                key="2"
                sectionCounterData={sectionCounterData}
              />
              <EventOverview key="3" sectionCounterData={sectionCounterData} />
            </GridLayout>
          )}
        </Box>
      )}
    </Box>
  );
}
