/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentReportParsingReportErrors
 */
export interface DocumentReportParsingReportErrors {
    /**
     * 
     * @type {string}
     * @memberof DocumentReportParsingReportErrors
     */
    unknownfileExtension?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReportParsingReportErrors
     */
    unknownfieldName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentReportParsingReportErrors
     */
    unknownStations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentReportParsingReportErrors
     */
    missingStationsWithYmPoints?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentReportParsingReportErrors
     */
    unkownMeasurementTypesAdded?: Array<string>;
}

export function DocumentReportParsingReportErrorsFromJSON(json: any): DocumentReportParsingReportErrors {
    return DocumentReportParsingReportErrorsFromJSONTyped(json, false);
}

export function DocumentReportParsingReportErrorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentReportParsingReportErrors {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unknownfileExtension': !exists(json, 'unknownfileExtension') ? undefined : json['unknownfileExtension'],
        'unknownfieldName': !exists(json, 'unknownfieldName') ? undefined : json['unknownfieldName'],
        'unknownStations': !exists(json, 'unknownStations') ? undefined : json['unknownStations'],
        'missingStationsWithYmPoints': !exists(json, 'missingStationsWithYmPoints') ? undefined : json['missingStationsWithYmPoints'],
        'unkownMeasurementTypesAdded': !exists(json, 'unkownMeasurementTypesAdded') ? undefined : json['unkownMeasurementTypesAdded'],
    };
}

export function DocumentReportParsingReportErrorsToJSON(value?: DocumentReportParsingReportErrors | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unknownfileExtension': value.unknownfileExtension,
        'unknownfieldName': value.unknownfieldName,
        'unknownStations': value.unknownStations,
        'missingStationsWithYmPoints': value.missingStationsWithYmPoints,
        'unkownMeasurementTypesAdded': value.unkownMeasurementTypesAdded,
    };
}


