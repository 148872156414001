import { Auth } from "aws-amplify";
import { QueryClient } from "react-query";
import { TrafficApi } from "services/trafficApi/apis";
import { YmApi } from "services/ymApi/apis";

import {
  AvailabilityApi,
  AvailabilityDocumentApi,
} from "services/availabilityApi/apis";
import {
  SecurityEventApi,
  SecurityCounterApi,
} from "services/securityApi/apis";
import {
  RuhReportApi,
  SjaReportApi,
  NhReportApi,
  HmsCounterApi,
  DeleteReportApi,
  HmsDocumentApi,
} from "services/hmsApi";

import { Configuration as TrafficConfiguration } from "services/trafficApi/runtime";
import { Configuration as YmConfiguration } from "services/ymApi/runtime";
import { Configuration as AvailabilityConfiguration } from "services/availabilityApi/runtime";
import { Configuration as SecurityConfiguration } from "services/securityApi/runtime";
import { Configuration as HmsConfiguration } from "services/hmsApi/runtime";

export const queryClient = new QueryClient();

export const authorizedTrafficApi: () => Promise<TrafficApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new TrafficConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/traffic",
  });
  return new TrafficApi(apiConfig);
};
export const authorizedYmApi: () => Promise<YmApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new YmConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/ym",
  });
  return new YmApi(apiConfig);
};

export const authorizedAvailabilityApi: () => Promise<AvailabilityApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new AvailabilityConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/availability",
    });
    return new AvailabilityApi(apiConfig);
  };

export const authorizedAvailabilityDocumentApi: () => Promise<AvailabilityDocumentApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new AvailabilityConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/availability",
    });
    return new AvailabilityDocumentApi(apiConfig);
  };

export const authorizedSecurityEventApi: () => Promise<SecurityEventApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new SecurityConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/security",
    });
    return new SecurityEventApi(apiConfig);
  };

export const authorizedSecurityCounterApi: () => Promise<SecurityCounterApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new SecurityConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/security",
    });
    return new SecurityCounterApi(apiConfig);
  };

export const authorizedHmsCounterApi: () => Promise<HmsCounterApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new HmsConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/hms",
    });
    return new HmsCounterApi(apiConfig);
  };

export const authorizedHmsDocumentApi: () => Promise<HmsDocumentApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new HmsConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/hms",
    });
    return new HmsDocumentApi(apiConfig);
  };

export const authorizedRuhReportApi: () => Promise<RuhReportApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new HmsConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/hms",
  });
  return new RuhReportApi(apiConfig);
};

export const authorizedDeleteReportApi: () => Promise<DeleteReportApi> =
  async () => {
    const currentUserSession = await Auth.currentSession();
    const idToken = currentUserSession.getIdToken().getJwtToken();
    const apiConfig = new HmsConfiguration({
      apiKey: idToken,
      basePath: process.env.REACT_APP_API_ENDPOINT + "/hms",
    });
    return new DeleteReportApi(apiConfig);
  };

export const authorizedSjaReportApi: () => Promise<SjaReportApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new HmsConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/hms",
  });
  return new SjaReportApi(apiConfig);
};

export const authorizedNhEventApi: () => Promise<NhReportApi> = async () => {
  const currentUserSession = await Auth.currentSession();
  const idToken = currentUserSession.getIdToken().getJwtToken();
  const apiConfig = new HmsConfiguration({
    apiKey: idToken,
    basePath: process.env.REACT_APP_API_ENDPOINT + "/hms",
  });
  return new NhReportApi(apiConfig);
};
