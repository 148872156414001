/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeleteYmMeasurementData,
    DeleteYmMeasurementDataFromJSON,
    DeleteYmMeasurementDataFromJSONTyped,
    DeleteYmMeasurementDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface DeleteYmMeasurement
 */
export interface DeleteYmMeasurement {
    /**
     * 
     * @type {DeleteYmMeasurementData}
     * @memberof DeleteYmMeasurement
     */
    data: DeleteYmMeasurementData;
}

export function DeleteYmMeasurementFromJSON(json: any): DeleteYmMeasurement {
    return DeleteYmMeasurementFromJSONTyped(json, false);
}

export function DeleteYmMeasurementFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteYmMeasurement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': DeleteYmMeasurementDataFromJSON(json['data']),
    };
}

export function DeleteYmMeasurementToJSON(value?: DeleteYmMeasurement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': DeleteYmMeasurementDataToJSON(value.data),
    };
}


