import { createSlice } from "@reduxjs/toolkit";

import { PaletteMode } from "@mui/material";

const initialState: {
  colorMode: PaletteMode;
  layoutEditable: boolean;
  hideMenus: boolean;
} = {
  colorMode: "light",
  layoutEditable: false,
  hideMenus: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleColorMode: (state) => {
      state.colorMode = state.colorMode === "light" ? "dark" : "light";
    },
    toggleHideMenus: (state) => {
      state.hideMenus = !state.hideMenus;
    },
    toggleEditLayout: (state) => {
      state.layoutEditable = !state.layoutEditable;
    },
  },
});

export const { toggleColorMode, toggleHideMenus, toggleEditLayout } =
  globalSlice.actions;

export default globalSlice.reducer;
