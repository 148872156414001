/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Traffic API
 * A rest api for managing the ameta traffic system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrafficSensorCounterTotal
 */
export interface TrafficSensorCounterTotal {
    /**
     * 
     * @type {number}
     * @memberof TrafficSensorCounterTotal
     */
    lane1?: number;
    /**
     * 
     * @type {number}
     * @memberof TrafficSensorCounterTotal
     */
    lane2?: number;
    /**
     * 
     * @type {number}
     * @memberof TrafficSensorCounterTotal
     */
    lane3?: number;
    /**
     * 
     * @type {number}
     * @memberof TrafficSensorCounterTotal
     */
    lane4?: number;
    /**
     * 
     * @type {number}
     * @memberof TrafficSensorCounterTotal
     */
    all?: number;
}

export function TrafficSensorCounterTotalFromJSON(json: any): TrafficSensorCounterTotal {
    return TrafficSensorCounterTotalFromJSONTyped(json, false);
}

export function TrafficSensorCounterTotalFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficSensorCounterTotal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lane1': !exists(json, 'lane_1') ? undefined : json['lane_1'],
        'lane2': !exists(json, 'lane_2') ? undefined : json['lane_2'],
        'lane3': !exists(json, 'lane_3') ? undefined : json['lane_3'],
        'lane4': !exists(json, 'lane_4') ? undefined : json['lane_4'],
        'all': !exists(json, 'all') ? undefined : json['all'],
    };
}

export function TrafficSensorCounterTotalToJSON(value?: TrafficSensorCounterTotal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lane_1': value.lane1,
        'lane_2': value.lane2,
        'lane_3': value.lane3,
        'lane_4': value.lane4,
        'all': value.all,
    };
}


