/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportObject
 */
export interface ReportObject {
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    sort: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    closedDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    openedDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    totalTime: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    totalMinutes: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    dayMinutes: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    nightMinutes: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    netMinutes: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    workType: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    direction: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    section: string;
    /**
     * 
     * @type {string}
     * @memberof ReportObject
     */
    isDeducted: string;
}

export function ReportObjectFromJSON(json: any): ReportObject {
    return ReportObjectFromJSONTyped(json, false);
}

export function ReportObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sort': json['sort'],
        'closedDate': json['closedDate'],
        'openedDate': json['openedDate'],
        'totalTime': json['totalTime'],
        'totalMinutes': json['totalMinutes'],
        'dayMinutes': json['dayMinutes'],
        'nightMinutes': json['nightMinutes'],
        'netMinutes': json['netMinutes'],
        'workType': json['workType'],
        'direction': json['direction'],
        'section': json['section'],
        'isDeducted': json['isDeducted'],
    };
}

export function ReportObjectToJSON(value?: ReportObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'closedDate': value.closedDate,
        'openedDate': value.openedDate,
        'totalTime': value.totalTime,
        'totalMinutes': value.totalMinutes,
        'dayMinutes': value.dayMinutes,
        'nightMinutes': value.nightMinutes,
        'netMinutes': value.netMinutes,
        'workType': value.workType,
        'direction': value.direction,
        'section': value.section,
        'isDeducted': value.isDeducted,
    };
}


