import { configureStore } from "@reduxjs/toolkit";

import globalReducer from "redux/global";
import ymReducer from "redux/ym";
import documentReducer from "redux/document";

const store = configureStore({
  reducer: { global: globalReducer, ym: ymReducer, document: documentReducer },
});
export default store;
export type TStore = ReturnType<typeof store.getState>;
