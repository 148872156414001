import React from "react";
import { useNavigate } from "react-router-dom";

import { groupBy } from "lodash";

import { useDispatch } from "react-redux";
import {
  setCenterPoint,
  setZoomLevel,
  setSideMenuClosed,
  setSelectedPointId,
  setDrawerOpen,
} from "redux/ym";

// import EnvironmentCardMapMarkerPopup from "components/mainView/environmentCard//EnvironmentCardMapMarkerPopup";

import YmMarkerCluster from "components/ym/mapView/YmMarkerCluster";

import Map from "@smartinspection/ameta-frontend-components/map/Map";

import { YmPoint } from "services/ymApi";

function EnvironmentCardMap({ markers }: { markers?: YmPoint[] }) {
  const navigate = useNavigate();

  const filteredMarkers =
    markers &&
    markers.filter((marker) => {
      return marker.status === "warning" || marker.status === "alarm";
    });

  const dispatch = useDispatch();

  const groupedData =
    filteredMarkers && groupBy(Object.values(filteredMarkers), "status");
  let statuses = groupedData && Object.keys(groupedData);
  if (statuses) {
    statuses = statuses.sort();
    // Hack, but works for now.
    // Probably not bulletproof.
    // Used to avoid getting wrong colors if the amount
    // of colors changes from 2 to 3 or vice versa,
    // in some spesific cases.
  }

  return (
    <Map
      whenCreated={(map) => {
        filteredMarkers &&
          filteredMarkers.length > 0 &&
          map.fitBounds(
            filteredMarkers.map((marker) => {
              return [
                marker.centerPoint.latitude,
                marker.centerPoint.longitude,
              ];
            }),
            { padding: [20, 20], maxZoom: 16 }
          );
      }}
    >
      {statuses &&
        groupedData &&
        statuses.map((status, index) => {
          return (
            <YmMarkerCluster
              key={index}
              clusterColor={
                status === "ok"
                  ? "green"
                  : status === "warning"
                  ? "orange"
                  : status === "alarm"
                  ? "red"
                  : "cadetblue"
              }
              onClick={(point) => {
                dispatch(setDrawerOpen());
                dispatch(setSelectedPointId(point.id));
                dispatch(
                  setCenterPoint({
                    lat: point.centerPoint.latitude,
                    lng: point.centerPoint.longitude,
                  })
                );
                dispatch(setSideMenuClosed());
                dispatch(setZoomLevel(14));
                navigate("/dashboard/ym-map");
              }}
              data={groupedData[status]}
            />
          );
        })}
    </Map>
  );
}

export default EnvironmentCardMap;
