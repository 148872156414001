/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SjaYearDataObject,
    SjaYearDataObjectFromJSON,
    SjaYearDataObjectFromJSONTyped,
    SjaYearDataObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface HMSCounterDataSja
 */
export interface HMSCounterDataSja {
    /**
     * 
     * @type {{ [key: string]: SjaYearDataObject; }}
     * @memberof HMSCounterDataSja
     */
    years: { [key: string]: SjaYearDataObject; };
}

export function HMSCounterDataSjaFromJSON(json: any): HMSCounterDataSja {
    return HMSCounterDataSjaFromJSONTyped(json, false);
}

export function HMSCounterDataSjaFromJSONTyped(json: any, ignoreDiscriminator: boolean): HMSCounterDataSja {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'years': (mapValues(json['years'], SjaYearDataObjectFromJSON)),
    };
}

export function HMSCounterDataSjaToJSON(value?: HMSCounterDataSja | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'years': (mapValues(value.years, SjaYearDataObjectToJSON)),
    };
}


