import React from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Box from "@mui/material/Box";
import Card from "@smartinspection/ameta-frontend-components/display/Card";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

import CircularProgress from "@smartinspection/ameta-frontend-components/display/CircularProgress";

import { useQuerySectionCounter } from "services/availabilityQueryHooks";

import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

function AvailabilityCard() {
  const sectionCounterQuery = useQuerySectionCounter();
  const sectionCounterData = sectionCounterQuery.data;

  const theme = useTheme();
  const navigate = useNavigate();

  const currentDate = new Date();
  const currentMonth = String(format(currentDate, "M"));
  const currentYear = String(format(currentDate, "Y"));
  const currentQuarter = Math.ceil(Number(currentMonth) / 3);

  const quarterAvailability: number | undefined = sectionCounterData?.data
    ?.deducted?.[currentYear]?.quarters?.[currentQuarter]
    ? Number(
        sectionCounterData.data.deducted[currentYear]?.quarters[currentQuarter]
          .availability
      )
    : undefined;

  const soFarThisYear: number | undefined = sectionCounterData?.data
    ?.deducted?.[currentYear]?.availability
    ? Number(sectionCounterData.data.deducted[currentYear].availability)
    : undefined;

  const thresholdValue = 99.0;

  return (
    <Card
      type="drawer"
      faIcon="chart-bar"
      title="Tilgjengelighet"
      subtitle={`${currentQuarter}. kvartal ${currentYear}`}
      rightHeaderComponent={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pr: 1,
            pt: 1.5,
          }}
        >
          <IconButton
            transparentBackground
            type="success"
            onClick={() => {
              navigate("/dashboard/availability");
            }}
          >
            <FontAwesomeIcon icon="external-link-alt" size="lg" />
          </IconButton>
        </Box>
      }
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {sectionCounterQuery.isLoading ? (
          <CircularProgress size={50} />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text
              sx={{
                px: 2,
                color: quarterAvailability
                  ? quarterAvailability > thresholdValue
                    ? theme.palette.success.main
                    : theme.palette.error.main
                  : undefined,
                fontWeight: 550,
              }}
              variant="h1"
            >
              {quarterAvailability ? quarterAvailability.toFixed(2) + "%" : "-"}
            </Text>

            <Box sx={{ display: "flex" }}>
              <Text
                sx={{
                  py: 2,
                  px: 4,
                  fontWeight: 550,
                }}
                variant="h6"
              >
                Hittil i år:
              </Text>
              <Text
                sx={{
                  py: 2,
                  px: 4,
                  fontWeight: 600,
                  color: soFarThisYear
                    ? soFarThisYear > thresholdValue
                      ? theme.palette.success.main
                      : theme.palette.error.main
                    : undefined,
                }}
                variant="h6"
              >
                {soFarThisYear ? soFarThisYear.toFixed(2) + "%" : "-"}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default AvailabilityCard;
