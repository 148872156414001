/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SectionCounterDataDeducted,
    SectionCounterDataDeductedFromJSON,
    SectionCounterDataDeductedFromJSONTyped,
    SectionCounterDataDeductedToJSON,
    SectionCounterDataNonDeducted,
    SectionCounterDataNonDeductedFromJSON,
    SectionCounterDataNonDeductedFromJSONTyped,
    SectionCounterDataNonDeductedToJSON,
} from './';

/**
 * 
 * @export
 * @interface SectionCounterData
 */
export interface SectionCounterData {
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataDeducted; }}
     * @memberof SectionCounterData
     */
    deducted: { [key: string]: SectionCounterDataDeducted; };
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataNonDeducted; }}
     * @memberof SectionCounterData
     */
    nonDeducted: { [key: string]: SectionCounterDataNonDeducted; };
    /**
     * 
     * @type {{ [key: string]: SectionCounterDataNonDeducted; }}
     * @memberof SectionCounterData
     */
    totalTime: { [key: string]: SectionCounterDataNonDeducted; };
}

export function SectionCounterDataFromJSON(json: any): SectionCounterData {
    return SectionCounterDataFromJSONTyped(json, false);
}

export function SectionCounterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionCounterData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deducted': (mapValues(json['deducted'], SectionCounterDataDeductedFromJSON)),
        'nonDeducted': (mapValues(json['nonDeducted'], SectionCounterDataNonDeductedFromJSON)),
        'totalTime': (mapValues(json['totalTime'], SectionCounterDataNonDeductedFromJSON)),
    };
}

export function SectionCounterDataToJSON(value?: SectionCounterData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deducted': (mapValues(value.deducted, SectionCounterDataDeductedToJSON)),
        'nonDeducted': (mapValues(value.nonDeducted, SectionCounterDataNonDeductedToJSON)),
        'totalTime': (mapValues(value.totalTime, SectionCounterDataNonDeductedToJSON)),
    };
}


