import React, { useState } from "react";

import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import Table from "@smartinspection/ameta-frontend-components/display/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { format } from "date-fns";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";
import EditYmMeasurementDialog from "components/ym/dialogs/EditYmMeasurementDialog";

import { YmPoint } from "services/ymApi";
import { authorizedYmApi } from "services/apiService";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";

export default function YmHistoricalListDialog({
  ymPoint,
  measurementType,
  refetch,
  open,
  onClose,
}: {
  refetch: () => void;
  ymPoint: YmPoint;
  measurementType: string;
  open: boolean;
  onClose: () => void;
}) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] =
    useState<any>(undefined);

  // str.substring(1), removes the first character of str

  const measurementHistoryDateConverter = (
    shortYear: string,
    shortMonth: string,
    shortDay: string
  ) => {
    const year = "20" + shortYear.substring(1);
    const monthZeroIndex = shortMonth.substring(1);
    const day = shortDay.substring(1);

    return new Date(+year, +monthZeroIndex - 1, +day);
  };

  interface historicalData {
    [key: string]: yearData;
  }
  interface yearData {
    [key: string]: monthData;
  }

  interface monthData {
    [key: string]: dayData;
  }
  interface dayData {
    [key: string]: measurement;
  }

  interface measurement {
    date?: string;
    measurementIndication: string;
    unit: string;
    value: string;
    referenceId: string;
  }

  const ymPointMeasurements: (
    historicalDataRecord: historicalData
  ) => object[] = (historicalDataRecord: historicalData) => {
    const ymMeasurements: object[] = [];
    Object.entries(historicalDataRecord).forEach(([year, yearData]) => {
      Object.entries(yearData).forEach(([month, monthData]) => {
        Object.entries(monthData).forEach(([day, dayData]) => {
          const dayDate = measurementHistoryDateConverter(year, month, day);
          const obj = {
            dateString: format(dayDate, "dd.MM.yyyy"),
            value: dayData?.[measurementType]?.value,
            dayData,
            date: dayDate,
          };

          dayData?.[measurementType] && ymMeasurements.push(obj);
        });
      });
    });

    return ymMeasurements;
  };

  const reportData =
    ymPoint.data?.historicalDataRecord &&
    ymPointMeasurements(ymPoint.data?.historicalDataRecord as historicalData);

  const temp = selectedMeasurement as
    | {
        dateString: string;
        value: string;
        dayData: dayData;
        date: Date;
      }
    | undefined;
  const selectedDayData: measurement | undefined =
    temp?.dayData[measurementType];

  const tableData =
    reportData &&
    reportData.map((el) => {
      return {
        ...el,
        delete: (
          <IconButton
            noShadow
            type="error"
            onClick={() => {
              setDeleteDialogOpen(true);
              setSelectedMeasurement(el);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
        edit: (
          <IconButton
            noShadow
            type="error"
            onClick={() => {
              setEditDialogOpen(true);
              setSelectedMeasurement(el);
            }}
          >
            <EditIcon />
          </IconButton>
        ),
      };
    });

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        title={"Innmålt " + measurementType}
      >
        <div style={{ height: "100%", width: "100%" }}>
          {reportData && reportData.length > 0 ? (
            <Table
              headers={[
                { fieldName: "dateString", name: "Dato" },
                { fieldName: "value", name: measurementType + " verdi" },
                { fieldName: "edit", name: "Rediger" },
                { fieldName: "delete", name: "Slett" },
              ]}
              data={tableData}
            />
          ) : (
            <CenterContainer>
              <Text variant="h5">Ingen data denne perioden</Text>
            </CenterContainer>
          )}
        </div>
      </Dialog>
      {deleteAllDialogOpen && (
        <ConfirmDialog
          open={deleteAllDialogOpen}
          onCancel={() => {
            setDeleteAllDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          onAccept={() => {
            authorizedYmApi().then((api) => {
              api
                .deleteYmMeasurementType({
                  pointId: ymPoint.id,
                  measurementType: measurementType,
                })
                .then(refetch);
            });
          }}
          text={
            "Er du sikker på at du vil slette all data på dette målepunktet for " +
            measurementType +
            " ?"
          }
          acceptText="Ja, slett all data"
          cancelText="Nei, avbryt"
        />
      )}
      {deleteDialogOpen && (
        <ConfirmDialog
          open={deleteDialogOpen}
          onCancel={() => {
            setDeleteDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          onAccept={() => {
            if (selectedDayData) {
              authorizedYmApi().then((api) => {
                api
                  .deleteYmMeasurement({
                    referenceId: selectedDayData.referenceId,
                    deleteYmMeasurement: {
                      data: { _delete: measurementType },
                    },
                  })
                  .then(refetch);
              });
            }
          }}
          text="Er du sikker på at du vil slette denne verdien?"
          acceptText="Ja, slett denne verdien"
          cancelText="Nei, avbryt"
        />
      )}
      {ymPoint && editDialogOpen && selectedDayData && (
        <EditYmMeasurementDialog
          isOpened={editDialogOpen}
          ymPointId={ymPoint.id}
          ymPointMeasurementId={ymPoint.measurementPointId}
          selectedMeasurement={selectedMeasurement}
          measurementType={measurementType}
          onClose={() => {
            setEditDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
          referenceId={selectedDayData.referenceId}
        />
      )}
    </React.Fragment>
  );
}
