import React, { useState } from "react";
import format from "date-fns/format";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import MultilineTextField from "@smartinspection/ameta-frontend-components/input/MultilineTextField";

import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import DatePicker from "@smartinspection/ameta-frontend-components/pickers/DatePicker";

import { authorizedRuhReportApi } from "services/apiService";

import { RUHReport } from "services/hmsApi";

export default function AddRUHDialog({ refetch }: { refetch: () => void }) {
  const [open, setOpen] = useState(false);

  const initialDate = new Date();

  const initialForm: RUHReport = {
    date: format(initialDate, "dd.MM.yyyy"),
    ruhType: "",
    ruhNr: -1,
    description: "",
  };

  const initialValidation: { [k: string]: boolean } = Object.fromEntries(
    Object.keys(initialForm).map((key) => [key, true])
  );

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedClosingDate, setSelectedClosingDate] = useState<Date | null>(
    null
  );
  const [formData, setFormData] = useState<RUHReport>(initialForm);
  const [vaildations, setValidations] = useState(initialValidation);
  const [errorMessage, setErrorMessage] = useState("");
  const validateForm = (form: any) => {
    const currentValidation = { ...vaildations };
    Object.keys(currentValidation).forEach((key) => {
      if ((!form[key] || form[key] < 0) && form[key] !== 0) {
        currentValidation[key] = false;
      }
    }); // for each key, set validation to false if there's nothing there
    setValidations(currentValidation);
    return Object.keys(currentValidation).every(
      (key) => currentValidation[key] // 'and' all values
    );
  };

  const closeAndResetStates = () => {
    setErrorMessage("");
    setSelectedDate(initialDate);
    setFormData(initialForm);
    setValidations(initialValidation);
    setOpen(false);
  };

  function onSaveClick() {
    if (!validateForm(formData)) {
      setErrorMessage("Du må fylle ut alle feltene som er markert med *");
    } else {
      authorizedRuhReportApi().then((api) => {
        api
          .createRuhReport({
            rUHReport: {
              ...formData,
              date: format(selectedDate, "dd.MM.yyyy"),
              ...(selectedClosingDate && {
                closed: format(selectedClosingDate, "dd.MM.yyyy"),
              }),
            },
          })
          .then(refetch);
      });
      closeAndResetStates();
    }
  }

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={<Text variant="body1">Legg til ny RUH-rapport</Text>}
      >
        <IconButton
          type="success"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        type="drawer"
        open={open}
        paperSx={{ minWidth: 800, height: "70%" }}
        onClose={closeAndResetStates}
        title="Legg til månedsrapport for RUH"
        dialogActions={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              px: 4,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button type="error" onClick={closeAndResetStates}>
              Avbryt
            </Button>
            <Button colored type="success" onClick={onSaveClick}>
              Legg til rapport
            </Button>
          </Box>
        }
      >
        {errorMessage !== "" ? (
          <Text sx={{ p: 1 }} variant="body1">
            {errorMessage}
          </Text>
        ) : (
          ""
        )}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            px: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            <DatePicker
              width={280}
              label="*Startdato"
              value={selectedDate}
              onChange={setSelectedDate}
            />
            <DatePicker
              width={280}
              label="SluttDato (ikke pågrevd)"
              value={selectedClosingDate}
              onChange={setSelectedClosingDate}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            <TextField
              width={280}
              label="*RUH type"
              value={formData.ruhType}
              onChange={(value) => {
                setValidations({ ...vaildations, ruhType: true });
                setFormData({
                  ...formData,
                  ruhType: value.target.value,
                });
              }}
              error={!vaildations.ruhType}
            />
            <TextField
              width={280}
              label="*RUH Nr"
              type="number"
              value={formData.ruhNr}
              onChange={(value) => {
                setValidations({ ...vaildations, ruhNr: true });
                setFormData({
                  ...formData,
                  ruhNr: Number(value.target.value),
                });
              }}
              error={!vaildations.ruhNr}
            />
          </Box>
          <Box sx={{ p: 2 }}>
            <MultilineTextField
              label="Kommentar"
              value={formData.description}
              minRows={6}
              onChange={(value) => {
                setValidations({ ...vaildations, description: true });
                setFormData({
                  ...formData,
                  description: value.target.value,
                });
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
