import React, { useState } from "react";
import { format } from "date-fns";

import Box from "@mui/material/Box";

import BarChart from "@smartinspection/ameta-frontend-components/charts/BarChart";
import Card from "@smartinspection/ameta-frontend-components/display/Card";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import VerticalSplit from "@smartinspection/ameta-frontend-components/layout/VerticalSplit";
import ValueIndicator from "@smartinspection/ameta-frontend-components/display/ValueIndicator";
import useTheme from "@smartinspection/ameta-frontend-components/style/useTheme";

import { HMSCounter, NhYearDataObject } from "services/hmsApi";
import { yearsListDropdown } from "utils/yearsListDropDown";

export default function NAndH({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const theme = useTheme();

  const monthConverter = (date: Date) => {
    const month = format(date, "M");
    const paddingString = "m";
    return paddingString.concat(month);
  };
  const yearConverter = (date: Date) => {
    const year = format(date, "yyyy");
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [previousMonthDate, setPreviousMonthDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 2))
  );

  const labelMonthConverter = {
    m1: "Jan",
    m2: "Feb",
    m3: "Mar",
    m4: "Apr",
    m5: "Mai",
    m6: "Jun",
    m7: "Jul",
    m8: "Aug",
    m9: "Sep",
    m10: "Okt",
    m11: "Nov",
    m12: "Des",
  };

  const yearMonthMapper = (year: NhYearDataObject | undefined) => {
    return Object.entries(labelMonthConverter).map(([key, value]) => {
      return {
        label: value,
        ...(year?.months?.[key]?.h1Value && {
          H1: year.months[key].h1Value,
        }),
        ...(year?.months?.[key]?.h2Value && {
          H2: year.months[key].h2Value,
        }),
      };
    });
  };

  const nhReportYear =
    hmsCounterData?.data?.nh?.years?.[yearConverter(selectedDate)];

  const nhPreviousMonth =
    hmsCounterData?.data?.nh?.years?.[yearConverter(previousMonthDate)]
      ?.months?.[monthConverter(previousMonthDate)];

  return (
    <Card
      type="default"
      faIcon="notes-medical"
      title="H1 og H2"
      rightHeaderComponent={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="Periode"
              optionsList={{
                0: "Januar",
                1: "Februar",
                2: "Mars",
                3: "April",
                4: "Mai",
                5: "Juni",
                6: "Juli",
                7: "August",
                8: "September",
                9: "Oktober",
                10: "November",
                11: "Desember",
              }}
              value={selectedDate.getMonth().toString()}
              onChange={(e) => {
                const tempDate = new Date(selectedDate);
                tempDate.setMonth(+e);
                setSelectedDate(new Date(tempDate));
                tempDate.setMonth(tempDate.getMonth() - 1);
                setPreviousMonthDate(new Date(tempDate));
              }}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <OptionsPicker
              label="År"
              optionsList={yearsListDropdown()}
              value={selectedDate.getFullYear().toString()}
              onChange={(e) => {
                const tempDate = new Date(selectedDate);
                tempDate.setFullYear(+e);
                setSelectedDate(new Date(tempDate));
                tempDate.setMonth(tempDate.getMonth() - 1);
                setPreviousMonthDate(new Date(tempDate));
              }}
            />
          </Box>
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <VerticalSplit
            divider
            left={
              <Box sx={{ display: "flex" }}>
                <ValueIndicator
                  type="flat"
                  label="H1"
                  value={
                    nhReportYear?.months?.[monthConverter(selectedDate)]
                      ?.h1Value
                      ? +nhReportYear?.months?.[monthConverter(selectedDate)]
                          ?.h1Value
                      : undefined
                  }
                  previous={{
                    value: nhPreviousMonth?.h1Value
                      ? +nhPreviousMonth?.h1Value
                      : undefined,
                    comparison: "value",
                    endingText: "enn forrige periode",
                    isIncreaseGood: "yes",
                  }}
                />
                <ValueIndicator
                  type="flat"
                  label="H1 snitt hittil i år"
                  value={nhReportYear?.avgH1 ? +nhReportYear.avgH1 : undefined}
                />
              </Box>
            }
            right={
              <Box sx={{ display: "flex" }}>
                <ValueIndicator
                  type="flat"
                  label="H2"
                  value={
                    nhReportYear?.months?.[monthConverter(selectedDate)]
                      ?.h1Value
                      ? +nhReportYear?.months?.[monthConverter(selectedDate)]
                          ?.h1Value
                      : undefined
                  }
                  previous={{
                    value: nhPreviousMonth?.h1Value
                      ? +nhPreviousMonth?.h1Value
                      : undefined,
                    comparison: "value",
                    endingText: "enn forrige periode",
                    isIncreaseGood: "yes",
                  }}
                />
                <ValueIndicator
                  type="flat"
                  label="H2 snitt hittil i år"
                  value={nhReportYear?.avgH2 ? +nhReportYear.avgH2 : undefined}
                />
              </Box>
            }
          />
        </Box>
        <Box sx={{ flex: 2 }}>
          <BarChart
            margin={{ top: 0, bottom: 10, left: 0, right: 20 }}
            dataProps={[
              {
                fieldName: "H1",
                name: "H1-verdi",
                color: theme.palette.error.main,
              },
              {
                fieldName: "H2",
                name: "H2-verdi",
                color: theme.palette.warning.main,
              },
            ]}
            data={
              yearMonthMapper(nhReportYear) as unknown as {
                [key: string]: string | number;
              }[]
            }
          />
        </Box>
      </Box>
    </Card>
  );
}
