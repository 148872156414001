const measurementTypes = [
  {
    unit: "lab",
    label: "pH",
    name: "pH",
  },
  {
    unit: "mg/L",
    label: "SO4",
    name: "Sulfat",
  },
  {
    unit: "mg/L",
    label: "STS",
  },
  {
    unit: "ug/L",
    label: "NH4_N",
    name: "Ammonium",
  },
  {
    unit: "ug/L",
    label: "NH3_N",
    name: "Ammonium",
  },
  {
    unit: "ug/L",
    label: "AL_R",
    name: "Alrestatin",
  },
  {
    unit: "ug/L",
    label: "Lal",
    name: "Limulus amebocyte lysate",
  },
  {
    unit: "oppsl",
    label: "AI_ICP (filtrert)",
  },
  {
    unit: "oppsl",
    label: "AI_ICP (ufiltrert)",
  },
  {
    unit: "ug/L",
    label: "As_MS",
  },
  {
    unit: "ug/L",
    label: "Cd_MS",
  },
  {
    unit: "ug/L",
    label: "Co_MS",
  },
  {
    unit: "ug/L",
    label: "Cu_MS",
  },
  {
    unit: "ug/L",
    label: "Mn_MS",
  },
  {
    unit: "ug/L",
    label: "Ni_MS",
  },
  {
    unit: "ug/L",
    label: "Zn_MS",
  },
  {
    unit: "ug/L",
    label: "Fe_MS",
  },
  {
    unit: "mg/L",
    label: "Na",
  },
  {
    unit: "mg/L",
    label: "Ca",
  },
];

export default measurementTypes;
