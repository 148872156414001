/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRUHReport,
    GetRUHReportFromJSON,
    GetRUHReportFromJSONTyped,
    GetRUHReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuhMonthDataObject
 */
export interface RuhMonthDataObject {
    /**
     * 
     * @type {number}
     * @memberof RuhMonthDataObject
     */
    sumRuh: number;
    /**
     * 
     * @type {{ [key: string]: Array<GetRUHReport>; }}
     * @memberof RuhMonthDataObject
     */
    days: { [key: string]: Array<GetRUHReport>; };
}

export function RuhMonthDataObjectFromJSON(json: any): RuhMonthDataObject {
    return RuhMonthDataObjectFromJSONTyped(json, false);
}

export function RuhMonthDataObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuhMonthDataObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sumRuh': json['sumRuh'],
        'days': json['days'],
    };
}

export function RuhMonthDataObjectToJSON(value?: RuhMonthDataObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sumRuh': value.sumRuh,
        'days': value.days,
    };
}


