import React, { useState } from "react";

import Box from "@mui/material/Box";

import Text from "@smartinspection/ameta-frontend-components/display/Text";
import TextField from "@smartinspection/ameta-frontend-components/input/TextField";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import Button from "@smartinspection/ameta-frontend-components/controls/Button";
import measurementTypes from "components/ym/measurementTypes";
import { format } from "date-fns";
import { authorizedYmApi } from "services/apiService";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";

interface dayData {
  [key: string]: measurementGathered;
}
interface measurement {
  date: Date;
  dateString: string;
  value?: string;
  dayData: dayData;
  measurementType: string;
}

interface measurementGathered {
  date?: string;
  measurementIndication: string;
  unit: string;
  value: string;
  measurementType: string;
  referenceId: string;
}

export default function EditYmMeasurementDialog({
  ymPointMeasurementId,
  ymPointId,
  isOpened,
  onClose,
  selectedMeasurement,
  measurementType,
  referenceId,
  editAll,
}: {
  ymPointId: string;
  ymPointMeasurementId: string;
  isOpened: boolean;
  onClose: () => void;
  selectedMeasurement?: measurement;
  measurementType?: string;
  referenceId: string;
  editAll?: boolean;
}) {
  const initialFormData = () => {
    let finalObject: {
      [key: string]: {
        unit?: string;
        value?: string;
        measurementIndication?: string;
        referenceId: string;
      };
    } = {};
    measurementTypes.forEach((item) => {
      let measurementIndication = "eq";
      if (selectedMeasurement?.dayData?.[item.label]?.value[0] === "<") {
        measurementIndication = "lt";
      } else if (selectedMeasurement?.dayData?.[item.label]?.value[0] === ">") {
        measurementIndication = "mt";
      }

      if (selectedMeasurement?.dayData?.[item.label]) {
        finalObject = {
          ...finalObject,
          [item.label]: {
            unit: item.unit,
            value: selectedMeasurement?.dayData?.[item.label]?.value,
            referenceId:
              selectedMeasurement?.dayData?.[item.label]?.referenceId,
            measurementIndication,
          },
        };
      }
    });
    return { data: finalObject };
  };

  const initialStatesThresholdValuesValidation = Object.fromEntries(
    Object.values(measurementTypes).map((value) => [value.label, true])
  );

  const [formData, setFormData] = useState(initialFormData());
  const [vaildations, setValidations] = useState({
    initialStatesThresholdValuesValidation,
  });
  const [measurementErrorMessage, setMeasurementErrorMessage] = useState("");
  const [saved, setSaved] = useState(false);

  function onSaveClick() {
    let countFalse = 0;
    for (const value of Object.values(
      vaildations.initialStatesThresholdValuesValidation
    )) {
      if (value === false) {
        countFalse++;
        break;
      }
    }
    if (countFalse !== 0) {
      setMeasurementErrorMessage(
        "Ugyldige tegn er brukt. Gyldige formater:: <51.5, >51.5, 51.5, <51, >51, 51"
      );
    } else if (Object.keys(formData.data).length === 0) {
      setMeasurementErrorMessage("Du må minst legge til en måleverdi");
    } else if (
      countFalse === 0 &&
      Object.keys(formData.data).length !== 0 &&
      selectedMeasurement?.date
    ) {
      editAll
        ? authorizedYmApi().then((api) => {
            api
              .createYmMeasurement({
                pointId: ymPointId,
                newYmMeasurement: {
                  measurementPointId: ymPointMeasurementId,
                  data: formData.data as { [key: string]: object },
                  date: format(selectedMeasurement?.date, "yyyy-MM-dd"),
                },
              })
              .then(() => {
                setSaved(false);
                setFormData({
                  data: {},
                });
                setMeasurementErrorMessage("");
                onClose();
              });
          })
        : authorizedYmApi().then((api) => {
            api
              .updateYmMeasurement({
                referenceId,
                updateYmMeasurement: {
                  data: formData.data as { [key: string]: object },
                },
              })
              .then(() => {
                handleOnClose();
              });
          });
    }
    setSaved(false);
  }
  const validThreshold = (value: string, label: string) => {
    const re = /^[<>]?[0-9\b]+(\.[0-9]+)?$/;
    if (value === "" || re.test(value)) {
      setValidations({
        ...vaildations,
        initialStatesThresholdValuesValidation: {
          ...vaildations.initialStatesThresholdValuesValidation,
          [label]: true,
        },
      });
    } else {
      setValidations({
        ...vaildations,
        initialStatesThresholdValuesValidation: {
          ...vaildations.initialStatesThresholdValuesValidation,
          [label]: false,
        },
      });
    }
  };

  const handleOnClose = () => {
    setSaved(false);
    setValidations({
      initialStatesThresholdValuesValidation,
    });
    setFormData({
      data: {},
    });
    setMeasurementErrorMessage("");
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        type="drawer"
        paperSx={{ minWidth: "60%", height: "70%" }}
        title={
          editAll
            ? "Rediger måledata"
            : "Rediger måledata for " + measurementType
        }
        onClose={handleOnClose}
        open={isOpened}
        dialogActions={
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              type="info"
              onClick={() => {
                if (!saved) {
                  setSaved(true);
                  onSaveClick();
                }
              }}
            >
              Rediger måledata
            </Button>
          </Box>
        }
      >
        <CenterContainer>
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
              {measurementTypes.map((type, index) => {
                return (
                  <TextField
                    key={index}
                    value={formData.data?.[type.label]?.value}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Text sx={{ mr: 1 }} variant="body1">
                          {type.label}
                        </Text>
                        <Text variant="subtitle1">{`(${type.unit})`}</Text>
                      </Box>
                    }
                    onChange={(event) => {
                      const currentValue = event.target.value;

                      let measurementIndication = "eq";
                      if (currentValue[0] === "<") {
                        measurementIndication = "lt";
                      } else if (currentValue[0] === ">") {
                        measurementIndication = "mt";
                      }
                      validThreshold(currentValue, type.label);
                      if (currentValue === "") {
                        const thresholdObject = Object.fromEntries(
                          Object.entries(formData.data).filter(
                            ([key]) => key !== measurementType
                          )
                        );
                        setFormData({
                          ...formData,
                          data: thresholdObject,
                        });
                      } else {
                        selectedMeasurement &&
                          setFormData({
                            ...formData,
                            data: {
                              ...formData.data,
                              [type.label]: {
                                unit: selectedMeasurement.dayData[type.label]
                                  .unit,
                                value: currentValue,
                                measurementIndication,
                                referenceId: referenceId,
                              },
                            },
                          });
                        setMeasurementErrorMessage("");
                      }
                      setSaved(false);
                    }}
                    error={
                      !vaildations.initialStatesThresholdValuesValidation[
                        type.label
                      ]
                    }
                  />
                );
              })}
            </Box>
          </Box>
        </CenterContainer>
        {measurementErrorMessage !== "" && (
          <Text variant="body1">{measurementErrorMessage}</Text>
        )}
      </Dialog>
    </React.Fragment>
  );
}
