/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Ym API
 * A rest api for managing the ameta Ym system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YMOverviewResponseStatuses,
    YMOverviewResponseStatusesFromJSON,
    YMOverviewResponseStatusesFromJSONTyped,
    YMOverviewResponseStatusesToJSON,
    YmPoint,
    YmPointFromJSON,
    YmPointFromJSONTyped,
    YmPointToJSON,
} from './';

/**
 * 
 * @export
 * @interface YMOverviewResponse
 */
export interface YMOverviewResponse {
    /**
     * 
     * @type {YMOverviewResponseStatuses}
     * @memberof YMOverviewResponse
     */
    statuses: YMOverviewResponseStatuses;
    /**
     * 
     * @type {Array<YmPoint>}
     * @memberof YMOverviewResponse
     */
    markedPoints?: Array<YmPoint>;
}

export function YMOverviewResponseFromJSON(json: any): YMOverviewResponse {
    return YMOverviewResponseFromJSONTyped(json, false);
}

export function YMOverviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): YMOverviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statuses': YMOverviewResponseStatusesFromJSON(json['statuses']),
        'markedPoints': !exists(json, 'markedPoints') ? undefined : ((json['markedPoints'] as Array<any>).map(YmPointFromJSON)),
    };
}

export function YMOverviewResponseToJSON(value?: YMOverviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statuses': YMOverviewResponseStatusesToJSON(value.statuses),
        'markedPoints': value.markedPoints === undefined ? undefined : ((value.markedPoints as Array<any>).map(YmPointToJSON)),
    };
}


