/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Traffic API
 * A rest api for managing the ameta traffic system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TrafficSensorCounter,
    TrafficSensorCounterFromJSON,
    TrafficSensorCounterToJSON,
    TrafficSensorReading,
    TrafficSensorReadingFromJSON,
    TrafficSensorReadingToJSON,
} from '../models';

export interface GetTrafficSensorCountersRequest {
    id: string;
    year: string;
}

export interface GetTrafficSensorReadingsRequest {
    id: string;
    yearMonth: string;
}

/**
 * 
 */
export class TrafficApi extends runtime.BaseAPI {

    /**
     * get traffic sensor counters
     */
    async getTrafficSensorCountersRaw(requestParameters: GetTrafficSensorCountersRequest): Promise<runtime.ApiResponse<TrafficSensorCounter>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrafficSensorCounters.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getTrafficSensorCounters.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/sensors/{id}/counters/{year}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"year"}}`, encodeURIComponent(String(requestParameters.year))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TrafficSensorCounterFromJSON(jsonValue));
    }

    /**
     * get traffic sensor counters
     */
    async getTrafficSensorCounters(requestParameters: GetTrafficSensorCountersRequest): Promise<TrafficSensorCounter> {
        const response = await this.getTrafficSensorCountersRaw(requestParameters);
        return await response.value();
    }

    /**
     * get traffic sensor readings
     */
    async getTrafficSensorReadingsRaw(requestParameters: GetTrafficSensorReadingsRequest): Promise<runtime.ApiResponse<Array<TrafficSensorReading>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTrafficSensorReadings.');
        }

        if (requestParameters.yearMonth === null || requestParameters.yearMonth === undefined) {
            throw new runtime.RequiredError('yearMonth','Required parameter requestParameters.yearMonth was null or undefined when calling getTrafficSensorReadings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // ametaAuth authentication
        }

        const response = await this.request({
            path: `/sensors/{id}/data/{year-month}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"year-month"}}`, encodeURIComponent(String(requestParameters.yearMonth))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrafficSensorReadingFromJSON));
    }

    /**
     * get traffic sensor readings
     */
    async getTrafficSensorReadings(requestParameters: GetTrafficSensorReadingsRequest): Promise<Array<TrafficSensorReading>> {
        const response = await this.getTrafficSensorReadingsRaw(requestParameters);
        return await response.value();
    }

}
