/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SectionCounterData,
    SectionCounterDataFromJSON,
    SectionCounterDataFromJSONTyped,
    SectionCounterDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface SectionCounter
 */
export interface SectionCounter {
    /**
     * 
     * @type {string}
     * @memberof SectionCounter
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionCounter
     */
    sort?: string;
    /**
     * 
     * @type {SectionCounterData}
     * @memberof SectionCounter
     */
    data?: SectionCounterData;
}

export function SectionCounterFromJSON(json: any): SectionCounter {
    return SectionCounterFromJSONTyped(json, false);
}

export function SectionCounterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionCounter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'data': !exists(json, 'data') ? undefined : SectionCounterDataFromJSON(json['data']),
    };
}

export function SectionCounterToJSON(value?: SectionCounter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sort': value.sort,
        'data': SectionCounterDataToJSON(value.data),
    };
}


