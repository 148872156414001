/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Availability API
 * A rest api for managing the ameta availability system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    closedDate: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    openedDate: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    workType: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    direction: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    section: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    isDeducted: string;
}

export function EventFromJSON(json: any): Event {
    return EventFromJSONTyped(json, false);
}

export function EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): Event {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'closedDate': json['closedDate'],
        'openedDate': json['openedDate'],
        'workType': json['workType'],
        'direction': json['direction'],
        'section': json['section'],
        'isDeducted': json['isDeducted'],
    };
}

export function EventToJSON(value?: Event | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'closedDate': value.closedDate,
        'openedDate': value.openedDate,
        'workType': value.workType,
        'direction': value.direction,
        'section': value.section,
        'isDeducted': value.isDeducted,
    };
}


