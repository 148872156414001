import { HMSCounter } from "services/hmsApi/models";
import { useQuery, useQueries } from "react-query";
import {
  authorizedHmsCounterApi,
  authorizedHmsDocumentApi,
} from "services/apiService";
import { HmsDocumentApi, DocumentReport } from "services/hmsApi";
import { queryClient } from "services/apiService";
export const useQueryHmsCounter = (): {
  data: HMSCounter | undefined;
  refetch: () => void;
  isLoading: boolean;
} => {
  const { data, refetch, isLoading } = useQuery(["hmsCounter"], async () => {
    const api = await authorizedHmsCounterApi();
    return api.getHmsCounter();
  });

  return { data, refetch, isLoading };
};
const paginationDocumentReportQuery = async (
  api: HmsDocumentApi,
  documentId: string,
  temporaryObjectStore: DocumentReport[]
) => {
  await api
    .getDocumentReport({ documentId: documentId })
    .then(async (response) => {
      temporaryObjectStore.push(response);
      if (response.status === "initial" || response.status === "processing") {
        await paginationDocumentReportQuery(
          api,
          documentId,
          temporaryObjectStore
        );
      }
    });
};

export const useHmsDocumentReportQuery = (documentIdlist: string[]) => {
  const queryArray = documentIdlist.map((element) => {
    return {
      queryKey: ["hmsDocumentsReport", element],
      queryFn: async () => {
        const temporaryObjectStore: DocumentReport[] = [];
        const api = await authorizedHmsDocumentApi();
        await paginationDocumentReportQuery(api, element, temporaryObjectStore);
        return { objects: temporaryObjectStore };
      },
    };
  });
  let data: { [key: string]: DocumentReport } = {};
  const queriesResult = useQueries(queryArray);

  queriesResult.forEach((response) => {
    response.data &&
      response.data.objects.forEach((obj) => {
        data[obj.documentName] = obj;
      });
  });
  return {
    data,
    refetch: () =>
      queriesResult.forEach((response) => {
        response.refetch();
      }),
    remove: () => {
      queryClient.removeQueries("ymDocumentsReport");
    },
  };
};
