/* tslint:disable */
/* eslint-disable */
/**
 * Ameta Security API
 * A rest api for managing the ameta security system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecurityEvent
 */
export interface SecurityEvent {
    /**
     * 
     * @type {string}
     * @memberof SecurityEvent
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityEvent
     */
    place: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityEvent
     */
    accidentType: SecurityEventAccidentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SecurityEvent
     */
    involvedCount: number;
    /**
     * 
     * @type {string}
     * @memberof SecurityEvent
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityEvent
     */
    reportId?: string;
}

export function SecurityEventFromJSON(json: any): SecurityEvent {
    return SecurityEventFromJSONTyped(json, false);
}

export function SecurityEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'place': json['place'],
        'accidentType': json['accidentType'],
        'involvedCount': json['involvedCount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'reportId': !exists(json, 'reportId') ? undefined : json['reportId'],
    };
}

export function SecurityEventToJSON(value?: SecurityEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'place': value.place,
        'accidentType': value.accidentType,
        'involvedCount': value.involvedCount,
        'comment': value.comment,
        'reportId': value.reportId,
    };
}

/**
* @export
* @enum {string}
*/
export enum SecurityEventAccidentTypeEnum {
    DR = 'DR',
    MAS = 'MAS',
    AS = 'AS',
    LS = 'LS',
    PR = 'PR',
    AU = 'AU'
}


