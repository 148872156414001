import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Box from "@mui/material/Box";

import { format } from "date-fns";
import { nb } from "date-fns/locale";

import Card from "@smartinspection/ameta-frontend-components/display/Card";
import ValueIndicator from "@smartinspection/ameta-frontend-components/display/ValueIndicator";
import QuadrantSplit from "@smartinspection/ameta-frontend-components/layout/QuadrantSplit";
import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";

import { HMSCounter } from "services/hmsApi";

function RUHSJACard({
  hmsCounterData,
}: {
  hmsCounterData: HMSCounter | undefined;
}) {
  const navigate = useNavigate();

  const monthConverter = (date: Date) => {
    const month = format(date, "M");
    const paddingString = "m";
    return paddingString.concat(month);
  };
  const yearConverter = (date: Date) => {
    const year = format(date, "yyyy");
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const currentDate = new Date();
  const previousMonthDate = new Date(currentDate);
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const twoMonthsAgoDate = new Date(currentDate);
  twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 2);

  const sjaReportYear =
    hmsCounterData?.data?.sja?.years?.[yearConverter(previousMonthDate)];

  let currentMonth = format(previousMonthDate, "LLLL", { locale: nb });

  currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
  const currentYear = format(previousMonthDate, "yyyy");

  return (
    <Card
      type="drawer"
      faIcon="notes-medical"
      title="HMS • RUH og SJA"
      subtitle={`${currentMonth} ${currentYear}`}
      rightHeaderComponent={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pr: 1,
            pt: 1.5,
          }}
        >
          <IconButton
            transparentBackground
            type="success"
            onClick={() => {
              navigate("/dashboard/hms");
            }}
          >
            <FontAwesomeIcon icon="external-link-alt" size="lg" />
          </IconButton>
        </Box>
      }
    >
      <Box sx={{ p: 1, height: "100%" }}>
        <QuadrantSplit
          divider
          topLeft={
            <ValueIndicator
              type="flat"
              label="RUH"
              faIcon="calculator"
              value={
                hmsCounterData?.data?.ruh?.years?.[
                  yearConverter(previousMonthDate)
                ]?.months?.[monthConverter(previousMonthDate)]?.sumRuh
              }
              previous={{
                value:
                  hmsCounterData?.data?.ruh?.years?.[
                    yearConverter(twoMonthsAgoDate)
                  ]?.months?.[monthConverter(twoMonthsAgoDate)]?.sumRuh,
                comparison: "value",
                isIncreaseGood: "no",
                endingText: "enn forrige periode",
              }}
            />
          }
          bottomLeft={
            <ValueIndicator
              label="RUH hittil i år"
              type="flat"
              faIcon="calculator"
              value={
                hmsCounterData?.data?.ruh?.years?.[
                  yearConverter(previousMonthDate)
                ]?.sumRuh
              }
            />
          }
          topRight={
            <ValueIndicator
              type="flat"
              label="SJA"
              faIcon="calculator"
              value={
                hmsCounterData?.data?.sja?.years?.[
                  yearConverter(previousMonthDate)
                ]?.months?.[monthConverter(previousMonthDate)]?.sumSja
              }
              previous={{
                value:
                  hmsCounterData?.data?.sja?.years?.[
                    yearConverter(twoMonthsAgoDate)
                  ]?.months?.[monthConverter(twoMonthsAgoDate)]?.sumSja,
                comparison: "value",
                isIncreaseGood: "yes",
                endingText: "enn forrige periode",
              }}
            />
          }
          bottomRight={
            <ValueIndicator
              label="SJA hittil i år"
              type="flat"
              faIcon="calculator"
              value={sjaReportYear?.sumSja}
            />
          }
        />
      </Box>
    </Card>
  );
}

export default RUHSJACard;
