import React, { useState } from "react";
import { format } from "date-fns";

import Box from "@mui/material/Box";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import { SecurityMonthObject } from "services/securityApi/models/SecurityMonthObject";
import { SecurityEvent } from "services/securityApi/models/SecurityEvent";

import { authorizedSecurityEventApi } from "services/apiService";

import { SecurityCounter } from "services/securityApi/models";
import { yearsListDropdown } from "utils/yearsListDropDown";

import IconButton from "@smartinspection/ameta-frontend-components/controls/IconButton";
import CenterContainer from "@smartinspection/ameta-frontend-components/layout/CenterContainer";
import Tooltip from "@smartinspection/ameta-frontend-components/display/Tooltip";
import Text from "@smartinspection/ameta-frontend-components/display/Text";
import Table from "@smartinspection/ameta-frontend-components/display/Table";
import OptionsPicker from "@smartinspection/ameta-frontend-components/pickers/OptionsPicker";
import Dialog from "@smartinspection/ameta-frontend-components/layout/Dialog";
import ConfirmDialog from "@smartinspection/ameta-frontend-components/layout/ConfirmDialog";

export default function SecurityReport({
  securityCounterData,
  refetch,
}: {
  securityCounterData: SecurityCounter;
  refetch: () => void;
}) {
  const [open, setOpen] = useState(false);
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
  const [selectedMonth, setSelectedMonth] = useState(
    format(previousMonthDate, "M")
  );
  const [selectedYear, setSelectedYear] = useState(
    format(previousMonthDate, "yyyy")
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteSecurityEventId, setDeleteSecurityEventId] = useState("");
  const monthStringConverter = (month: string) => {
    const paddingString = "m";
    return paddingString.concat(month);
  };

  const yearStringConverter = (year: string) => {
    const slicedYear = year.slice(2, 4);
    const paddingString = "y";
    return paddingString.concat(slicedYear);
  };

  const secuityEventsInMonth: (
    month: SecurityMonthObject
  ) => SecurityEvent[] = (month: SecurityMonthObject) => {
    // Can get the events in scrabled order. Solved by using sortable table.

    const securityEvents: SecurityEvent[] = [];
    Object.values(month.days).forEach((events: SecurityEvent[]) => {
      events.forEach((e) => {
        securityEvents.push(e);
      });
    });
    return securityEvents;
  };

  const reportDataMonth =
    securityCounterData.data?.years?.[yearStringConverter(selectedYear)]
      ?.months?.[monthStringConverter(selectedMonth)];
  const reportData = reportDataMonth && secuityEventsInMonth(reportDataMonth);

  const tableData: { [key: string]: React.ReactNode }[] = [];
  reportData !== undefined &&
    reportData.forEach((element) => {
      tableData.push({
        ...element,
        delete: (
          <IconButton
            noShadow
            type="error"
            onClick={() => {
              setDeleteDialogOpen(true);
              setDeleteSecurityEventId(element.reportId as string);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
      });
    });

  return (
    <React.Fragment>
      <Tooltip
        placement="right"
        tooltipContent={<Text variant="body1">Se rapportoversikt</Text>}
      >
        <IconButton
          type="success"
          onClick={() => {
            setOpen(true);
          }}
        >
          <ArticleIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        paperSx={{
          minWidth: "70%",
          borderRadius: 2,
          height: "70%",
        }}
        onClose={() => setOpen(false)}
        title="Månedsrapport"
        rightHeaderComponent={
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              py: 1,
            }}
          >
            <Box
              sx={{
                width: "30%",
                display: "flex",
                justifyContent: "space-around",
                p: 1,
              }}
            >
              <OptionsPicker
                label="År"
                optionsList={yearsListDropdown()}
                value={selectedYear}
                onChange={setSelectedYear}
              />

              <OptionsPicker
                label="Periode"
                optionsList={{
                  "1": "Januar",
                  "2": "Februar",
                  "3": "Mars",
                  "4": "April",
                  "5": "Mai",
                  "6": "Juni",
                  "7": "Juli",
                  "8": "August",
                  "9": "September",
                  "10": "Oktober",
                  "11": "November",
                  "12": "Desember",
                }}
                onChange={setSelectedMonth}
                value={selectedMonth}
              />
            </Box>
          </Box>
        }
      >
        <Box style={{ height: "100%", width: "100%" }}>
          {reportData && reportData.length > 0 ? (
            <Box sx={{ py: 2, px: 1 }}>
              <Table
                headers={[
                  { fieldName: "date", name: "Dato" },
                  { fieldName: "place", name: "Sted" },
                  { fieldName: "accidentType", name: "Type ulykke" },
                  { fieldName: "involvedCount", name: "Antall" },
                  { fieldName: "comment", name: "Kommentar" },
                  { fieldName: "delete", name: "", align: "center" },
                ]}
                data={tableData}
              />
            </Box>
          ) : (
            <CenterContainer>
              <Text variant="h5">Ingen data denne perioden</Text>
            </CenterContainer>
          )}
        </Box>
      </Dialog>
      {deleteDialogOpen && (
        <ConfirmDialog
          open={deleteDialogOpen}
          text="Er du sikker på at du vil slette denne raden?"
          acceptText="Ja, slett denne raden"
          cancelText="Avbryt"
          onAccept={() => {
            authorizedSecurityEventApi().then((api) => {
              api
                .deleteSecurityEvent({ securityEventId: deleteSecurityEventId })
                .then(() => {
                  setDeleteSecurityEventId("");
                  setDeleteDialogOpen(false);
                  setTimeout(() => {
                    refetch();
                  }, 2000);
                });
            });
          }}
          onCancel={() => {
            setDeleteSecurityEventId("");
            setDeleteDialogOpen(false);
            setTimeout(() => {
              refetch();
            }, 2000);
          }}
        />
      )}
    </React.Fragment>
  );
}
