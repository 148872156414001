/* tslint:disable */
/* eslint-disable */
/**
 * Ameta hms API
 * A rest api for managing the ameta hms system
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RuhMonthDataObject,
    RuhMonthDataObjectFromJSON,
    RuhMonthDataObjectFromJSONTyped,
    RuhMonthDataObjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuhYearDataObject
 */
export interface RuhYearDataObject {
    /**
     * 
     * @type {number}
     * @memberof RuhYearDataObject
     */
    sumRuh: number;
    /**
     * 
     * @type {{ [key: string]: RuhMonthDataObject; }}
     * @memberof RuhYearDataObject
     */
    months: { [key: string]: RuhMonthDataObject; };
}

export function RuhYearDataObjectFromJSON(json: any): RuhYearDataObject {
    return RuhYearDataObjectFromJSONTyped(json, false);
}

export function RuhYearDataObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuhYearDataObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sumRuh': json['sumRuh'],
        'months': (mapValues(json['months'], RuhMonthDataObjectFromJSON)),
    };
}

export function RuhYearDataObjectToJSON(value?: RuhYearDataObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sumRuh': value.sumRuh,
        'months': (mapValues(value.months, RuhMonthDataObjectToJSON)),
    };
}


