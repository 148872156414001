import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getDocumentIcon from "utils/documentIconSelector";
import Table from "@smartinspection/ameta-frontend-components/display/Table";
import FileUploadItem from "services/FileUploadItem";

function DocumentUploadTable({
  docType,
  filesToUpload,
  clearFile,
  uploadedDocumentIdlist,
  setDialogBoxTitle,
}: {
  docType: string;
  filesToUpload: File[];
  clearFile: (canceledFile: File) => void;
  uploadedDocumentIdlist: (uploadedDocumentIdlist: string[]) => void;
  setDialogBoxTitle: (title: string) => void;
}) {
  const [documentIdList, setDocumentIdList] = useState<string[]>([]);
  const fileUploadTableData: {
    fileIcon: React.ReactNode;
    name: string;
    createdAt: React.ReactNode;
  }[] = filesToUpload.map((file) => {
    const iconProps = getDocumentIcon(file.name);
    return {
      fileIcon: (
        <FontAwesomeIcon
          style={{ color: iconProps.color }}
          size="2x"
          icon={iconProps.icon}
        />
      ),
      name: file.name,
      createdAt: (
        <FileUploadItem
          docType={docType}
          key={file.name}
          file={file}
          clearFile={clearFile}
          getDocumentId={(documentId) => {
            documentIdList.push(documentId);
            setDocumentIdList(documentIdList);
            uploadedDocumentIdlist(documentIdList);
          }}
        />
      ),
    };
  });
  return (
    <div>
      {setDialogBoxTitle("Opplasting av dokumenter")}
      <Table
        headers={[
          { fieldName: "fileIcon", name: "" },
          { fieldName: "name", name: "Filnavn" },
          {
            fieldName: "createdAt",
            name: filesToUpload.length > 0 ? "Opprettet/Status" : "Opprettet",
          },
        ]}
        data={fileUploadTableData}
      />
    </div>
  );
}

export default React.memo(DocumentUploadTable, (prevProps, nextProps) => {
  // if there are uploads in progress, don't update
  // table before all uploads are complete
  if (prevProps.filesToUpload.length > 0) {
    if (nextProps.filesToUpload.length === 0) {
      return false;
    } else {
      return true;
    }

    // if there are no uploads in progress, update on
    // a document or file change
  } else if (prevProps.filesToUpload !== nextProps.filesToUpload) {
    return false;
  } else {
    return true;
  }
});
